import * as React from "react";
import { Box, chakra, Divider, Icon, useToast } from "@chakra-ui/react";
import { EmrInProcess, EmrRegistration } from "@medstonetech/slate-icons";
import { PillsBottle, SquaredChecked } from "icons";
import { formMessages } from "messages";
import { ChartSection, SharedChartSectionHeader } from "modules";
import {
  ChartRouteBaseParams,
  nextRoutesNurse,
  nextRoutesProvider,
  TriageIVMedicationForm,
  TriageIVMedicationSection,
} from "modules/charts-shared";
import { OrderHeaderCard } from "modules/orders";
import {
  useIvAndMedication,
  useUpdateIvAndMedication,
} from "modules/triage/api";
import {
  formToPayload,
  responseToForm,
} from "modules/triage/utils/iv-medications";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { IvAndMedicationsColumn } from "./IvAndMedicationColumn";
import {
  IvMedicationsTypeContent,
  IvStartsTypeContent,
  MedicationTypeContent,
} from "./MedsAndIvModals";
import { OrderStatus } from "enums";

type MedsAndIvTypes = "ivStarts" | "ivMedications" | "medications";

function IvAndMedications(props: { chartCode: string; isReadOnly?: boolean }) {
  const { encounterId = "", "*": chartName = "" } =
    useParams<ChartRouteBaseParams>();
  const toast = useToast();
  const navigate = useNavigate();
  const useFormContext = useForm<TriageIVMedicationForm>({
    defaultValues: {
      ivMedications: [],
      ivStarts: [],
      medications: [],
      treatments: [],
    },
  });
  const {
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormContext;
  const { data: ivMedicationData, isLoading: getIsLoading } =
    useIvAndMedication(encounterId);
  const { mutateAsync: updateIvMedication, isLoading: updateIsLoading } =
    useUpdateIvAndMedication(encounterId);
  const { chartCode, isReadOnly } = props;

  const [nextRoute, setNextRoute] = useState<string | null>(null);

  const location = useLocation();

  const fromSummary = location.pathname.includes("medical-records");

  useEffect(() => {
    if (ivMedicationData) {
      reset(responseToForm(ivMedicationData.data));
    }
  }, [ivMedicationData, reset]);

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    return () => {
      document.documentElement.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (nextRoute && !isDirty) {
      navigate(nextRoute);
      setNextRoute(null);
    }
  }, [nextRoute, navigate, isDirty]);

  const ivStarts = watch("ivStarts");
  const ivMedications = watch("ivMedications");
  const medications = watch("medications");

  const onSubmit = async (formData: TriageIVMedicationForm) => {
    const payload = formToPayload(formData);
    try {
      await updateIvMedication(payload);
      toast({ description: formMessages.updateSuccess("IV and Medications") });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const handleSaveAndNext = () => {
    const nextRoutes =
      chartCode[0] === "T" ? nextRoutesNurse : nextRoutesProvider;
    const route = nextRoutes[nextRoutes.indexOf(chartName || "") + 1];
    handleSubmit(onSubmit)().then(() => {
      setNextRoute(`./../${route}`);
    });
  };

  const filteredItems: (TriageIVMedicationSection & {
    index: number;
    type: string;
  })[] = React.useMemo(
    () => [
      ...ivStarts.map((x, index) => ({
        ...x,
        index,
        type: "ivStarts",
      })),
      ...ivMedications.map((x, index) => ({
        ...x,
        index,
        type: "ivMedications",
      })),
      ...medications.map((x, index) => ({
        ...x,
        index,
        type: "medications",
      })),
    ],
    [ivMedications, ivStarts, medications]
  );

  const columns = React.useMemo(
    () => ({
      ordered: {
        title: "Ordered",
        icon: EmrRegistration,
        bg: "orange",
        status: "Ordered" as OrderStatus,
        items: filteredItems.filter(
          (item) =>
            item.status === "Ordered" ||
            item.status === "PRN" ||
            item.status === "Prepared"
        ),
      },
      inProcess: {
        title: "In Process",
        icon: EmrInProcess,
        bg: "blue",
        status: "InProcess" as OrderStatus,
        items: filteredItems.filter((item) => item.status === "InProcess"),
      },
      completed: {
        title: "Completed",
        icon: EmrInProcess,
        bg: "green",
        status: "Completed" as OrderStatus,
        items: filteredItems.filter((item) => item.status === "Completed"),
      },
      administered: {
        title: "Administered",
        icon: SquaredChecked,
        bg: "purple",
        status: "Administered" as OrderStatus,
        items: filteredItems.filter((item) => item.status === "Administered"),
      },
    }),
    [filteredItems]
  );

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartSection
        minWidth="100%"
        onSubmit={handleSubmit(onSubmit)}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          handleSubmit(onSubmit)();
          onComplete();
        }}
      >
        <SharedChartSectionHeader
          icon={<PillsBottle />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          Meds & IV {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>

        <Box w="100%" display="flex" justifyContent="space-between">
          {Object.entries(columns).map(([_key, column], index) => (
            <React.Fragment key={column.status}>
              <IvAndMedicationsColumn
                header={
                  <OrderHeaderCard
                    title={column.title}
                    icon={<Icon as={column.icon} />}
                    bg={column.bg}
                    counter={column.items.length}
                  />
                }
                status={column.status}
                items={column.items}
                onSave={handleSubmit(onSubmit)}
              />
              {index < Object.keys(columns).length - 1 && (
                <Divider orientation="vertical" minH="90vh" />
              )}
            </React.Fragment>
          ))}
        </Box>

        {fromSummary &&
          filteredItems.map((x) => {
            const type: MedsAndIvTypes =
              x.route === "IV"
                ? "ivMedications"
                : x.route === "IVP" || x.route === "IVPB"
                ? "ivStarts"
                : "medications";

            const title =
              x.route === "IV"
                ? "IV/Medication Infusion"
                : x.route === "IVP" || x.route === "IVPB"
                ? "IV Push"
                : "Medication";

            return (
              <Box
                justifySelf="center"
                alignItems="center"
                display="flex"
                flexDir="column"
                color="blue"
              >
                <chakra.span fontWeight="600" mb="1.5rem">
                  {title}
                </chakra.span>
                {type === "ivStarts" && (
                  <IvStartsTypeContent item={x} type={type} />
                )}
                {type === "ivMedications" && (
                  <IvMedicationsTypeContent item={x} type={type} />
                )}
                {type === "medications" && (
                  <MedicationTypeContent item={x} type={type} />
                )}
                <Divider my="1.5rem" />
              </Box>
            );
          })}
      </ChartSection>
    </FormProvider>
  );
}

export { IvAndMedications };
