import { Icon } from "@chakra-ui/react";
import { EmrHpi } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import { ProviderChartCode } from "enums";
import {
  ChartRow,
  ChartRowElement,
  ChartRowElementImportedProps,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import * as React from "react";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { ChartForm } from "modules/charts-shared-v2";

const CHART_CODE: ProviderChartCode = "C006x";
const SECTION_CODE = "SHPI";

function T06HPI() {
  const {
    getIsLoading,
    encounterId,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    chartCode: CHART_CODE,
    sectionId: SECTION_CODE,
    sectionLabel: "HPI",
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:hpi").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  const { getValues } = useFormContext;

  const chartFavoriteEditMode: Record<
    "imported",
    ChartRowElementImportedProps
  > | null = getValues("chartFavoriteEditMode")
    ? {
        imported: {
          type: "nursing",
        },
      }
    : null;

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={SECTION_CODE}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrHpi} />}
          chartId={CHART_CODE}
          encounterId={encounterId}
          sectionId={SECTION_CODE}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          HPI {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q001.C006xSHPIQ001A011"
                  label="Injury to:"
                />
                <ChartRowElement
                  type="circle-option"
                  label="L"
                  name="Q001.C006xSHPIQ001A001"
                />
                <ChartRowElement
                  type="circle-option"
                  label="R"
                  name="Q001.C006xSHPIQ001A002"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="collar-bone area"
                  name="Q001.C006xSHPIQ001A003"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="shoulder"
                  name="Q001.C006xSHPIQ001A004"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="arm"
                  name="Q001.C006xSHPIQ001A005"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="elbow"
                  name="Q001.C006xSHPIQ001A006"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="forearm"
                  name="Q001.C006xSHPIQ001A007"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="wrist"
                  name="Q001.C006xSHPIQ001A008"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="hand"
                  name="Q001.C006xSHPIQ001A009"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q001.C006xSHPIQ001A010"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q002"
              subsectionLabel="Onset / duration"
            >
              Onset / duration
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="just prior to arrival"
                  name="Q002.C003xSHPIQ002A001"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="today"
                  name="Q002.C003xSHPIQ002A002"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="yesterday"
                  name="Q002.C003xSHPIQ002A003"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q002.C003xSHPIQ002A004"
                  size="xs"
                  inputType="number"
                  textAlign="center"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="min"
                  name="Q002.C003xSHPIQ002A005"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="hours"
                  name="Q002.C003xSHPIQ002A006"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="days ago"
                  name="Q002.C003xSHPIQ002A007"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q002.C003xSHPIQ002A008"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q003"
              subsectionLabel="Where"
            >
              Where
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="home"
                  name="Q003.C003xSHPIQ003A001"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="school"
                  name="Q003.C003xSHPIQ003A002"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="neighbor's"
                  name="Q003.C003xSHPIQ003A003"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="park"
                  name="Q003.C003xSHPIQ003A004"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="work"
                  name="Q003.C003xSHPIQ003A005"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="street"
                  name="Q003.C003xSHPIQ003A006"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q003.C003xSHPIQ003A009"
                  label="exact location:"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q003.C003xSHPIQ003A007"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q003.C003xSHPIQ003A010"
                  label="exact activity:"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q003.C003xSHPIQ003A008"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q004"
              subsectionLabel="Severity of pain"
            >
              Severity of pain
            </ChartSubsectionHeader>
          }
          content={
            <>
              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    label="mild"
                    name="Q004.C003xSHPIQ004A001"
                  />
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    label="moderate"
                    name="Q004.C003xSHPIQ004A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    label="severe"
                    name="Q004.C003xSHPIQ004A003"
                  />
                  <ChartRowElement
                    type="input"
                    {...chartFavoriteEditMode}
                    name="Q004.C003xSHPIQ004A004"
                    size="xs"
                    inputType="number"
                    textAlign="center"
                  />
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name="Q004.C003xSHPIQ004A011"
                    label="/10"
                  />
                  <ChartRowElement
                    type="input"
                    {...chartFavoriteEditMode}
                    name="Q004.C003xSHPIQ004A005"
                  />
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    label="worse / persistent since"
                    name="Q004.C003xSHPIQ004A006"
                  />
                  <ChartRowElement
                    type="input"
                    {...chartFavoriteEditMode}
                    name="Q004.C003xSHPIQ004A007"
                  />
                </ChartRow>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name="Q004.C003xSHPIQ004A012"
                    label="pain"
                  />
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    label="intermittent"
                    name="Q004.C003xSHPIQ004A008"
                  />
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    label="lasting"
                    name="Q004.C003xSHPIQ004A009"
                  />
                  <ChartRowElement
                    type="input"
                    {...chartFavoriteEditMode}
                    name="Q004.C003xSHPIQ004A010"
                  />
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q005"
              subsectionLabel="Context"
            >
              Context
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="fall"
                  name="Q005.C006xSHPIQ005A001"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="blow"
                  name="Q005.C006xSHPIQ005A002"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="incision"
                  name="Q005.C006xSHPIQ005A003"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="crush"
                  name="Q005.C006xSHPIQ005A004"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="burn"
                  name="Q005.C006xSHPIQ005A005"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q005.C006xSHPIQ005A006"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="PCP sent to ER"
                  name="Q005.C006xSHPIQ005A007"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q005.C006xSHPIQ005A008"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q006"
              subsectionLabel="Associated Symptoms"
            >
              Associated Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="tingling"
                  name="Q006.C006xSHPIQ006A001"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="numbness distally"
                  name="Q006.C006xSHPIQ006A002"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q006.C006xSHPIQ006A003"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="loss feeling"
                  name="Q006.C006xSHPIQ006A004"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="power"
                  name="Q006.C006xSHPIQ006A005"
                />
                <ChartRowElement
                  type="circle-option"
                  label="L"
                  name="Q006.C006xSHPIQ006A006"
                />
                <ChartRowElement
                  type="circle-option"
                  label="R"
                  name="Q006.C006xSHPIQ006A007"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="arm"
                  name="Q006.C006xSHPIQ006A008"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q006.C006xSHPIQ006A009"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q007"
              subsectionLabel="Modifying Factors"
            >
              Modifying Factors
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="none"
                  name="Q007.C006xSHPIQ007A001"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="pain on movement"
                  name="Q007.C006xSHPIQ007A002"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q007.C006xSHPIQ007A003"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T06HPI };
