import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
} from "modules/charts-shared/types";
import {
  ChartRow,
  ChartRowElement,
  ChartRowElementImportedProps,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules/charts-shared/components";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import {
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules/charts-shared";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrRos } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const SECTION_CODE = "SROS";
const CHART_CODE = "C070a";

function T70ROS() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ros").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode: CHART_CODE,
    encounterId,
    sectionId: SECTION_CODE,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode: CHART_CODE,
      encounterId,
      sectionId: SECTION_CODE,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode: CHART_CODE,
            sectionId: SECTION_CODE,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("ROS") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
      getValues,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "ros",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode: CHART_CODE,
          sectionId: SECTION_CODE,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("ROS") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  const chartFavoriteEditMode: Record<
    "imported",
    ChartRowElementImportedProps
  > | null = getValues("chartFavoriteEditMode")
    ? {
        imported: {
          type: "nursing",
        },
      }
    : null;

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={SECTION_CODE}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrRos} />}
          chartId={CHART_CODE}
          encounterId={encounterId}
          sectionId={SECTION_CODE}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          ROS {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q001"
              subsectionLabel="CONST"
            >
              CONST
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q001.C070aSROSQ001A001"
                  label="recent illness"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q001.C070aSROSQ001A002"
                  label="fever"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q001.C070aSROSQ001A003"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q001.C070aSROSQ001A004"
                  label="other"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q001.C070aSROSQ001A005"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q002"
              subsectionLabel="MS"
            >
              MS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q002.C070aSROSQ002A001"
                  label="neck pain"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q002.C070aSROSQ002A002"
                  label="back pain"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q002.C070aSROSQ002A003"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q002.C070aSROSQ002A004"
                  label="calf pain"
                />
                <ChartRowElement
                  type="circle-option"
                  name="Q002.C070aSROSQ002A005"
                  label="L"
                />
                <ChartRowElement
                  type="circle-option"
                  name="Q002.C070aSROSQ002A006"
                  label="R"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q002.C070aSROSQ002A007"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q002.C070aSROSQ002A008"
                  label="other"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q002.C070aSROSQ002A009"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q003"
              subsectionLabel="LYMPH"
            >
              LYMPH
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q003.C070aSROSQ003A001"
                  label="ankle swelling"
                />
                <ChartRowElement
                  type="circle-option"
                  name="Q003.C070aSROSQ003A002"
                  label="L"
                />
                <ChartRowElement
                  type="circle-option"
                  name="Q003.C070aSROSQ003A003"
                  label="R"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q003.C070aSROSQ003A004"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q003.C070aSROSQ003A005"
                  label="other"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q003.C070aSROSQ003A006"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q004"
              subsectionLabel="GI"
            >
              GI
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q004.C070aSROSQ004A001"
                  label="abdominal pain"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q004.C070aSROSQ004A002"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q004.C070aSROSQ004A003"
                  label="black stools"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q004.C070aSROSQ004A004"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q004.C070aSROSQ004A005"
                  label="other"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q004.C070aSROSQ004A006"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q005"
              subsectionLabel="GU"
            >
              GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q005.C070aSROSQ005A001"
                  label="problems urinating"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q005.C070aSROSQ005A002"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q005.C070aSROSQ005A003"
                  label="LMNP"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q005.C070aSROSQ005A004"
                  width="30rem"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q005.C070aSROSQ005A005"
                  label="pre-menopausal"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q005.C070aSROSQ005A006"
                  label="post-menopausal"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q005.C070aSROSQ005A007"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q005.C070aSROSQ005A008"
                  label="other"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q005.C070aSROSQ005A009"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q006"
              subsectionLabel="EYES"
            >
              EYES
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q006.C070aSROSQ006A001"
                  label="problem with vision"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q006.C070aSROSQ006A002"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q006.C070aSROSQ006A003"
                  label="other"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q006.C070aSROSQ006A004"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q007"
              subsectionLabel="ENT"
            >
              ENT
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q007.C070aSROSQ007A001"
                  label="sore throat"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q007.C070aSROSQ007A002"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q007.C070aSROSQ007A003"
                  label="other"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q007.C070aSROSQ007A004"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q008"
              subsectionLabel="SKIN"
            >
              SKIN
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q008.C070aSROSQ008A001"
                  label="rash"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q008.C070aSROSQ008A002"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q008.C070aSROSQ008A003"
                  label="other"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q008.C070aSROSQ008A004"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q009"
              subsectionLabel="ENDO"
            >
              ENDO
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q009.C070aSROSQ009A001"
                  label="recent weight change"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q009.C070aSROSQ009A002"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q009.C070aSROSQ009A003"
                  label="other"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q009.C070aSROSQ009A004"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q010"
              subsectionLabel="NEURO"
            >
              NEURO
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q010.C070aSROSQ010A001"
                  label="fainting"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q010.C070aSROSQ010A002"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q010.C070aSROSQ010A003"
                  label="headache"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q010.C070aSROSQ010A004"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q010.C070aSROSQ010A005"
                  label="other"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q010.C070aSROSQ010A006"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q011"
              subsectionLabel="PSYCH"
            >
              PSYCH
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q011.C070aSROSQ011A001"
                  label="anxiety"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q011.C070aSROSQ011A002"
                  label="depression"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q011.C070aSROSQ011A003"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q010.C070aSROSQ010A004"
                  label="other"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q011.C070aSROSQ011A005"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={CHART_CODE}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
              subsectionId="Q012"
              subsectionLabel="Additional Findings"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q012.C070aSROSQ012A001`}
                    w="60px"
                  >
                    none
                  </ChartRowElement>
                </>
              }
              isRequired={false}
            >
              Additional Findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q012.C070aSROSQ012A002"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T70ROS };
