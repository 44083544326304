import { ProviderChartCode } from "enums";

const PROVIDER_CHARTS: Record<ProviderChartCode, string> = {
  C001x: "(T-01) Head / Eye Injury",
  C003x: "(T-03) Facial / Scalp Injury",
  C006x: "(T-06) Upper Extremity Injury",
  C014x: "(T-14) Pediatric Illness",
  C016x: "(T-16) Pediatric Injury",
  C017x: "(T-17) MVC",
  C021x: "(T-21) Animal Bite",
  C022x: "(T-22) Major Burn / Smoke Inhalation",
  C024x: "(T-24) Headache / General Medicine",
  C030x: "(T-30) Respiratory / Flu / Fever",
  C031x: "(T-31) Chest Pain / Wheezing / Asthma / Dyspnea",
  C035x: "(T-35) Extremity Problems",
  C036x: "(T-36) Abdominal Pain / NVD",
  C039x: "(T-39) Female GU",
  C040x: "(T-40) Pregnancy",
  C041x: "(T-41) Male GU",
  C043x: "(T-43) Skin Rash / Abscess",
  C044x: "(T-44) Allergies",
  C046x: "(T-46) Neuro Deficit / Mental Status / Dizziness",
  C052x: "(T-52) Psych Disorder / Suicide Attempt / OD",
  C070a: "(T-70) Observation",
  C073x: "(T-73) Injuries",
  C074x: "(T-74) Sore Throat / Dental Pain",
} as const;

export { PROVIDER_CHARTS };
