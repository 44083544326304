import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartRowElementImportedProps,
  ChartRowGroup,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrPerson } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { PHYSICAL_EXAM_GRID_COLUMNS } from "modules/provider/shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SPEX";
const chartCode = "C016x";

function T16PhysicalExam() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
    watch,
  } = useFormContext;

  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:pex").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values, ["Q001"]);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("Physical Exam") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
      getValues,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "physical-exam",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject, ["Q001"]);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Physical Exam") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  const q002AllNormal = Boolean(watch(`Q002.${chartCode}${sectionId}Q002A001`));
  const q003AllNormal = Boolean(watch(`Q003.${chartCode}${sectionId}Q003A001`));
  const q004AllNormal = Boolean(watch(`Q004.${chartCode}${sectionId}Q004A001`));
  const q005AllNormal = Boolean(watch(`Q005.${chartCode}${sectionId}Q005A001`));
  const q006AllNormal = Boolean(watch(`Q006.${chartCode}${sectionId}Q006A001`));
  const q007AllNormal = Boolean(watch(`Q007.${chartCode}${sectionId}Q007A001`));
  const q008AllNormal = Boolean(watch(`Q008.${chartCode}${sectionId}Q008A001`));
  const q009AllNormal = Boolean(watch(`Q009.${chartCode}${sectionId}Q009A001`));
  const q010AllNormal = Boolean(watch(`Q010.${chartCode}${sectionId}Q010A001`));
  const q011AllNormal = Boolean(watch(`Q011.${chartCode}${sectionId}Q011A001`));
  const q012AllNormal = Boolean(watch(`Q012.${chartCode}${sectionId}Q012A001`));
  const q013AllNormal = Boolean(watch(`Q013.${chartCode}${sectionId}Q013A001`));
  const q014AllNormal = Boolean(watch(`Q014.${chartCode}${sectionId}Q014A001`));
  const q015AllNormal = Boolean(watch(`Q015.${chartCode}${sectionId}Q015A001`));

  const chartFavoriteEditMode: Record<
    "imported",
    ChartRowElementImportedProps
  > | null = getValues("chartFavoriteEditMode")
    ? {
        imported: {
          type: "nursing",
        },
      }
    : null;

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPerson} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          Physical Exam {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowGroup>
                  <ChartRowElement
                    type="checkbox"
                    name={`Q001.${chartCode}${sectionId}Q001A001`}
                  />
                  <ChartRowElement
                    label="Nursing Assessment reviewed"
                    pr="0px"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q001.${chartCode}${sectionId}Q001A003`}
                    color="black"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    pl="0px"
                    marginInline="0px"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="checkbox"
                    name={`Q001.${chartCode}${sectionId}Q001A002`}
                  />
                  <ChartRowElement
                    label="Vitals Reviewed"
                    pr="0px"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q001.${chartCode}${sectionId}Q001A004`}
                    color="black"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    pl="0px"
                    marginInline="0px"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="General Appearance"
            >
              General Appearance
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ambulatory"
                    name={`Q002.${chartCode}${sectionId}Q002A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="c-collar"
                    name={`Q002.${chartCode}${sectionId}Q002A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="backboard"
                    name={`Q002.${chartCode}${sectionId}Q002A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="PTA"
                    name={`Q002.${chartCode}${sectionId}Q002A005`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="in ED"
                    name={`Q002.${chartCode}${sectionId}Q002A006`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A007`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no acute distress"
                    name={`Q002.${chartCode}${sectionId}Q002A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="mild distress"
                    name={`Q002.${chartCode}${sectionId}Q002A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="moderate distress"
                    name={`Q002.${chartCode}${sectionId}Q002A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="severe distress"
                    name={`Q002.${chartCode}${sectionId}Q002A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A012`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="appears normal"
                    name={`Q002.${chartCode}${sectionId}Q002A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ill"
                    name={`Q002.${chartCode}${sectionId}Q002A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="uncomfortable"
                    name={`Q002.${chartCode}${sectionId}Q002A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="crying"
                    name={`Q002.${chartCode}${sectionId}Q002A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="inconsolable"
                    name={`Q002.${chartCode}${sectionId}Q002A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A018`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="attentiveness nml"
                    name={`Q002.${chartCode}${sectionId}Q002A019`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="lethargic"
                    name={`Q002.${chartCode}${sectionId}Q002A020`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="weak cry"
                    name={`Q002.${chartCode}${sectionId}Q002A021`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A022`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q002.${chartCode}${sectionId}Q002A030`}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A025`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Head"
            >
              Head
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no evidence of trauma"
                    name={`Q003.${chartCode}${sectionId}Q003A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="racoon eyes"
                    name={`Q003.${chartCode}${sectionId}Q003A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="Battle's sign"
                    name={`Q003.${chartCode}${sectionId}Q003A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A007`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="soft tissue swelling"
                    name={`Q003.${chartCode}${sectionId}Q003A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />

                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A009`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bony deformity"
                    name={`Q003.${chartCode}${sectionId}Q003A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />

                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A011`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="scalp laceration"
                    name={`Q003.${chartCode}${sectionId}Q003A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="facial trauma"
                    name={`Q003.${chartCode}${sectionId}Q003A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A016`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q003.${chartCode}${sectionId}Q003A017`}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A018`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Neck"
            >
              Neck
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q004.${chartCode}${sectionId}Q004A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="vertral point-tenderness"
                    name={`Q004.${chartCode}${sectionId}Q004A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A004`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="painless ROM"
                    name={`Q004.${chartCode}${sectionId}Q004A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="muscle spasm"
                    name={`Q004.${chartCode}${sectionId}Q004A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="decreased ROM"
                    name={`Q004.${chartCode}${sectionId}Q004A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A008`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="trachea midline"
                    name={`Q004.${chartCode}${sectionId}Q004A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pain on neck movement of neck"
                    name={`Q004.${chartCode}${sectionId}Q004A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A008`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q004.${chartCode}${sectionId}Q004A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A010`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Nexus Criteria"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q005.${chartCode}${sectionId}Q005A001`}
                    w="93px"
                  >
                    negative
                  </ChartRowElement>
                </>
              }
            >
              Nexus Criteria
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="midline tenderness"
                    name={`Q005.${chartCode}${sectionId}Q005A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="distracting injury"
                    name={`Q005.${chartCode}${sectionId}Q005A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A004`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="altered mental status"
                    name={`Q005.${chartCode}${sectionId}Q005A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="recent ETOH"
                    name={`Q005.${chartCode}${sectionId}Q005A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A007`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="focal neuro deficit"
                    name={`Q005.${chartCode}${sectionId}Q005A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A009`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Eyes"
            >
              Eyes
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="PERRL"
                    name={`Q006.${chartCode}${sectionId}Q006A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="unequal pupils"
                    name={`Q006.${chartCode}${sectionId}Q006A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q006.${chartCode}${sectionId}Q006A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A005`}
                    type="input"
                    {...chartFavoriteEditMode}
                    width="50px"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="mm"
                    fontStyle="italic"
                    name={`Q006.${chartCode}${sectionId}Q006A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q006.${chartCode}${sectionId}Q006A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A008`}
                    type="input"
                    {...chartFavoriteEditMode}
                    width="50px"
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="mm"
                    fontStyle="italic"
                    name={`Q006.${chartCode}${sectionId}Q006A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A010`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="EOMI"
                    name={`Q006.${chartCode}${sectionId}Q006A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="EOM entrapment"
                    name={`Q006.${chartCode}${sectionId}Q006A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="EOM palsy"
                    name={`Q006.${chartCode}${sectionId}Q006A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q006.${chartCode}${sectionId}Q006A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q006.${chartCode}${sectionId}Q006A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A016`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="subconjunctival hemmorhage"
                    name={`Q006.${chartCode}${sectionId}Q006A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q006.${chartCode}${sectionId}Q006A018`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q006.${chartCode}${sectionId}Q006A019`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A020`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="lids & conjunct. nml"
                    name={`Q006.${chartCode}${sectionId}Q006A021`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pale conjunctivae"
                    name={`Q006.${chartCode}${sectionId}Q006A022`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A023`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="corneal abrasion"
                    name={`Q006.${chartCode}${sectionId}Q006A024`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="hyphema"
                    name={`Q006.${chartCode}${sectionId}Q006A025`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q006.${chartCode}${sectionId}Q006A026`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q006.${chartCode}${sectionId}Q006A027`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A028`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q006.${chartCode}${sectionId}Q006A029`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A030`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="ENT"
            >
              ENT
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ears nml"
                    name={`Q007.${chartCode}${sectionId}Q007A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="hemotympanum"
                    name={`Q007.${chartCode}${sectionId}Q007A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q007.${chartCode}${sectionId}Q007A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q007.${chartCode}${sectionId}Q007A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A006`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="TM obscured by wax"
                    name={`Q007.${chartCode}${sectionId}Q007A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q007.${chartCode}${sectionId}Q007A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q007.${chartCode}${sectionId}Q007A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A010`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nose nml"
                    name={`Q007.${chartCode}${sectionId}Q007A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="clotted nasal blood"
                    name={`Q007.${chartCode}${sectionId}Q007A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q007.${chartCode}${sectionId}Q007A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q007.${chartCode}${sectionId}Q007A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A015`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nasal septal hematoma"
                    name={`Q007.${chartCode}${sectionId}Q007A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q007.${chartCode}${sectionId}Q007A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q007.${chartCode}${sectionId}Q007A018`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A019`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="dental injury"
                    name={`Q007.${chartCode}${sectionId}Q007A020`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="malocclusion"
                    name={`Q007.${chartCode}${sectionId}Q007A021`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A022`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pharynx nml"
                    name={`Q007.${chartCode}${sectionId}Q007A023`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="dry mucous membranes"
                    name={`Q007.${chartCode}${sectionId}Q007A024`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A025`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q007.${chartCode}${sectionId}Q007A026`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A027`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Respiratory / CVS"
            >
              Respiratory / CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="chest non-tender"
                    name={`Q008.${chartCode}${sectionId}Q008A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q008.${chartCode}${sectionId}Q008A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q008.${chartCode}${sectionId}Q008A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q008.${chartCode}${sectionId}Q008A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A006`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="crepitus"
                    name={`Q008.${chartCode}${sectionId}Q008A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="subcontaneous emphysema"
                    name={`Q008.${chartCode}${sectionId}Q008A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A009`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="breath sounds nml"
                    name={`Q008.${chartCode}${sectionId}Q008A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="decreased breath sounds"
                    name={`Q008.${chartCode}${sectionId}Q008A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q008.${chartCode}${sectionId}Q008A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q008.${chartCode}${sectionId}Q008A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A014`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="wheezes"
                    name={`Q008.${chartCode}${sectionId}Q008A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="rales"
                    name={`Q008.${chartCode}${sectionId}Q008A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="rhonchi"
                    name={`Q008.${chartCode}${sectionId}Q008A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q008.${chartCode}${sectionId}Q008A018`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q008.${chartCode}${sectionId}Q008A019`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A020`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="guarding on breathing"
                    name={`Q008.${chartCode}${sectionId}Q008A021`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A022`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="heart sounds nml"
                    name={`Q008.${chartCode}${sectionId}Q008A023`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tachycardia"
                    name={`Q008.${chartCode}${sectionId}Q008A024`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="bradycardia"
                    name={`Q008.${chartCode}${sectionId}Q008A025`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A026`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="strong peripheral pulses"
                    name={`Q008.${chartCode}${sectionId}Q008A027`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="peripheral pulses"
                    name={`Q008.${chartCode}${sectionId}Q008A028`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="weak"
                    fontStyle="italic"
                    name={`Q008.${chartCode}${sectionId}Q008A029`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="thready"
                    fontStyle="italic"
                    name={`Q008.${chartCode}${sectionId}Q008A030`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A031`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml capillary refill"
                    name={`Q008.${chartCode}${sectionId}Q008A032`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="slow capillary refill"
                    name={`Q008.${chartCode}${sectionId}Q008A033`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A034`}
                    type="input"
                    {...chartFavoriteEditMode}
                    width="50px"
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="sec"
                    fontStyle="italic"
                    name={`Q008.${chartCode}${sectionId}Q008A035`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A036`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q008.${chartCode}${sectionId}Q008A037`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A038`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Abdomen"
            >
              Abdomen
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q009.${chartCode}${sectionId}Q009A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q009.${chartCode}${sectionId}Q009A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="rebound"
                    name={`Q009.${chartCode}${sectionId}Q009A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="guarding"
                    name={`Q009.${chartCode}${sectionId}Q009A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="guarding"
                    fontStyle="italic"
                    name={`Q009.${chartCode}${sectionId}Q009A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="LUQ"
                    fontStyle="italic"
                    name={`Q009.${chartCode}${sectionId}Q009A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="RUQ"
                    fontStyle="italic"
                    name={`Q009.${chartCode}${sectionId}Q009A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="LLQ"
                    fontStyle="italic"
                    name={`Q009.${chartCode}${sectionId}Q009A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="RLQ"
                    fontStyle="italic"
                    name={`Q009.${chartCode}${sectionId}Q009A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A011`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no organomegaly"
                    name={`Q009.${chartCode}${sectionId}Q009A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="mass"
                    name={`Q009.${chartCode}${sectionId}Q009A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="organomegaly"
                    name={`Q009.${chartCode}${sectionId}Q009A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A015`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml bowel sounds"
                    name={`Q009.${chartCode}${sectionId}Q009A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="anbml bowel sounds"
                    name={`Q009.${chartCode}${sectionId}Q009A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A018`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no seat belt trauma"
                    name={`Q009.${chartCode}${sectionId}Q009A019`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A020`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q009.${chartCode}${sectionId}Q009A021`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A022`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              isRequired={false}
              subsectionLabel="Genital"
            >
              Genital
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml genital exam"
                    name={`Q010.${chartCode}${sectionId}Q010A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="perineal hematoma"
                    name={`Q010.${chartCode}${sectionId}Q010A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A004`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml vaginal exam"
                    name={`Q010.${chartCode}${sectionId}Q010A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="blood on urethral meatus"
                    name={`Q010.${chartCode}${sectionId}Q010A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A007`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="exam consistent with abuse"
                    name={`Q010.${chartCode}${sectionId}Q010A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A009`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q010.${chartCode}${sectionId}Q010A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A011`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              isRequired={false}
              subsectionLabel="Rectal"
            >
              Rectal
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml rectal exam"
                    name={`Q011.${chartCode}${sectionId}Q011A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="decreased rectal tone"
                    name={`Q011.${chartCode}${sectionId}Q011A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A004`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="heme negative stool"
                    name={`Q011.${chartCode}${sectionId}Q011A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A006`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q011.${chartCode}${sectionId}Q011A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A008`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q012"
              subsectionLabel="Back"
            >
              Back
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q012.${chartCode}${sectionId}Q012A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="vertebral point-tenderness"
                    name={`Q012.${chartCode}${sectionId}Q012A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A004`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="CVA tenderness"
                    name={`Q012.${chartCode}${sectionId}Q012A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q012.${chartCode}${sectionId}Q012A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q012.${chartCode}${sectionId}Q012A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A008`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="muscle spasm"
                    name={`Q012.${chartCode}${sectionId}Q012A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A010`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q012.${chartCode}${sectionId}Q012A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A012`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q013"
              subsectionLabel="Skin"
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml color, warm, dry"
                    name={`Q013.${chartCode}${sectionId}Q013A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cyanosis"
                    name={`Q013.${chartCode}${sectionId}Q013A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="diaphoresis"
                    name={`Q013.${chartCode}${sectionId}Q013A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="pallor"
                    name={`Q013.${chartCode}${sectionId}Q013A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="erythema"
                    name={`Q013.${chartCode}${sectionId}Q013A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="icterus"
                    name={`Q013.${chartCode}${sectionId}Q013A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q013.${chartCode}${sectionId}Q013A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A009`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no rash"
                    name={`Q013.${chartCode}${sectionId}Q013A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="skin rash"
                    name={`Q013.${chartCode}${sectionId}Q013A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="zooster-like"
                    fontStyle="italic"
                    name={`Q013.${chartCode}${sectionId}Q013A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="petechiae"
                    name={`Q013.${chartCode}${sectionId}Q013A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="icterus"
                    name={`Q013.${chartCode}${sectionId}Q013A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="lymphangitis"
                    name={`Q013.${chartCode}${sectionId}Q013A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A016`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="intact"
                    name={`Q013.${chartCode}${sectionId}Q013A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="abrasions"
                    name={`Q013.${chartCode}${sectionId}Q013A018`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="laceration"
                    name={`Q013.${chartCode}${sectionId}Q013A019`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A020`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q013.${chartCode}${sectionId}Q013A021`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A022`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q014"
              subsectionLabel="Extremities"
            >
              Extremities
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="moves all extremities"
                    name={`Q014.${chartCode}${sectionId}Q014A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="painful"
                    name={`Q014.${chartCode}${sectionId}Q014A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="unable to bear weight"
                    name={`Q014.${chartCode}${sectionId}Q014A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q014.${chartCode}${sectionId}Q014A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q014.${chartCode}${sectionId}Q014A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A007`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="painless ROM"
                    name={`Q014.${chartCode}${sectionId}Q014A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="joint swelling"
                    name={`Q014.${chartCode}${sectionId}Q014A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="extremity swelling"
                    name={`Q014.${chartCode}${sectionId}Q014A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q014.${chartCode}${sectionId}Q014A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q014.${chartCode}${sectionId}Q014A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A013`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no pulse deficits"
                    name={`Q014.${chartCode}${sectionId}Q014A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pulse deficit"
                    name={`Q014.${chartCode}${sectionId}Q014A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q014.${chartCode}${sectionId}Q014A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q014.${chartCode}${sectionId}Q014A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A018`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q014.${chartCode}${sectionId}Q014A019`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="deformity"
                    name={`Q014.${chartCode}${sectionId}Q014A020`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q014.${chartCode}${sectionId}Q014A021`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q014.${chartCode}${sectionId}Q014A022`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A023`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q014.${chartCode}${sectionId}Q014A024`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A025`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q015"
              subsectionLabel="Hips / Pelvis"
            >
              Hips / Pelvis
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="hips non-tender"
                    name={`Q015.${chartCode}${sectionId}Q015A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bony point-tenderness"
                    name={`Q015.${chartCode}${sectionId}Q015A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q015.${chartCode}${sectionId}Q015A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q015.${chartCode}${sectionId}Q015A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    name={`Q015.${chartCode}${sectionId}Q015A006`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q015AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pelvis stable"
                    name={`Q015.${chartCode}${sectionId}Q015A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pain on hip movement"
                    name={`Q015.${chartCode}${sectionId}Q015A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q015.${chartCode}${sectionId}Q015A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q015.${chartCode}${sectionId}Q015A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    name={`Q015.${chartCode}${sectionId}Q015A011`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q015AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q015.${chartCode}${sectionId}Q015A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q015AllNormal}
                  />
                  <ChartRowElement
                    name={`Q015.${chartCode}${sectionId}Q015A013`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q015AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q016"
              subsectionLabel="Additional Findings"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q016.${chartCode}${sectionId}Q016A001`}
                    w="60px"
                  >
                    none
                  </ChartRowElement>
                </>
              }
              isRequired={false}
            >
              Additional Findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q016.${chartCode}${sectionId}Q016A002`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T16PhysicalExam };
