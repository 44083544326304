import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useToast } from "hooks";
import { formMessages } from "messages";
import {
  ChartFavorite,
  ChartFavoriteRequest,
  mapFormToPayload,
  useAddChartFavorite,
  useUpdateChartFavoriteInfo,
} from "modules";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Button, Input } from "shared";
import { extractApiErrorMessage } from "utils";

type ChartFavoriteRenameModalProps = {
  item: ChartFavorite;
  isOpen: boolean;
  onClose: () => void;
};

function ChartFavoriteRenameModal({
  item,
  isOpen,
  onClose,
}: ChartFavoriteRenameModalProps) {
  const toast = useToast();

  const [name, setName] = useState(item.title);

  const {
    mutateAsync: updateChartFavorite,
    isLoading: isUpdateChartFavoriteLoading,
  } = useUpdateChartFavoriteInfo({
    id: item.id,
    sectionCode: item.sectionCode,
  });

  const handleRename = async () => {
    try {
      const payload = {
        title: name,
        isFavorite: item.isFavorite,
      };

      await updateChartFavorite(payload);

      toast({
        status: "success",
        description: formMessages.updateSuccess("Chart Favorite"),
      });
      onClose();
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxW="unset"
        width="360px"
        containerProps={{ justifyContent: "flex-end" }}
        height="200px"
        margin="auto"
        overflowY="auto"
        overflowX="hidden"
      >
        <ModalHeader
          display="flex"
          justifyContent="center"
          fontSize="1rem"
          padding="20px 16px 15px"
          alignItems="center"
        >
          <Heading
            fontWeight="600"
            fontSize="1.0625rem"
            textAlign="center"
            display="flex"
            alignItems="center"
            gap={1}
          >
            Rename Favorite
          </Heading>
        </ModalHeader>
        <ModalBody p="15px">
          <Input
            onChange={(e) => setName(e.target.value)}
            value={name}
            bg="white"
          />
          <Flex justifyContent="space-between" mt="20px">
            <Button
              variant="outlineSquared"
              color="red"
              borderColor="red"
              onClick={onClose}
              borderRadius="10px"
              w="150px"
              sx={{ height: "40px" }}
            >
              Cancel
            </Button>
            <Button
              variant="outlineSquared"
              disabled={name === "" || isUpdateChartFavoriteLoading}
              onClick={handleRename}
              isLoading={isUpdateChartFavoriteLoading}
              borderRadius="10px"
              w="150px"
              sx={{ height: "40px" }}
            >
              Save
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

type ChartFavoriteCreateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  sectionCode: string;
  chartCode: string;
};

function ChartFavoriteCreateModal({
  isOpen,
  onClose,
  sectionCode,
  chartCode,
}: ChartFavoriteCreateModalProps) {
  const toast = useToast();
  const { getValues, setValue, reset } = useFormContext();

  const [name, setName] = useState("");

  const { mutateAsync: addChartFavorite, isLoading: isAddFavoriteLoading } =
    useAddChartFavorite({ sectionCode, chartCode });

  const handleAddNewElement = async () => {
    try {
      const form = getValues();
      delete form.chartFavoriteEditMode;
      const subsections = mapFormToPayload(form);
      const payload: ChartFavoriteRequest = {
        title: name,
        chartCode,
        sectionCode,
        isFavorite: false,
        subSections: subsections
          .map((x) => ({
            code: x.code,
            answers: x.answers.map((answer) => ({
              qCode: answer.qCode.slice(5),
              value: answer.value,
            })),
          }))
          .filter(
            (x) =>
              x.code !== "chartFavoriteId" && x.code !== "chartFavoriteEditMode"
          ),
      };
      const response = await addChartFavorite(payload);

      setName("");
      reset(form);
      setValue("chartFavoriteId", response.data.id, { shouldDirty: true });
      toast({
        status: "success",
        description: formMessages.createSuccess("Chart Favorite"),
      });
      onClose();
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxW="unset"
        width="360px"
        containerProps={{ justifyContent: "flex-end" }}
        height="200px"
        margin="auto"
        overflowY="auto"
        overflowX="hidden"
      >
        <ModalHeader
          display="flex"
          justifyContent="center"
          fontSize="1rem"
          padding="20px 16px 15px"
          alignItems="center"
        >
          <Heading
            fontWeight="600"
            fontSize="1.0625rem"
            textAlign="center"
            display="flex"
            alignItems="center"
          >
            Add New Favorite
          </Heading>
        </ModalHeader>
        <ModalBody p="15px">
          <Input
            onChange={(e) => setName(e.target.value)}
            value={name}
            bg="white"
          />
          <Flex justifyContent="space-between" mt="20px">
            <Button
              variant="outlineSquared"
              color="red"
              borderColor="red"
              onClick={onClose}
              borderRadius="10px"
              w="150px"
              sx={{ height: "40px" }}
            >
              Cancel
            </Button>
            <Button
              variant="outlineSquared"
              disabled={name === "" || isAddFavoriteLoading}
              onClick={handleAddNewElement}
              isLoading={isAddFavoriteLoading}
              borderRadius="10px"
              w="150px"
              sx={{ height: "40px" }}
            >
              Save
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export { ChartFavoriteRenameModal, ChartFavoriteCreateModal };
