import { useToast } from "@chakra-ui/react";
import { Infusion } from "icons";
import { formMessages } from "messages";
import {
  ChartSection,
  SharedChartSectionHeader,
  SharedChartSubsectionHeader,
} from "modules";
import {
  ChartRouteBaseParams,
  TriageIVMedicationForm,
} from "modules/charts-shared";
import { useCreateOrder } from "modules/orders/api";
import {
  useIvAndMedication,
  useUpdateIvAndMedication,
} from "modules/triage/api";
import {
  formToPayload,
  responseToForm,
} from "modules/triage/utils/iv-medications";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { IvStartsCard, IvStartsCardHeader } from "./IvStartsCard";

const SECTION_CODE = "SIVM";

function IVStarts(props: { isReadOnly?: boolean; chartCode: string }) {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const toast = useToast();
  const navigate = useNavigate();

  const { isReadOnly, chartCode } = props;

  const useFormContext = useForm<TriageIVMedicationForm>({
    defaultValues: {
      ivStarts: [],
    },
  });
  const {
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormContext;
  const { data: ivMedicationData, isLoading: getIsLoading } =
    useIvAndMedication(encounterId);
  const { mutateAsync: updateIvMedication, isLoading: updateIsLoading } =
    useUpdateIvAndMedication(encounterId);
  const { mutateAsync: createOrder, isLoading: isCreatingOrder } =
    useCreateOrder(encounterId, chartCode);

  const [nextRoute, setNextRoute] = useState<string | null>(null);

  useEffect(() => {
    if (ivMedicationData) {
      reset(responseToForm(ivMedicationData.data));
    }
  }, [ivMedicationData, reset]);

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    return () => {
      document.documentElement.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (nextRoute && !isDirty) {
      navigate(nextRoute);
      setNextRoute(null);
    }
  }, [nextRoute, navigate, isDirty]);

  if (getIsLoading) {
    return <Loading />;
  }
  const ivStarts = watch("ivStarts");

  const onSubmit = async (formData: TriageIVMedicationForm) => {
    const payload = formToPayload(formData);
    try {
      await updateIvMedication(payload);
      toast({ description: formMessages.updateSuccess("IV Starts") });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const handleCreateNewIVStart = async () => {
    try {
      await createOrder([
        {
          orderElementType: "Medication",
          description: "",
          location: "",
          notes: "",
          administrationTime: new Date().toString(),
          route: "IVP",
        },
      ]);
      toast({ description: formMessages.updateSuccess("IV Starts") });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  return (
    <FormProvider {...useFormContext}>
      <ChartSection
        onSubmit={handleSubmit(onSubmit)}
        disabled={isReadOnly || isCreatingOrder}
        onRouteChangeSave={(onComplete) => {
          handleSubmit(onSubmit)();
          onComplete();
        }}
      >
        <SharedChartSectionHeader
          icon={<Infusion />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
        >
          IV Starts {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>
        <SharedChartSubsectionHeader
          chartCode=""
          encounterId={encounterId}
          sectionCode={SECTION_CODE}
          subsectionCode="Q002"
          subsectionLabel="IV Starts"
          margin="1rem 0"
          showNotesBtn={false}
          showAuditsBtn={false}
          showInteractionsBtn={false}
          endButton={
            <Button
              variant="action"
              maxH="36px"
              isLoading={isCreatingOrder}
              onClick={handleCreateNewIVStart}
            >
              Add New IV
            </Button>
          }
        >
          IV Starts
        </SharedChartSubsectionHeader>
        <IvStartsCardHeader />
        {ivStarts.map((_, index) => (
          <IvStartsCard num={index} />
        ))}
      </ChartSection>
    </FormProvider>
  );
}

export { IVStarts };
