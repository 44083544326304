import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  BoxProps,
  Icon,
  chakra,
  HStack,
  Text,
  Flex,
  Tooltip,
  VStack,
  Image,
} from "@chakra-ui/react";
import { EmrSignature } from "@medstonetech/slate-icons";
import { useUserRoles } from "contexts/UserRoles";
import { ChartRowElement } from "modules/charts-shared";
import { UserType } from "modules/identity";
import { useGetUserSignature } from "modules/onboarding/api";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button, Card, Input, StaffAvatar } from "shared";
import { USERID_CLAIMS } from "system-constants";

type IvStartsCardProps = {
  num: number;
};

const GRID_PROPS: BoxProps = {
  textAlign: "center",
  display: "grid",
  gridTemplateColumns: "1fr repeat(4, 0.5fr) 6fr 1fr",
  gridColumnGap: "0.25rem",
  alignItems: "center",
};

function IvStartsCardHeader() {
  return (
    <Card
      bg="gray.250"
      marginBottom="1rem"
      padding="1rem 1.5rem"
      boxShadow="none"
      {...GRID_PROPS}
    >
      <chakra.span>Date / Time</chakra.span>
      <chakra.span>#</chakra.span>
      <chakra.span>Site</chakra.span>
      <chakra.span>Ga</chakra.span>
      <chakra.span>Attempts</chakra.span>
      <chakra.span>Inserted By</chakra.span>
      <chakra.span>Delete IV</chakra.span>
    </Card>
  );
}

function IvStartsSignature(props: { index: number }) {
  const { index } = props;

  const { watch, setValue } = useFormContext();

  const signedBy = watch(`ivStarts[${index}].signedBy`);

  const { data: signature } = useGetUserSignature(signedBy || "");

  const { user } = useAuth0();

  const handleSign = () =>
    setValue(`ivStarts[${index}].signedBy`, user?.[USERID_CLAIMS], {
      shouldDirty: true,
    });

  const { roles } = useUserRoles();

  const isAllowedToSign = React.useMemo(() => {
    const rolesAllowedToViewOpenChartCounter: UserType[] = [
      "Administrator",
      "SupervisingProvider",
      "Provider",
    ];

    return roles.some((x) => rolesAllowedToViewOpenChartCounter.includes(x));
  }, [roles]);

  return (
    <Box height="100px" p={6} width="100%" bgColor="white" borderRadius={20}>
      <HStack width="100%" spacing={6}>
        {!!signedBy && (
          <Flex justifyContent="center" flex={1}>
            <HStack>
              <StaffAvatar
                w="40px"
                h="40px"
                size="xs"
                fontSize=".75rem"
                userName={signature?.data?.fullName}
                profileUrl={signature?.data?.pictureUrl}
              />
              <VStack spacing="0" alignItems="flex-start">
                <Box
                  fontSize="1.0625rem"
                  fontWeight="600"
                  lineHeight="1.5rem"
                  color="black"
                >
                  {signature?.data?.fullName}
                </Box>
                <Box
                  fontSize="0.875rem"
                  fontWeight="400"
                  lineHeight="1rem"
                  color="gray.700"
                >
                  {signature?.data?.teams}
                </Box>
              </VStack>
            </HStack>
          </Flex>
        )}
        <HStack
          pl={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={40}
          flex={2}
        >
          <HStack spacing={10}>
            {!!signedBy ? (
              <>
                <Text fontWeight="600" fontSize="16px" color="gray.700">
                  E-signed by:
                </Text>
                <Box minW={300} maxW={400} minH={50}>
                  <Image
                    src={signature?.data?.signature}
                    height="100%"
                    width="100%"
                  />
                </Box>
              </>
            ) : (
              <Tooltip
                isOpen={!isAllowedToSign}
                label="Only Providers are allowed to sign"
              >
                <Button
                  variant="label"
                  color="blue"
                  onClick={handleSign}
                  disabled={!isAllowedToSign}
                >
                  <Icon
                    as={EmrSignature}
                    fontSize="1.5rem"
                    color="blue"
                    m={2}
                  />
                  Add Signature
                </Button>
              </Tooltip>
            )}
          </HStack>
        </HStack>
      </HStack>
    </Box>
  );
}

function IvStartsCard(props: IvStartsCardProps) {
  const { num } = props;
  const { register, control } = useFormContext();
  const { remove } = useFieldArray({ control, name: "ivStarts" });

  const handleRemoveIvStart = () => {
    remove(num);
  };

  return (
    <Card boxShadow="none" padding="1rem 1.5rem">
      <Box {...GRID_PROPS}>
        <ChartRowElement
          type="date"
          name={`ivStarts[${num}].T001xSIVMQ001A001`}
        />
        <chakra.span>{num + 1}</chakra.span>
        <Input {...register(`ivStarts[${num}].T001xSIVMQ001A002`)} />
        <Input {...register(`ivStarts[${num}].T001xSIVMQ001A003`)} />
        <Input {...register(`ivStarts[${num}].T001xSIVMQ001A004`)} />
        <IvStartsSignature index={num} />

        <Button
          variant="action"
          onClick={handleRemoveIvStart}
          color="red"
          borderColor="red"
          maxH="36px"
        >
          Delete IV
        </Button>
      </Box>
      <Box display="grid" gridTemplateColumns="4fr 0.5fr">
        <Flex direction="row" gap="1rem" alignItems="center">
          <chakra.span>Comments:</chakra.span>
          <Input {...register(`ivStarts[${num}].T001xSIVMQ001A005`)} />
        </Flex>
      </Box>
    </Card>
  );
}

export { IvStartsCard, IvStartsCardHeader };
export type { IvStartsCardProps };
