import {
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { MoreHoriz } from "icons";
import * as React from "react";
import { RestoreDeletedChartsModal } from "./chart-modal/RestoreDeletedChartsModal";
import { getFileUrl, isLastIndex } from "utils";
import { useGetFacesheetPDF } from "api";
import { Loading } from "shared";
import { MedicalRecordsOption } from "./medical-records";
import { useNavigate } from "react-router-dom";

type RestoreChartsButtonProps = { encounterId: string } & Pick<
  MenuItemProps,
  "isDisabled"
>;

function RestoreChartsButton(props: RestoreChartsButtonProps) {
  const { encounterId, isDisabled } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem
        isDisabled={isDisabled}
        onClick={onOpen}
        bg="white"
        fontSize="1.0625rem"
        fontWeight="400"
      >
        Restore Deleted Charts
      </MenuItem>
      <RestoreDeletedChartsModal
        isModalOpen={isOpen}
        onModalClose={onClose}
        encounterId={encounterId}
      />
      <Divider />
    </>
  );
}

type PrintFacesheetOptionProps = {
  printFacesheetPDF: () => void;
};

function PrintFacesheetOption(props: PrintFacesheetOptionProps) {
  const { printFacesheetPDF } = props;

  return (
    <MenuItem
      key="7"
      bg="white"
      fontSize="1.0625rem"
      fontWeight="400"
      onClick={printFacesheetPDF}
    >
      Print Facesheet
    </MenuItem>
  );
}

function ChartSectionHeaderMenu(props: { encounterId: string }) {
  const { encounterId } = props;

  const [enabled, setEnabled] = React.useState(false);

  const { isLoading, isFetching, data, refetch } = useGetFacesheetPDF(
    encounterId,
    { enabled }
  );

  const printFacesheetPDF = async () => {
    refetch();
    if (!enabled) setEnabled(true);
  };

  React.useEffect(() => {
    let fileUrl: string | undefined = undefined;
    if (!isLoading && !isFetching && enabled && !!data?.data) {
      const file = data?.data;
      if (file) {
        fileUrl = getFileUrl(file);
        const myWindow = window.open(fileUrl);
        myWindow?.focus();
        setEnabled(false);
      }
    }
    return () => {
      if (!!fileUrl) window.URL.revokeObjectURL(fileUrl);
    };
  }, [data, enabled, isFetching, isLoading]);

  const navigate = useNavigate();

  const mainRoute = window.location.hash.split("/")[1];

  const CHART_MENU_OPTIONS: MenuItemProps[] = React.useMemo(
    () => [
      {
        key: "1",
        children: "Create New Task",
        isDisabled: true,
      },
      {
        key: "2",
        children: "Go to My Task Manager",
        isDisabled: true,
      },
      {
        key: "3",
        children: "Go to Ayva",
        isDisabled: false,
        onClick: () => {
          window.open("https://ayva.bravadohealth.com/#!/login", "_blank");
        },
      },
      {
        key: "5",
        children: "Chart Summary",
        onClick: () => {
          navigate(`/${mainRoute}/charts/${encounterId}/summary`);
        },
      },
      {
        key: "6",
        children: "Open Auditor's View",
        isDisabled: true,
      },
    ],
    [encounterId, mainRoute, navigate]
  );

  return (
    <>
      {isLoading || isFetching ? (
        <Loading
          w="20px"
          h="20px"
          spinnerProps={{ size: "sm", color: "blue" }}
        />
      ) : (
        <>
          <Menu>
            <MenuButton
              as={IconButton}
              variant="icon"
              icon={<Icon as={MoreHoriz} color="blue" />}
            >
              Actions
            </MenuButton>
            <MenuList zIndex="popover">
              {CHART_MENU_OPTIONS.map(
                ({ key, icon, children, isDisabled, onClick }, index) => (
                  <React.Fragment key={key}>
                    {index === 3 && (
                      <>
                        <MedicalRecordsOption />
                        <Divider />
                      </>
                    )}
                    {index === 2 && (
                      <>
                        <RestoreChartsButton encounterId={encounterId} />
                        <PrintFacesheetOption
                          printFacesheetPDF={printFacesheetPDF}
                        />
                        <Divider />
                      </>
                    )}
                    <MenuItem
                      isDisabled={isDisabled}
                      onClick={onClick}
                      bg="white"
                      fontSize="1.0625rem"
                      fontWeight="400"
                    >
                      {children}
                      {icon}
                    </MenuItem>
                    {!isLastIndex(index, CHART_MENU_OPTIONS) && (
                      <Divider
                        {...((index === 1 || index === 3) && { height: "3px" })}
                      />
                    )}
                  </React.Fragment>
                )
              )}
            </MenuList>
          </Menu>
        </>
      )}
    </>
  );
}

export { ChartSectionHeaderMenu };
