import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useToast,
  Text,
  IconButton,
  chakra,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { EmrLock } from "@medstonetech/slate-icons";
import {
  CONCENTRATION_DOSE_UNITS,
  UNITS_ARRAY,
  VOLUME_UNITS,
} from "constants/orders";
import {
  useDeleteCommonDoseFavorite,
  useDoseFavorites,
} from "modules/charts-shared/api";
import { calculateMedicationAmount } from "modules/charts-shared/utils";
import {
  CommonDose,
  Concentration,
  OrderElementResponse,
} from "modules/order-list/types";
import { useFormContext } from "react-hook-form";
import {
  IoArrowDownCircleOutline,
  IoHeartDislikeOutline,
} from "react-icons/io5";
import { Button, Card, Checkbox, Label, Select } from "shared";
import { extractApiErrorMessage } from "utils";
import { getOptions } from "utils/enum-mapper";
import { MedicationOrderForm } from "./AddMedicationOrderModal";
import {
  VALID_CONCENTRATIONS_UNITS,
  VALID_UNITS,
  VALID_VOLUME_UNITS,
} from "./ValidCalculations";

type MyFavoriteDosesProps = {
  item: OrderElementResponse;
  showCalculation: () => void;
};

type MyFavoriteDosesForm = MedicationOrderForm;

const fieldLabelStyle = {
  mt: "10px",
  fontSize: "11pt",
};

function MyFavoriteDoses({ item, showCalculation }: MyFavoriteDosesProps) {
  const { register, setValue, watch, getValues } =
    useFormContext<MyFavoriteDosesForm>();

  const handleCalculate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = e;
    const { doseAmount, doseUnit, weight, concentration } = getValues();
    if (
      checked &&
      concentration.doseAmount &&
      concentration.doseUnit &&
      concentration.volumeAmount &&
      concentration.volumeUnit &&
      doseAmount &&
      doseUnit &&
      weight
    ) {
      const amount = calculateMedicationAmount(
        {
          doseAmount: concentration.doseAmount,
          doseUnit: concentration.doseUnit,
          volumeAmount: concentration.volumeAmount,
          volumeUnit: concentration.volumeUnit,
          id: "",
        },
        doseAmount,
        doseUnit,
        weight
      );
      setValue("amount", amount);
    } else {
      setValue("amount", null);
    }
  };

  const doseUnit = watch("doseUnit");
  const concentrationDoseUnit = watch("concentration.doseUnit");
  const volumeUnit = watch("concentration.volumeUnit");

  const validCalculation =
    VALID_UNITS.includes(doseUnit) &&
    !!concentrationDoseUnit &&
    VALID_CONCENTRATIONS_UNITS.includes(concentrationDoseUnit) &&
    !!volumeUnit &&
    VALID_VOLUME_UNITS.includes(volumeUnit);

  const toast = useToast();

  const getConcentration = (concentrationId?: string) => {
    const concentration: Concentration | undefined =
      item.orderFormElementConcentrations?.find(
        (c) => c.id === concentrationId
      );

    if (
      concentration &&
      concentration.doseAmount &&
      concentration.doseUnit &&
      concentration.volumeAmount &&
      concentration.volumeUnit
    ) {
      return ` (${concentration.doseAmount} ${concentration.doseUnit} / ${concentration.volumeAmount} ${concentration.volumeUnit})`;
    }

    return "";
  };

  const { data } = useDoseFavorites(item.id);

  const favorites = data?.data;

  const {
    mutateAsync: removeFromFavorites,
    isLoading: isRemovingFromFavorites,
  } = useDeleteCommonDoseFavorite();

  const onRemoveFavorite = async (id: string) => {
    try {
      await removeFromFavorites(id);
    } catch (error) {
      toast({
        description: extractApiErrorMessage(error),
      });
    }
  };

  const onImportDose = (dose: CommonDose, route?: string) => {
    const concentration: Concentration | undefined =
      item.orderFormElementConcentrations?.find(
        (c) => c.id === dose.orderFormEelementConcentrationId
      );

    setValue("doseAmount", dose.doseAmount);
    setValue("doseUnit", dose.doseUnit);
    setValue("route", route || "");

    if (concentration) {
      setValue("concentration.doseAmount", concentration.doseAmount);
      setValue("concentration.doseUnit", concentration.doseUnit);
      setValue("concentration.volumeAmount", concentration.volumeAmount);
      setValue("concentration.volumeUnit", concentration.volumeUnit);
    } else {
      setValue("concentration.doseAmount", undefined);
      setValue("concentration.doseUnit", undefined);
      setValue("concentration.volumeAmount", undefined);
      setValue("concentration.volumeUnit", undefined);
    }
  };

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Label {...fieldLabelStyle}>Medication Name *</Label>
      <InputGroup>
        <Input readOnly value={item?.description} />
        <InputRightElement>
          <Icon as={EmrLock} w="32px" h="32px" size="lg" color="gray.450" />
        </InputRightElement>
      </InputGroup>

      <Card variant="basic" p="15px 0" minH="40px" background="transparent">
        {!!favorites && favorites?.length > 0 ? (
          <Stack>
            {favorites?.map(({ id, dose, route }) => (
              <VStack key={id}>
                <HStack minW="100%" justifyContent="space-between">
                  <Box alignItems="center">
                    <IconButton
                      color="blue"
                      fontSize="1.5rem"
                      icon={<Icon as={IoArrowDownCircleOutline} />}
                      onClick={() => onImportDose(dose, route)}
                      aria-label="remove favorite"
                      variant="label"
                      disabled={isRemovingFromFavorites}
                      mr="0.5rem"
                    />
                    <chakra.span fontWeight="500">
                      {dose?.doseAmount || 0} {dose?.doseUnit}
                      {getConcentration(
                        dose?.orderFormEelementConcentrationId
                      )}{" "}
                      • {route}
                    </chakra.span>
                  </Box>

                  <IconButton
                    color="blue"
                    fontSize="1.5rem"
                    icon={<Icon as={IoHeartDislikeOutline} />}
                    onClick={() => onRemoveFavorite(id)}
                    aria-label="remove favorite"
                    variant="label"
                    disabled={isRemovingFromFavorites}
                  />
                </HStack>
              </VStack>
            ))}
          </Stack>
        ) : (
          <Text flex={1} textAlign="center">
            None
          </Text>
        )}
      </Card>

      <Label {...fieldLabelStyle}>Custom Dosage</Label>
      <Flex columnGap="10px">
        <Input {...register("doseAmount")} type="number" step=".001" />
        <Select
          items={UNITS_ARRAY}
          labelAccessor="label"
          valueAccessor="value"
          selectedItem={UNITS_ARRAY.find((u) => u.value === doseUnit)}
          showBlank={false}
          onSelectedItemChange={(changes) =>
            setValue("doseUnit", changes.selectedItem?.value || "")
          }
        />
      </Flex>

      <Label {...fieldLabelStyle}>Custom Concentration</Label>
      <Flex columnGap="10px">
        <Input
          {...register("concentration.doseAmount")}
          type="number"
          step=".001"
        />
        <Select
          items={CONCENTRATION_DOSE_UNITS}
          labelAccessor="label"
          valueAccessor="value"
          selectedItem={CONCENTRATION_DOSE_UNITS.find(
            (x) => x.value === concentrationDoseUnit
          )}
          onSelectedItemChange={(changes) =>
            setValue(
              "concentration.doseUnit",
              changes.selectedItem?.value ?? ""
            )
          }
        />
        <Input
          {...register("concentration.volumeAmount")}
          type="number"
          step=".001"
        />
        <Select
          items={VOLUME_UNITS}
          labelAccessor="label"
          valueAccessor="value"
          showBlank={false}
          selectedItem={VOLUME_UNITS.find((x) => x.value === volumeUnit)}
          onSelectedItemChange={(changes) =>
            setValue(
              "concentration.volumeUnit",
              changes.selectedItem?.value ?? ""
            )
          }
        />
      </Flex>

      {watch("concentration.doseAmount") &&
        Boolean(concentrationDoseUnit) &&
        watch("concentration.volumeAmount") &&
        Boolean(volumeUnit) &&
        watch("doseAmount") &&
        Boolean(doseUnit) &&
        validCalculation && (
          <>
            <Label {...fieldLabelStyle}>Weight(kg)</Label>
            <Flex columnGap="10px">
              <Input
                type="number"
                step=".001"
                flex={1}
                {...register("weight")}
              />
              <Checkbox
                isDisabled={!Boolean(watch("weight"))}
                onChange={handleCalculate}
                flex={1}
              >
                Calculate from weight
              </Checkbox>
            </Flex>
          </>
        )}
      {watch("amount") && (
        <>
          <Label {...fieldLabelStyle}>Amount</Label>
          <Flex>
            <InputGroup flex={1}>
              <Input readOnly value={watch("amount") || 0} />
              <InputRightElement>mL</InputRightElement>
            </InputGroup>
            <Button
              flex={1}
              variant="label"
              color="blue"
              onClick={showCalculation}
            >
              Show Calculation
            </Button>
          </Flex>
        </>
      )}
      <Label {...fieldLabelStyle}>Route *</Label>
      <Select
        items={getOptions("orderFormElementRoute")}
        labelAccessor="label"
        valueAccessor="value"
        selectedItem={getOptions("orderFormElementRoute").find(
          (x) => x.value === getValues("route")
        )}
        showBlank
        onSelectedItemChange={(changes) =>
          setValue("route", changes.selectedItem?.value || "")
        }
      />

      <Label {...fieldLabelStyle}>Notes</Label>
      <Input {...register("notes")} type="text" />
    </Box>
  );
}

export { MyFavoriteDoses };
