import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartRowElementImportedProps,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SCLI";
const chartCode = "C030x";

function T30ClinicalImpression() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({
          description: formMessages.updateSuccess("Clinical Impression"),
        });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      getValues,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "clinical-impression",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Clinical Impression") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  const chartFavoriteEditMode: Record<
    "imported",
    ChartRowElementImportedProps
  > | null = getValues("chartFavoriteEditMode")
    ? {
        imported: {
          type: "nursing",
        },
      }
    : null;

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Initial visit unless marked:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                />
                <ChartRowElement
                  label="subsequent"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="sequelae"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Pulmonary"
            >
              Pulmonary
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Asthma:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A092`}
                />
                <ChartRowElement
                  label="Reactive airway dz:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A093`}
                />
                <ChartRowElement
                  label="acute exacerbation"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="status asthmaticus"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hx of:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A094`}
                />
                <ChartRowElement
                  label="mild"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="mod"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="severe dz"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="intermittent"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="persistent"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Bronchospasm, acute"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Bronchitis:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A095`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="RSV"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="simple"
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="mucopurulent"
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cold (Nasopharyngitis)"
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="COPD:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A096`}
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A097`}
                />
                <ChartRowElement
                  label="exacerbation"
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="acute bronchitis"
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Cough"
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Sinus"
                  name={`Q002.${chartCode}${sectionId}Q002A022`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Pain"
                  name={`Q002.${chartCode}${sectionId}Q002A023`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="w/ respirations"
                  name={`Q002.${chartCode}${sectionId}Q002A024`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A025`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="COVID-19:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A098`}
                />
                <ChartRowElement
                  label="disease"
                  name={`Q002.${chartCode}${sectionId}Q002A026`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="exposure"
                  name={`Q002.${chartCode}${sectionId}Q002A027`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A028`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Epiglottis:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A099`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A029`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="w/ obstruction"
                  name={`Q002.${chartCode}${sectionId}Q002A030`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A031`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Herpes gingivostomatitis:"
                  name={`Q002.${chartCode}${sectionId}Q002A032`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A033`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Herpes pharyngotonsillitis:"
                  name={`Q002.${chartCode}${sectionId}Q002A034`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A035`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Influenza:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A100`}
                />
                <ChartRowElement
                  label="A"
                  name={`Q002.${chartCode}${sectionId}Q002A036`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="B"
                  name={`Q002.${chartCode}${sectionId}Q002A037`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="A & B"
                  name={`Q002.${chartCode}${sectionId}Q002A038`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A039`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Laryngitis:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A101`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A040`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Otitis media:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A102`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A041`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A042`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="acute:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A103`}
                />
                <ChartRowElement
                  label="recurrent:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A104`}
                />
                <ChartRowElement
                  label="serous"
                  name={`Q002.${chartCode}${sectionId}Q002A043`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="suppurative"
                  name={`Q002.${chartCode}${sectionId}Q002A044`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="w/ TM perf:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A105`}
                />
                <ChartRowElement
                  label="central"
                  name={`Q002.${chartCode}${sectionId}Q002A045`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="marginal"
                  name={`Q002.${chartCode}${sectionId}Q002A046`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A047`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pharyngitis:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A106`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A048`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Strep"
                  name={`Q002.${chartCode}${sectionId}Q002A049`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A050`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pneumonia:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A107`}
                />
                <ChartRowElement
                  label="interstitial"
                  name={`Q002.${chartCode}${sectionId}Q002A051`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="atypical"
                  name={`Q002.${chartCode}${sectionId}Q002A052`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="bronchopneumonia"
                  name={`Q002.${chartCode}${sectionId}Q002A053`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="lobar"
                  name={`Q002.${chartCode}${sectionId}Q002A054`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="aspiration"
                  name={`Q002.${chartCode}${sectionId}Q002A055`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="viral:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A108`}
                />
                <ChartRowElement
                  label="RSV"
                  name={`Q002.${chartCode}${sectionId}Q002A056`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Influenza:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A109`}
                />
                <ChartRowElement
                  label="A"
                  name={`Q002.${chartCode}${sectionId}Q002A057`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="B"
                  name={`Q002.${chartCode}${sectionId}Q002A058`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="A & B"
                  name={`Q002.${chartCode}${sectionId}Q002A059`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="A & B"
                  name={`Q002.${chartCode}${sectionId}Q002A060`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="COVID-19"
                  name={`Q002.${chartCode}${sectionId}Q002A061`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A062`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pneumothorax:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A110`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A063`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A064`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="tension"
                  name={`Q002.${chartCode}${sectionId}Q002A065`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A066`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pulm edema:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A111`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A067`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="w/ CHF"
                  name={`Q002.${chartCode}${sectionId}Q002A068`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A069`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pulm embolism:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A112`}
                />
                <ChartRowElement
                  label="w/ cor pulmonale"
                  name={`Q002.${chartCode}${sectionId}Q002A070`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A071`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Rhinitis, allergic:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A113`}
                />
                <ChartRowElement
                  label="seasonal"
                  name={`Q002.${chartCode}${sectionId}Q002A072`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="perennial"
                  name={`Q002.${chartCode}${sectionId}Q002A073`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A074`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Sinusitis:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A114`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A075`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="recurrent"
                  name={`Q002.${chartCode}${sectionId}Q002A076`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="maxillary"
                  name={`Q002.${chartCode}${sectionId}Q002A077`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="frontal"
                  name={`Q002.${chartCode}${sectionId}Q002A078`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="ethmoid"
                  name={`Q002.${chartCode}${sectionId}Q002A079`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A080`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Tonsillitis:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A115`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A081`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="recurrent"
                  name={`Q002.${chartCode}${sectionId}Q002A082`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Strep"
                  name={`Q002.${chartCode}${sectionId}Q002A083`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="GC"
                  name={`Q002.${chartCode}${sectionId}Q002A084`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Herpes"
                  name={`Q002.${chartCode}${sectionId}Q002A085`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="mono:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A116`}
                />
                <ChartRowElement
                  label="EBV"
                  name={`Q002.${chartCode}${sectionId}Q002A086`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="CMV"
                  name={`Q002.${chartCode}${sectionId}Q002A087`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A088`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="URI, acute"
                  name={`Q002.${chartCode}${sectionId}Q002A089`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A090`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A091`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Other"
            >
              Other
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Endocarditis, infective:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A036`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="subacute"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Meningitis:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A037`}
                />
                <ChartRowElement
                  label="viral"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="bacterial"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="H influ"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Pneumo"
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Strep"
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Staph"
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Mononucleosis:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A038`}
                />
                <ChartRowElement
                  label="EBV"
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="CMV"
                  name={`Q003.${chartCode}${sectionId}Q003A012`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A039`}
                />
                <ChartRowElement
                  label="splenomegaly"
                  name={`Q003.${chartCode}${sectionId}Q003A013`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hepatomegaly"
                  name={`Q003.${chartCode}${sectionId}Q003A014`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A015`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pyelonephritis:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A040`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q003.${chartCode}${sectionId}Q003A016`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A017`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="SIRS"
                  name={`Q003.${chartCode}${sectionId}Q003A018`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Sepsis, severe"
                  name={`Q003.${chartCode}${sectionId}Q003A019`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A041`}
                />
                <ChartRowElement
                  label="hypotension"
                  name={`Q003.${chartCode}${sectionId}Q003A020`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="shock"
                  name={`Q003.${chartCode}${sectionId}Q003A021`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="DIC"
                  name={`Q003.${chartCode}${sectionId}Q003A022`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="alt. mental"
                  name={`Q003.${chartCode}${sectionId}Q003A023`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hypoxia"
                  name={`Q003.${chartCode}${sectionId}Q003A024`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="resp. distress"
                  name={`Q003.${chartCode}${sectionId}Q003A025`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A026`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Tick borne dz:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A042`}
                />
                <ChartRowElement
                  label="Rocky Mtn spotted fever"
                  name={`Q003.${chartCode}${sectionId}Q003A027`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Lyme disease"
                  name={`Q003.${chartCode}${sectionId}Q003A028`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Tick paralysis"
                  name={`Q003.${chartCode}${sectionId}Q003A029`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A030`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="UTI:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A043`}
                />
                <ChartRowElement
                  label="cystitis:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A044`}
                />
                <ChartRowElement
                  label="acute:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A045`}
                />
                <ChartRowElement
                  label="w/ hematuria"
                  name={`Q003.${chartCode}${sectionId}Q003A031`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A032`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Viral syndrome"
                  name={`Q003.${chartCode}${sectionId}Q003A033`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A034`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A035`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Signs / Symptoms"
            >
              Signs / Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Cough"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Dyspnea"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Dehydration"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Fever"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Headache:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A028`}
                />
                <ChartRowElement
                  label="acute tension 2º"
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypokalemia"
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypoxemia"
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Leukocytosis"
                  name={`Q004.${chartCode}${sectionId}Q004A016`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A017`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Neutropenia"
                  name={`Q004.${chartCode}${sectionId}Q004A018`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A019`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Shortness of breath"
                  name={`Q004.${chartCode}${sectionId}Q004A020`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A021`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Skin rash:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A029`}
                />
                <ChartRowElement
                  label="pupura"
                  name={`Q004.${chartCode}${sectionId}Q004A022`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="petechiae"
                  name={`Q004.${chartCode}${sectionId}Q004A023`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A024`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Wheezing"
                  name={`Q004.${chartCode}${sectionId}Q004A025`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A026`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A027`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T30ClinicalImpression };
