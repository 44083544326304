import { Icon } from "@chakra-ui/react";
import { EmrPerson } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import {
  ChartRow,
  ChartRowElement,
  ChartRowElementImportedProps,
  ChartRowGroup,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import { PHYSICAL_EXAM_GRID_COLUMNS } from "modules/provider/shared";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";

const sectionId = "SPEX";
const chartCode = "C074x";

function T74PhysicalExam() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "Physical Exam",
    chartCode,
  });

  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:pex").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  const { getValues } = useFormContext;

  const chartFavoriteEditMode: Record<
    "imported",
    ChartRowElementImportedProps
  > | null = getValues("chartFavoriteEditMode")
    ? {
        imported: {
          type: "nursing",
        },
      }
    : null;

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPerson} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          Physical Exam {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowGroup>
                  <ChartRowElement
                    type="checkbox"
                    name={`Q001.${chartCode}${sectionId}Q001A001`}
                  />
                  <ChartRowElement
                    label="Nursing Assessment reviewed"
                    pr="0px"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q001.${chartCode}${sectionId}Q001A003`}
                    color="black"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    pl="0px"
                    marginInline="0px"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="checkbox"
                    name={`Q001.${chartCode}${sectionId}Q001A002`}
                  />
                  <ChartRowElement
                    label="Vitals Reviewed"
                    pr="0px"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q001.${chartCode}${sectionId}Q001A004`}
                    color="black"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    pl="0px"
                    marginInline="0px"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="General Appearance"
            >
              General Appearance
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ambulatory"
                    name={`Q002.${chartCode}${sectionId}Q002A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="c-collar"
                    name={`Q002.${chartCode}${sectionId}Q002A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="backboard"
                    name={`Q002.${chartCode}${sectionId}Q002A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="PTA"
                    name={`Q002.${chartCode}${sectionId}Q002A005`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="in ED"
                    name={`Q002.${chartCode}${sectionId}Q002A006`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A007`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ambulatory"
                    name={`Q002.${chartCode}${sectionId}Q002A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no acute distress"
                    name={`Q002.${chartCode}${sectionId}Q002A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="mild distress"
                    name={`Q002.${chartCode}${sectionId}Q002A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="moderate distress"
                    name={`Q002.${chartCode}${sectionId}Q002A011`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="severe distress"
                    name={`Q002.${chartCode}${sectionId}Q002A012`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A013`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="appears normal"
                    name={`Q002.${chartCode}${sectionId}Q002A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ill"
                    name={`Q002.${chartCode}${sectionId}Q002A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="uncomfortable"
                    name={`Q002.${chartCode}${sectionId}Q002A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="crying"
                    name={`Q002.${chartCode}${sectionId}Q002A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="inconsolable"
                    name={`Q002.${chartCode}${sectionId}Q002A018`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A019`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="alert"
                    name={`Q002.${chartCode}${sectionId}Q002A020`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="anxious"
                    name={`Q002.${chartCode}${sectionId}Q002A021`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="lethargic"
                    name={`Q002.${chartCode}${sectionId}Q002A022`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="unconscious"
                    name={`Q002.${chartCode}${sectionId}Q002A023`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="unresponsive"
                    name={`Q002.${chartCode}${sectionId}Q002A024`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A025`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q002.${chartCode}${sectionId}Q002A026`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A027`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Head / Neck"
            >
              Head / Neck
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="head & neck nml inspection"
                    name={`Q003.${chartCode}${sectionId}Q003A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pain on percussion over sinuses"
                    name={`Q003.${chartCode}${sectionId}Q003A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A004`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="facial erythema"
                    name={`Q003.${chartCode}${sectionId}Q003A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A006`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="trachea midline"
                    name={`Q003.${chartCode}${sectionId}Q003A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="maxillary swelling"
                    name={`Q003.${chartCode}${sectionId}Q003A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A011`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="thyroid nml"
                    name={`Q003.${chartCode}${sectionId}Q003A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="mandibular swelling"
                    name={`Q003.${chartCode}${sectionId}Q003A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A016`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no lymphadenopathy"
                    name={`Q003.${chartCode}${sectionId}Q003A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cervical lymphadenopathy"
                    name={`Q003.${chartCode}${sectionId}Q003A018`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="anterior"
                    fontStyle="italic"
                    name={`Q003.${chartCode}${sectionId}Q003A019`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="posterior"
                    fontStyle="italic"
                    name={`Q003.${chartCode}${sectionId}Q003A020`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="upper"
                    fontStyle="italic"
                    name={`Q003.${chartCode}${sectionId}Q003A021`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="lower"
                    fontStyle="italic"
                    name={`Q003.${chartCode}${sectionId}Q003A022`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A023`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="neck mass"
                    name={`Q003.${chartCode}${sectionId}Q003A024`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q003.${chartCode}${sectionId}Q003A025`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A026`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="stiff neck"
                    name={`Q003.${chartCode}${sectionId}Q003A027`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="meningismus"
                    name={`Q003.${chartCode}${sectionId}Q003A028`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A029`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pain on palpation"
                    name={`Q003.${chartCode}${sectionId}Q003A030`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A031`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q003.${chartCode}${sectionId}Q003A032`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A033`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Eyes"
            >
              Eyes
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="eyes nml inspection"
                    name={`Q004.${chartCode}${sectionId}Q004A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="conjunctivae"
                    name={`Q004.${chartCode}${sectionId}Q004A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="pale"
                    fontStyle="italic"
                    name={`Q004.${chartCode}${sectionId}Q004A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="red"
                    fontStyle="italic"
                    name={`Q004.${chartCode}${sectionId}Q004A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="edematous"
                    fontStyle="italic"
                    name={`Q004.${chartCode}${sectionId}Q004A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A007`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="PERRL"
                    name={`Q004.${chartCode}${sectionId}Q004A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="EOM palsy"
                    name={`Q004.${chartCode}${sectionId}Q004A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q004.${chartCode}${sectionId}Q004A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q004.${chartCode}${sectionId}Q004A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A012`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q004.${chartCode}${sectionId}Q004A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A014`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Mouth / Throat"
            >
              Mouth / Throat
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="lips, gums nml"
                    name={`Q005.${chartCode}${sectionId}Q005A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A003`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no drooling"
                    name={`Q005.${chartCode}${sectionId}Q005A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="drooling"
                    name={`Q005.${chartCode}${sectionId}Q005A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="trismus"
                    name={`Q005.${chartCode}${sectionId}Q005A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="increased secretion of saliva"
                    name={`Q005.${chartCode}${sectionId}Q005A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A008`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pharynx nml"
                    name={`Q005.${chartCode}${sectionId}Q005A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pharyngeal erythema"
                    name={`Q005.${chartCode}${sectionId}Q005A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A011`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no airway problems"
                    name={`Q005.${chartCode}${sectionId}Q005A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tonsillar exudate"
                    name={`Q005.${chartCode}${sectionId}Q005A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q005.${chartCode}${sectionId}Q005A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A015`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no thrush"
                    name={`Q005.${chartCode}${sectionId}Q005A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="peritonsillar mass:"
                    name={`Q005.${chartCode}${sectionId}Q005A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q005.${chartCode}${sectionId}Q005A018`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q005.${chartCode}${sectionId}Q005A019`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="uvular shift"
                    fontStyle="italic"
                    name={`Q005.${chartCode}${sectionId}Q005A020`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="pointing"
                    fontStyle="italic"
                    name={`Q005.${chartCode}${sectionId}Q005A021`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="abscess"
                    fontStyle="italic"
                    name={`Q005.${chartCode}${sectionId}Q005A022`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A023`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="voice nml"
                    name={`Q005.${chartCode}${sectionId}Q005A024`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="hoarse"
                    name={`Q005.${chartCode}${sectionId}Q005A025`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="muffled voice"
                    name={`Q005.${chartCode}${sectionId}Q005A026`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A027`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="membranes nml"
                    name={`Q005.${chartCode}${sectionId}Q005A028`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="dry membranes"
                    name={`Q005.${chartCode}${sectionId}Q005A029`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="sockets"
                    name={`Q005.${chartCode}${sectionId}Q005A030`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A031`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="dental tenderness:"
                    name={`Q005.${chartCode}${sectionId}Q005A032`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="gum swelling around tooth"
                    name={`Q005.${chartCode}${sectionId}Q005A033`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q005.${chartCode}${sectionId}Q005A034`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q005.${chartCode}${sectionId}Q005A035`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="widespread dental decay"
                    name={`Q005.${chartCode}${sectionId}Q005A036`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A037`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q005.${chartCode}${sectionId}Q005A038`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A039`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Ears / Nose"
            >
              Ears / Nose
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml inspection"
                    name={`Q006.${chartCode}${sectionId}Q006A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="TM erythema"
                    name={`Q006.${chartCode}${sectionId}Q006A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q006.${chartCode}${sectionId}Q006A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q006.${chartCode}${sectionId}Q006A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A006`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="loss of TM landmarks"
                    name={`Q006.${chartCode}${sectionId}Q006A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q006.${chartCode}${sectionId}Q006A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q006.${chartCode}${sectionId}Q006A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A010`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="dry mucous membranes"
                    name={`Q006.${chartCode}${sectionId}Q006A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A012`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q006.${chartCode}${sectionId}Q006A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A014`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Respiratory"
            >
              Respiratory
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no resp. distress"
                    name={`Q007.${chartCode}${sectionId}Q007A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="respiratory distress"
                    name={`Q007.${chartCode}${sectionId}Q007A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A004`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="breath sounds nml"
                    name={`Q007.${chartCode}${sectionId}Q007A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="stridor"
                    name={`Q007.${chartCode}${sectionId}Q007A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A009`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="wheezes"
                    name={`Q007.${chartCode}${sectionId}Q007A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="rales"
                    name={`Q007.${chartCode}${sectionId}Q007A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="rhonchi"
                    name={`Q007.${chartCode}${sectionId}Q007A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q007.${chartCode}${sectionId}Q007A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q007.${chartCode}${sectionId}Q007A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A015`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q007.${chartCode}${sectionId}Q007A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A017`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="CVS"
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="heart sounds nml"
                    name={`Q008.${chartCode}${sectionId}Q008A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="heart murmur"
                    name={`Q008.${chartCode}${sectionId}Q008A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A004`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tachycardia"
                    name={`Q008.${chartCode}${sectionId}Q008A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="bradycardia"
                    name={`Q008.${chartCode}${sectionId}Q008A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A007`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q008.${chartCode}${sectionId}Q008A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A009`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Abdomen / GU"
            >
              Abdomen / GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q009.${chartCode}${sectionId}Q009A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness:"
                    name={`Q009.${chartCode}${sectionId}Q009A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="generalized"
                    name={`Q009.${chartCode}${sectionId}Q009A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="LUQ"
                    name={`Q009.${chartCode}${sectionId}Q009A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="RUQ"
                    name={`Q009.${chartCode}${sectionId}Q009A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="LLQ"
                    name={`Q009.${chartCode}${sectionId}Q009A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="RLQ"
                    name={`Q009.${chartCode}${sectionId}Q009A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A009`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no organomegaly"
                    name={`Q009.${chartCode}${sectionId}Q009A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="abnml bowel sounds"
                    name={`Q009.${chartCode}${sectionId}Q009A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A012`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="hepatomegaly"
                    name={`Q009.${chartCode}${sectionId}Q009A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="splenomegaly"
                    name={`Q009.${chartCode}${sectionId}Q009A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="mass"
                    name={`Q009.${chartCode}${sectionId}Q009A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A016`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="catheter present"
                    name={`Q009.${chartCode}${sectionId}Q009A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A018`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q009.${chartCode}${sectionId}Q009A019`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A020`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              subsectionLabel="Extremities"
            >
              Extremities
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q010.${chartCode}${sectionId}Q010A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q010.${chartCode}${sectionId}Q010A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q010.${chartCode}${sectionId}Q010A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q010.${chartCode}${sectionId}Q010A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q010.${chartCode}${sectionId}Q010A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A007`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q010.${chartCode}${sectionId}Q010A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A009`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              subsectionLabel="Skin"
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no rash"
                    name={`Q011.${chartCode}${sectionId}Q011A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="skin rash"
                    name={`Q011.${chartCode}${sectionId}Q011A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="embolic lesions"
                    name={`Q011.${chartCode}${sectionId}Q011A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A005`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="intact"
                    name={`Q011.${chartCode}${sectionId}Q011A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cyanosis"
                    name={`Q011.${chartCode}${sectionId}Q011A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A008`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pressure ucler"
                    name={`Q011.${chartCode}${sectionId}Q011A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="location:"
                    name={`Q011.${chartCode}${sectionId}Q011A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A011`}
                    type="input"
                    width="32.4rem"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="depth / stage:"
                    name={`Q011.${chartCode}${sectionId}Q011A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="1"
                    name={`Q011.${chartCode}${sectionId}Q011A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="2"
                    name={`Q011.${chartCode}${sectionId}Q011A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="3"
                    name={`Q011.${chartCode}${sectionId}Q011A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="4"
                    name={`Q011.${chartCode}${sectionId}Q011A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A017`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q011.${chartCode}${sectionId}Q011A018`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A019`}
                    type="input"
                    width="32.4rem"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q012"
              subsectionLabel="Neuro / Psych"
            >
              Neuro / Psych
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="oriented x4"
                    name={`Q012.${chartCode}${sectionId}Q012A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="disoriented to:"
                    name={`Q012.${chartCode}${sectionId}Q012A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="person"
                    name={`Q012.${chartCode}${sectionId}Q012A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="place"
                    name={`Q012.${chartCode}${sectionId}Q012A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="time"
                    name={`Q012.${chartCode}${sectionId}Q012A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="situation"
                    name={`Q012.${chartCode}${sectionId}Q012A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A008`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="neuro intact"
                    name={`Q012.${chartCode}${sectionId}Q012A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="motor loss"
                    name={`Q012.${chartCode}${sectionId}Q012A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    label="sensory loss"
                    name={`Q012.${chartCode}${sectionId}Q012A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A012`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="facial palsy"
                    name={`Q012.${chartCode}${sectionId}Q012A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A014`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="mood / affect nml"
                    name={`Q012.${chartCode}${sectionId}Q012A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="depressed mood / affect"
                    name={`Q012.${chartCode}${sectionId}Q012A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A017`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    name={`Q012.${chartCode}${sectionId}Q012A018`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A019`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q013"
              isRequired={false}
              subsectionLabel="Additional Findings"
            >
              Additional Findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q013A001`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={<ChartSubsectionContent></ChartSubsectionContent>}
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T74PhysicalExam };
