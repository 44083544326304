import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Icon,
  Text,
} from "@chakra-ui/react";
import { useToast } from "hooks";
import { Inbox } from "icons";
import {
  OrderHistoryItem,
  useOrderHistoryInfinite,
} from "modules/charts-shared/api";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { InfiniteList } from "shared";
import { extractApiErrorMessage } from "utils";
import { OrderFormCartItem } from "./OrderFormCartItem";

function OrderHistoryPreview() {
  const { encounterId = "" } = useParams();
  const toast = useToast();

  const { data, isLoading, fetchNextPage, hasNextPage, error } =
    useOrderHistoryInfinite({
      encounterId,
    });

  const fetchMore = useCallback(async () => {
    try {
      await fetchNextPage();
    } catch (err) {
      toast({ description: extractApiErrorMessage(err) });
    }
  }, [fetchNextPage, toast]);

  useEffect(() => {
    if (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [error, toast]);

  return (
    <Accordion allowToggle defaultIndex={0}>
      <AccordionItem>
        <AccordionButton>
          <Box display="flex" justifyContent="start" gap="24px" w="100%">
            <Icon as={Inbox} color="gray.700" w="24px" h="24px" />
            <Text fontSize="18px" fontWeight="700">
              Order History
            </Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px={0} py={4}>
          <InfiniteList
            fetchMore={fetchMore}
            hasMore={!!hasNextPage}
            isLoading={isLoading}
            renderRow={(item) => (
              <Box key={item.id} mb="12px">
                <OrderFormCartItem
                  key={item.id}
                  isFromHistory
                  item={Object.assign({}, item, {
                    date: new Date(item.timeOrdered),
                    orderElementCategory: item.elementCategory ?? null,
                    orderElementType: item.elementType,
                    description: item.description ?? "",
                    scheduledTime: item.scheduledTime ?? null,
                    isScheduled: item.isScheduled ?? false,
                    isObservation: item.isObservation ?? false,
                    isPRN: item.isPRN ?? false,
                    prnReason: item.prnReason ?? "",
                  })}
                  orderStatus={item.status}
                />
              </Box>
            )}
            rows={
              data?.pages.reduce<OrderHistoryItem[]>(
                (accum, curr) => [...accum, ...curr.data.content],
                []
              ) || []
            }
            isGrid
            spacing="10px"
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export { OrderHistoryPreview };
