import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartRowElementImportedProps,
  ChartRowGroup,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrHpi } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SHPI";
const chartCode = "C052x";
const SUBSTANCES_GRID_COLUMNS = "500px 1fr";
const SUBSTANCES_GRID_HEADER = "516px 1fr";
const SUBSTANCES_ROWS_PADDING = "0 16px";

function T52HPI() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:hpi").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("HPI") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
      getValues,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "hpi",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("HPI") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (!name) return;
      const fields = [
        `Q004.${chartCode}${sectionId}Q004A004`,
        `Q004.${chartCode}${sectionId}Q004A005`,
        `Q004.${chartCode}${sectionId}Q004A006`,
        `Q004.${chartCode}${sectionId}Q004A007`,
        `Q004.${chartCode}${sectionId}Q004A008`,
        `Q004.${chartCode}${sectionId}Q004A009`,
        `Q004.${chartCode}${sectionId}Q004A010`,
        `Q004.${chartCode}${sectionId}Q004A011`,
        `Q004.${chartCode}${sectionId}Q004A012`,
        `Q004.${chartCode}${sectionId}Q004A013`,
      ];

      if (fields.includes(name)) {
        const value = getValues(name) as unknown as string | undefined;
        if (value === "Positive" || value === "Negative") {
          fields.forEach((code) => {
            if (code !== name) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setValue(code, "");
            }
          });
        }
      }
    });

    return () => subscription?.unsubscribe();
  }, [watch, setValue, getValues]);

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  const chartFavoriteEditMode: Record<
    "imported",
    ChartRowElementImportedProps
  > | null = getValues("chartFavoriteEditMode")
    ? {
        imported: {
          type: "nursing",
        },
      }
    : null;

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrHpi} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          HPI {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="suicidal thoughts"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="depression"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="agitated"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hallucinating"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="suicide attempt"
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="self-injury"
                  name={`Q001.${chartCode}${sectionId}Q001A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="intentional overdose"
                  name={`Q001.${chartCode}${sectionId}Q001A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="accidental drug ingestion"
                  name={`Q001.${chartCode}${sectionId}Q001A008`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="homicidal"
                  name={`Q001.${chartCode}${sectionId}Q001A009`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A010`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A011`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Onset / Duration"
            >
              Onset / Duration
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="just PTA"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="today"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="yesterday"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  w="50px"
                  type="input"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="minutes ago"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hours ago"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="days ago"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="gone now"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="better"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="continues in ED"
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="gradual onset"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="sudden onset"
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="intermittent"
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="constant"
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Intent"
            >
              Intent
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="suicide"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="wants to escape"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="accidental"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="no answer"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="prior thoughts of suicide"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="no prior thoughts of suicide"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Severity"
            >
              Severity
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="mild"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="moderate"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="severe"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="(1/10):"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                />
                <ChartRowElement
                  label="1"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="2"
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="3"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="4"
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="5"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="6"
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="7"
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="8"
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="9"
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="10"
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Context"
            >
              Context
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="situational problems"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="related to:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q005.${chartCode}${sectionId}Q005A016`}
                />
                <ChartRowElement
                  label="spouse"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="parent"
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="son"
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="daughter"
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="significant other"
                  name={`Q005.${chartCode}${sectionId}Q005A007`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="work"
                  name={`Q005.${chartCode}${sectionId}Q005A008`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="lost job"
                  name={`Q005.${chartCode}${sectionId}Q005A009`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="school"
                  name={`Q005.${chartCode}${sectionId}Q005A010`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="legal problems"
                  name={`Q005.${chartCode}${sectionId}Q005A011`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="recent death"
                  name={`Q005.${chartCode}${sectionId}Q005A012`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="sexual orientation"
                  name={`Q005.${chartCode}${sectionId}Q005A013`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="recent divorce"
                  name={`Q005.${chartCode}${sectionId}Q005A014`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q005.${chartCode}${sectionId}Q005A017`}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A015`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Associated Symptoms"
            >
              Associated Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="depressed"
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="angry"
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="frustrated"
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="agitated"
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hostile"
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="paranoid"
                  name={`Q006.${chartCode}${sectionId}Q006A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A007`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="confused"
                  name={`Q006.${chartCode}${sectionId}Q006A008`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A009`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="hallucinating"
                  name={`Q006.${chartCode}${sectionId}Q006A010`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A011`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="suicidal thoughts"
                  name={`Q006.${chartCode}${sectionId}Q006A012`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="specific plan"
                  name={`Q006.${chartCode}${sectionId}Q006A013`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="gesture"
                  name={`Q006.${chartCode}${sectionId}Q006A014`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="attempt"
                  name={`Q006.${chartCode}${sectionId}Q006A015`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A016`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="mechanism:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q006.${chartCode}${sectionId}Q006A033`}
                />
                <ChartRowElement
                  label="overdose"
                  name={`Q006.${chartCode}${sectionId}Q006A017`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="incision"
                  name={`Q006.${chartCode}${sectionId}Q006A018`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="stab wound"
                  name={`Q006.${chartCode}${sectionId}Q006A019`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="other"
                  name={`Q006.${chartCode}${sectionId}Q006A020`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A021`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="wrist:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q006.${chartCode}${sectionId}Q006A034`}
                />
                <ChartRowElement
                  label="incised"
                  name={`Q006.${chartCode}${sectionId}Q006A022`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="abraded"
                  name={`Q006.${chartCode}${sectionId}Q006A023`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="L"
                  name={`Q006.${chartCode}${sectionId}Q006A024`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="R"
                  name={`Q006.${chartCode}${sectionId}Q006A025`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A026`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="ingestion (see list below):"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q006.${chartCode}${sectionId}Q006A035`}
                />
                <ChartRowElement
                  label="suicide attempt"
                  name={`Q006.${chartCode}${sectionId}Q006A027`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="wanted to ‘escape'"
                  name={`Q006.${chartCode}${sectionId}Q006A028`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="accidental"
                  name={`Q006.${chartCode}${sectionId}Q006A029`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="will not answer"
                  name={`Q006.${chartCode}${sectionId}Q006A030`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A031`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q006.${chartCode}${sectionId}Q006A036`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A032`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Substances Ingested (if applicable)"
            >
              Substances Ingested (if applicable)
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent p="0px" pb="16px">
              <ChartRow
                gridTemplateColumns={SUBSTANCES_GRID_HEADER}
                bg="gray.250"
                h="60px"
                p={SUBSTANCES_ROWS_PADDING}
              >
                <ChartRowGroup>
                  <ChartRowElement
                    label="Name"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q007.${chartCode}${sectionId}Q007A025`}
                    color="gray.650"
                    fontWeight="500"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="Strength"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q007.${chartCode}${sectionId}Q007A026`}
                    color="gray.650"
                    fontWeight="500"
                    w="176px"
                  />
                  <ChartRowElement
                    label="# taken"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q007.${chartCode}${sectionId}Q007A027`}
                    color="gray.650"
                    fontWeight="500"
                    w="176px"
                  />
                  <ChartRowElement
                    label="When taken"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q007.${chartCode}${sectionId}Q007A028`}
                    color="gray.650"
                    fontWeight="500"
                    w="176px"
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={SUBSTANCES_GRID_COLUMNS}
                p={SUBSTANCES_ROWS_PADDING}
              >
                <ChartRowGroup>
                  <ChartRowElement
                    label="acetaminophen"
                    name={`Q007.${chartCode}${sectionId}Q007A001`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A002`}
                    w="176px"
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A003`}
                    w="176px"
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A004`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={SUBSTANCES_GRID_COLUMNS}
                p={SUBSTANCES_ROWS_PADDING}
              >
                <ChartRowGroup>
                  <ChartRowElement
                    label="aspirin"
                    name={`Q007.${chartCode}${sectionId}Q007A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A006`}
                    w="176px"
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A007`}
                    w="176px"
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A008`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={SUBSTANCES_GRID_COLUMNS}
                p={SUBSTANCES_ROWS_PADDING}
              >
                <ChartRowGroup>
                  <ChartRowElement
                    label="ethanol"
                    name={`Q007.${chartCode}${sectionId}Q007A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A010`}
                    w="176px"
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A011`}
                    w="176px"
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A012`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={SUBSTANCES_GRID_COLUMNS}
                p={SUBSTANCES_ROWS_PADDING}
              >
                <ChartRowGroup>
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A013`}
                    w="476px"
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A014`}
                    w="176px"
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A015`}
                    w="176px"
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A016`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={SUBSTANCES_GRID_COLUMNS}
                p={SUBSTANCES_ROWS_PADDING}
              >
                <ChartRowGroup>
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A017`}
                    w="476px"
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A018`}
                    w="176px"
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A019`}
                    w="176px"
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A020`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>

              <ChartRow
                gridTemplateColumns={SUBSTANCES_GRID_COLUMNS}
                p={SUBSTANCES_ROWS_PADDING}
              >
                <ChartRowGroup>
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A021`}
                    w="476px"
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A022`}
                    w="176px"
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A023`}
                    w="176px"
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A024`}
                    type="input"
                    {...chartFavoriteEditMode}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Rescue Factor (if suicide attempt)"
            >
              Rescue Factor (if suicide attempt)
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="How did ingestion / other acts come to attention?"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q008.${chartCode}${sectionId}Q008A009`}
                  color="black"
                  fontWeight="600"
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A001`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Arrived by:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q008.${chartCode}${sectionId}Q008A010`}
                  color="black"
                  fontWeight="600"
                />
                <ChartRowElement
                  label="private car"
                  name={`Q008.${chartCode}${sectionId}Q008A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="police"
                  name={`Q008.${chartCode}${sectionId}Q008A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="ambulance(who called?)"
                  name={`Q008.${chartCode}${sectionId}Q008A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="patient"
                  name={`Q008.${chartCode}${sectionId}Q008A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="spouse"
                  name={`Q008.${chartCode}${sectionId}Q008A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A007`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q008.${chartCode}${sectionId}Q008A011`}
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A008`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Recently"
            >
              Recently
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="recently:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q009.${chartCode}${sectionId}Q009A006`}
                />
                <ChartRowElement
                  label="seen by doctor"
                  name={`Q009.${chartCode}${sectionId}Q009A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="treated by doctor"
                  name={`Q009.${chartCode}${sectionId}Q009A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hospitalized"
                  name={`Q009.${chartCode}${sectionId}Q009A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="PCP sent to ER"
                  name={`Q009.${chartCode}${sectionId}Q009A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T52HPI };
