import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrHpi } from "@medstonetech/slate-icons";
import {
  ChartRow,
  ChartRowElement,
  ChartRowElementImportedProps,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";
import { usePermissions } from "contexts";
import { ChartForm } from "modules/charts-shared-v2";

const sectionId = "SHPI";
const chartCode = "C003x";

function T03HPI() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "HPI",
    chartCode,
  });

  const { getValues } = useFormContext;

  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:hpi").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  const chartFavoriteEditMode: Record<
    "imported",
    ChartRowElementImportedProps
  > | null = getValues("chartFavoriteEditMode")
    ? {
        imported: {
          type: "nursing",
        },
      }
    : null;

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrHpi} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          HPI {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q001.C003xSHPIQ001A009"
                  label="Injury to:"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q001.C003xSHPIQ001A001"
                  label="head"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q001.C003xSHPIQ001A002"
                  label="face"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q001.C003xSHPIQ001A003"
                  label="mouth"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q001.C003xSHPIQ001A004"
                  label="lip"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q001.C003xSHPIQ001A005"
                  label="chin"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q001.C003xSHPIQ001A006"
                  label="nose"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q001.C003xSHPIQ001A007"
                  label="forehead"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q001.C003xSHPIQ001A008"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Onset / Duration"
            >
              Onset / Duration
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="just prior to arrival"
                  name="Q002.C003xSHPIQ002A001"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="today"
                  name="Q002.C003xSHPIQ002A002"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="yesterday"
                  name="Q002.C003xSHPIQ002A003"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q002.C003xSHPIQ002A004"
                  defaultValue=""
                  width="3.5rem"
                  textAlign="center"
                  inputType="number"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="min"
                  name="Q002.C003xSHPIQ002A005"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="hrs"
                  name="Q002.C003xSHPIQ002A006"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="days ago"
                  name="Q002.C003xSHPIQ002A007"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q002.C003xSHPIQ002A008"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Where"
            >
              Where
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="home"
                  name="Q003.C003xSHPIQ003A001"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="school"
                  name="Q003.C003xSHPIQ003A002"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="neighbor’s"
                  name="Q003.C003xSHPIQ003A003"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="park"
                  name="Q003.C003xSHPIQ003A004"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="work"
                  name="Q003.C003xSHPIQ003A005"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="street"
                  name="Q003.C003xSHPIQ003A006"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q003.C003xSHPIQ003A009"
                  label="exact location:"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q003.C003xSHPIQ003A007"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q003.C003xSHPIQ003A010"
                  label="exact activity:"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q003.C003xSHPIQ003A008"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Timing"
            >
              Timing
            </ChartSubsectionHeader>
          }
          content={
            <>
              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    label="still present"
                    name="Q004.C003xSHPIQ004A001"
                  />
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    label="better"
                    name="Q004.C003xSHPIQ004A002"
                  />
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    label="gone now"
                    name="Q004.C003xSHPIQ004A003"
                  />
                  <ChartRowElement type="empty" />
                </ChartRow>
              </ChartSubsectionContent>
              <ChartSubsectionContent>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name="Q004.C003xSHPIQ004A010"
                    label="pain:"
                  />
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    label="intermittent"
                    name="Q004.C003xSHPIQ004A004"
                  />
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    label="lasting"
                    name="Q004.C003xSHPIQ004A005"
                  />
                  <ChartRowElement
                    type="input"
                    {...chartFavoriteEditMode}
                    name="Q004.C003xSHPIQ004A006"
                  />
                </ChartRow>
                <ChartRow>
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    label="worse"
                    name="Q004.C003xSHPIQ004A007"
                  />
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    label="persistent"
                    name="Q004.C003xSHPIQ004A008"
                  />
                  <ChartRowElement
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name="Q004.C003xSHPIQ004A011"
                    label="since:"
                  />
                  <ChartRowElement
                    type="input"
                    {...chartFavoriteEditMode}
                    name="Q004.C003xSHPIQ004A009"
                  />
                </ChartRow>
              </ChartSubsectionContent>
            </>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Context"
            >
              Context
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="fall"
                  name="Q005.C003xSHPIQ005A001"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="direct blow"
                  name="Q005.C003xSHPIQ005A002"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="incision"
                  name="Q005.C003xSHPIQ005A003"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="stab"
                  name="Q005.C003xSHPIQ005A004"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="burn"
                  name="Q005.C003xSHPIQ005A005"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q005.C003xSHPIQ005A006"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="PCP sent to ER"
                  name="Q005.C003xSHPIQ005A007"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q005.C003xSHPIQ005A008"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Severity of pain"
            >
              Severity of pain
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="mild"
                  name="Q006.T003xSCHCQ006A001"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="moderate"
                  name="Q006.T003xSCHCQ006A002"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="severe"
                  name="Q006.T003xSCHCQ006A003"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q006.T003xSCHCQ006A004"
                  defaultValue=""
                  width="3.5rem"
                  textAlign="center"
                  inputType="number"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q006.T003xSCHCQ006A006"
                  label="/10"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q006.T003xSCHCQ006A005"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Associated symptoms"
            >
              Associated symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="seizure"
                  name="Q007.T003xSCHCQ007A001"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="memory impairment"
                  name="Q007.T003xSCHCQ007A002"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q007.T003xSCHCQ007A018"
                  label="lost consciousness:"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="yes"
                  name="Q007.T003xSCHCQ007A003"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="no"
                  name="Q007.T003xSCHCQ007A004"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="unknown"
                  name="Q007.T003xSCHCQ007A005"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q007.T003xSCHCQ007A019"
                  label="duration:"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q007.T003xSCHCQ007A006"
                  defaultValue=""
                  width="3.5rem"
                  textAlign="center"
                  inputType="number"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="sec"
                  name="Q007.T003xSCHCQ007A007"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="min"
                  name="Q007.T003xSCHCQ007A008"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="hrs"
                  name="Q007.T003xSCHCQ007A009"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q007.T003xSCHCQ007A020"
                  label="remembers:"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="event"
                  name="Q007.T003xSCHCQ007A010"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="coming to hospital"
                  name="Q007.T003xSCHCQ007A011"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q007.T003xSCHCQ007A012"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="neck pain"
                  name="Q007.T003xSCHCQ007A013"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="headache"
                  name="Q007.T003xSCHCQ007A014"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q007.T003xSCHCQ007A015"
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name="Q007.T003xSCHCQ007A021"
                  label="remembers:"
                />
                <ChartRowElement
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  label="other injuries"
                  name="Q007.T003xSCHCQ007A016"
                />
                <ChartRowElement
                  type="input"
                  {...chartFavoriteEditMode}
                  name="Q007.T003xSCHCQ007A017"
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T03HPI };
