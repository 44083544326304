import { ProviderChartCode } from "enums";
import {
  T01ClinicalImpression,
  T01HPI,
  T01PhysicalExam,
  T01Radiology,
  T01ROS,
  T03ClinicalImpression,
  T03HPI,
  T03PhysicalExam,
  T03Radiology,
  T03ROS,
  T06ClinicalImpression,
  T06HPI,
  T06PhysicalExam,
  T06Radiology,
  T06ROS,
  T14ClinicalImpression,
  T14HPI,
  T14PhysicalExam,
  T14Radiology,
  T14ROS,
  T16ClinicalImpression,
  T16HPI,
  T16PhysicalExam,
  T16Radiology,
  T16ROS,
  T17ClinicalImpression,
  T17HPI,
  T17PhysicalExam,
  T17Radiology,
  T17ROS,
  T21ClinicalImpression,
  T21HPI,
  T21PhysicalExam,
  T21Radiology,
  T21ROS,
  T22ClinicalImpression,
  T22HPI,
  T22PhysicalExam,
  T22Radiology,
  T22ROS,
  T24ClinicalImpression,
  T24HPI,
  T24PhysicalExam,
  T24Radiology,
  T24ROS,
  T30ClinicalImpression,
  T30HPI,
  T30PhysicalExam,
  T30Radiology,
  T30ROS,
  T31ClinicalImpression,
  T31HPI,
  T31PhysicalExam,
  T31Radiology,
  T31ROS,
  T35ClinicalImpression,
  T35HPI,
  T35PhysicalExam,
  T35Radiology,
  T35ROS,
  T36ClinicalImpression,
  T36HPI,
  T36PhysicalExam,
  T36Radiology,
  T36ROS,
  T39ClinicalImpression,
  T39HPI,
  T39PhysicalExam,
  T39Radiology,
  T39ROS,
  T40ClinicalImpression,
  T40HPI,
  T40PhysicalExam,
  T40Radiology,
  T40ROS,
  T41ClinicalImpression,
  T41HPI,
  T41PhysicalExam,
  T41Radiology,
  T41ROS,
  T43ClinicalImpression,
  T43HPI,
  T43PhysicalExam,
  T43Radiology,
  T43ROS,
  T44ClinicalImpression,
  T44HPI,
  T44PhysicalExam,
  T44Radiology,
  T44ROS,
  T46ClinicalImpression,
  T46HPI,
  T46PhysicalExam,
  T46Radiology,
  T46ROS,
  T52ClinicalImpression,
  T52HPI,
  T52PhysicalExam,
  T52Radiology,
  T52ROS,
  T70ClinicalImpression,
  T70HPI,
  T70PhysicalExam,
  T70Radiology,
  T70ROS,
  T73ClinicalImpression,
  T73HPI,
  T73PhysicalExam,
  T73Radiology,
  T73ROS,
  T74ClinicalImpression,
  T74HPI,
  T74PhysicalExam,
  T74Radiology,
  T74ROS,
} from "modules/provider/components";
import {
  EKG,
  LabsChart,
  MDM,
  ProviderMedicalHistory,
} from "modules/provider/shared";
import { Procedures } from "modules/provider/shared/procedure-charts";
import {
  AllergiesMedicationsRx,
  ArrivalInfo,
  IvAndMedications,
  Treatments,
} from "modules/triage/shared";
import { Vitals } from "modules/vitals";
import * as React from "react";
import { ChartType } from "types";
import { Disposition, OrderHistory, Progress, Supplies } from "../components";

const PROVIDER_CHART_TYPE: ChartType = "Chart";

const LABS_CHART_CODE_FOR_NOTES: ProviderChartCode = "C001x";

type ProviderChartSection =
  | "SHPI"
  | "SPEX"
  | "SROS"
  | "SRAD"
  | "SCLI"
  | "SAMRX"
  | "SARI"
  | "SDIS"
  | "SMHX"
  | "ORHI"
  | "SSUP"
  | "SIVM"
  | "STRE"
  | "SLAB"
  | "SPRO2"
  | "SEKG"
  | "SPGR"
  | "SMDM"
  | "SDIS"
  | "SVIT";

const getSharedCharts = (chartCode: ProviderChartCode) => ({
  SAMRX: () => (
    <AllergiesMedicationsRx
      chartCode={chartCode}
      chartType={PROVIDER_CHART_TYPE}
      isReadOnly
    />
  ),
  SARI: () => <ArrivalInfo chartCode={chartCode} isReadOnly />,
  SDIS: () => (
    <Disposition
      chartCode={chartCode}
      isReadOnly
      chartType={PROVIDER_CHART_TYPE}
    />
  ),
  SMHX: () => <ProviderMedicalHistory chartCode={chartCode} isReadOnly />,
  ORHI: () => (
    <OrderHistory
      chartCode={chartCode}
      isReadOnly
      chartType={PROVIDER_CHART_TYPE}
    />
  ),
  SSUP: () => (
    <Supplies
      chartCode={chartCode}
      isReadOnly
      chartType={PROVIDER_CHART_TYPE}
    />
  ),
  SIVM: () => <IvAndMedications chartCode={chartCode} isReadOnly />,
  STRE: () => <Treatments chartCode={chartCode} isReadOnly />,
  SLAB: () => <LabsChart chartCode={LABS_CHART_CODE_FOR_NOTES} isReadOnly />,
  SPRO2: () => <Procedures chartCode={chartCode} sectionCode="SPRO2" />,
  SEKG: () => <EKG chartCode={chartCode} isReadOnly />,
  SPGR: () => <Progress chartType="Chart" isReadOnly />,
  SMDM: () => <MDM chartCode={chartCode} isReadOnly />,
  SVIT: () => <Vitals isReadOnly />,
});

const PROVIDER_CHART_ORDER: Partial<Record<ProviderChartSection, number>> = {
  SCLI: 1,
  SDIS: 2,
  SHPI: 3,
  SROS: 4,
  SPEX: 5,
  SVIT: 6,
  ORHI: 7,
  SIVM: 8,
  STRE: 9,
  SLAB: 10,
  SRAD: 11,
  SEKG: 12,
  SPRO2: 13,
  SPGR: 14,
  SMDM: 15,
};

const sortProviderSections = (sections: string[]): string[] => {
  return [...sections].sort((a, b) => {
    const orderA = PROVIDER_CHART_ORDER[a as ProviderChartSection] || Infinity;
    const orderB = PROVIDER_CHART_ORDER[b as ProviderChartSection] || Infinity;
    return orderA - orderB;
  });
};

const ProviderCharts: Partial<
  Record<
    ProviderChartCode,
    Record<ProviderChartSection, () => React.ReactElement>
  >
> = {
  C001x: {
    SHPI: T01HPI,
    SPEX: T01PhysicalExam,
    SROS: T01ROS,
    SRAD: T01Radiology,
    SCLI: T01ClinicalImpression,
    ...getSharedCharts("C001x"),
  },
  C003x: {
    SHPI: T03HPI,
    SPEX: T03PhysicalExam,
    SROS: T03ROS,
    SRAD: T03Radiology,
    SCLI: T03ClinicalImpression,
    ...getSharedCharts("C003x"),
  },
  C006x: {
    SHPI: T06HPI,
    SPEX: T06PhysicalExam,
    SROS: T06ROS,
    SRAD: T06Radiology,
    SCLI: T06ClinicalImpression,
    ...getSharedCharts("C006x"),
  },
  C014x: {
    SHPI: T14HPI,
    SPEX: T14PhysicalExam,
    SROS: T14ROS,
    SRAD: T14Radiology,
    SCLI: T14ClinicalImpression,
    ...getSharedCharts("C014x"),
  },
  C016x: {
    SHPI: T16HPI,
    SPEX: T16PhysicalExam,
    SROS: T16ROS,
    SRAD: T16Radiology,
    SCLI: T16ClinicalImpression,
    ...getSharedCharts("C016x"),
  },
  C017x: {
    SHPI: T17HPI,
    SPEX: T17PhysicalExam,
    SROS: T17ROS,
    SRAD: T17Radiology,
    SCLI: T17ClinicalImpression,
    ...getSharedCharts("C017x"),
  },
  C021x: {
    SHPI: T21HPI,
    SPEX: T21PhysicalExam,
    SROS: T21ROS,
    SRAD: T21Radiology,
    SCLI: T21ClinicalImpression,
    ...getSharedCharts("C021x"),
  },
  C022x: {
    SHPI: T22HPI,
    SPEX: T22PhysicalExam,
    SROS: T22ROS,
    SRAD: T22Radiology,
    SCLI: T22ClinicalImpression,
    ...getSharedCharts("C022x"),
  },
  C024x: {
    SHPI: T24HPI,
    SPEX: T24PhysicalExam,
    SROS: T24ROS,
    SRAD: T24Radiology,
    SCLI: T24ClinicalImpression,
    ...getSharedCharts("C024x"),
  },
  C030x: {
    SHPI: T30HPI,
    SPEX: T30PhysicalExam,
    SROS: T30ROS,
    SRAD: T30Radiology,
    SCLI: T30ClinicalImpression,
    ...getSharedCharts("C030x"),
  },
  C031x: {
    SHPI: T31HPI,
    SPEX: T31PhysicalExam,
    SROS: T31ROS,
    SRAD: T31Radiology,
    SCLI: T31ClinicalImpression,
    ...getSharedCharts("C031x"),
  },
  C035x: {
    SHPI: T35HPI,
    SPEX: T35PhysicalExam,
    SROS: T35ROS,
    SRAD: T35Radiology,
    SCLI: T35ClinicalImpression,
    ...getSharedCharts("C035x"),
  },
  C036x: {
    SHPI: T36HPI,
    SPEX: T36PhysicalExam,
    SROS: T36ROS,
    SRAD: T36Radiology,
    SCLI: T36ClinicalImpression,
    ...getSharedCharts("C036x"),
  },
  C039x: {
    SHPI: T39HPI,
    SPEX: T39PhysicalExam,
    SROS: T39ROS,
    SRAD: T39Radiology,
    SCLI: T39ClinicalImpression,
    ...getSharedCharts("C039x"),
  },
  C041x: {
    SHPI: T41HPI,
    SPEX: T41PhysicalExam,
    SROS: T41ROS,
    SRAD: T41Radiology,
    SCLI: T41ClinicalImpression,
    ...getSharedCharts("C041x"),
  },
  C043x: {
    SHPI: T43HPI,
    SPEX: T43PhysicalExam,
    SROS: T43ROS,
    SRAD: T43Radiology,
    SCLI: T43ClinicalImpression,
    ...getSharedCharts("C043x"),
  },
  C044x: {
    SHPI: T44HPI,
    SPEX: T44PhysicalExam,
    SROS: T44ROS,
    SRAD: T44Radiology,
    SCLI: T44ClinicalImpression,
    ...getSharedCharts("C044x"),
  },
  C046x: {
    SHPI: T46HPI,
    SPEX: T46PhysicalExam,
    SROS: T46ROS,
    SRAD: T46Radiology,
    SCLI: T46ClinicalImpression,
    ...getSharedCharts("C046x"),
  },
  C052x: {
    SHPI: T52HPI,
    SPEX: T52PhysicalExam,
    SROS: T52ROS,
    SRAD: T52Radiology,
    SCLI: T52ClinicalImpression,
    ...getSharedCharts("C052x"),
  },
  C070a: {
    SHPI: T70HPI,
    SPEX: T70PhysicalExam,
    SROS: T70ROS,
    SRAD: T70Radiology,
    SCLI: T70ClinicalImpression,
    ...getSharedCharts("C070a"),
  },
  C073x: {
    SHPI: T73HPI,
    SPEX: T73PhysicalExam,
    SROS: T73ROS,
    SRAD: T73Radiology,
    SCLI: T73ClinicalImpression,
    ...getSharedCharts("C073x"),
  },
  C074x: {
    SHPI: T74HPI,
    SPEX: T74PhysicalExam,
    SROS: T74ROS,
    SRAD: T74Radiology,
    SCLI: T74ClinicalImpression,
    ...getSharedCharts("C074x"),
  },
  C040x: {
    SHPI: T40HPI,
    SPEX: T40PhysicalExam,
    SROS: T40ROS,
    SRAD: T40Radiology,
    SCLI: T40ClinicalImpression,
    ...getSharedCharts("C040x"),
  },
};

export { ProviderCharts, sortProviderSections };
export type { ProviderChartSection };
