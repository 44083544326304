import { useAuth0 } from "@auth0/auth0-react";
import {
  Badge,
  Box,
  chakra,
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { OrderFormElementRoute, OrderStatus } from "enums";
import {
  ChartRowElement,
  TriageIVMedicationSection,
} from "modules/charts-shared";
import {
  getOrderDetailsDateShortFormat,
  ORDER_STATUS_COLOR,
} from "modules/orders";
import { ReactNode, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  FormChartOption,
  FormRadioChartOptionGroup,
  Input,
  Select,
  Textarea,
  UserInfo,
} from "shared";
import { USERID_CLAIMS } from "system-constants";
import { enumMapper } from "utils";
import { getOptions } from "utils/enum-mapper";

type MedsAndIvModalProps = {
  isOpen: boolean;
  onClose(): void;
  type: string;
  item: TriageIVMedicationSection & { index: number };
  title: string;
  onSave: () => void;
};

type MedicationTypeContentProps = {
  item: TriageIVMedicationSection;
  type: string;
};

type ItemInfoProps = {
  label: string;
  value: ReactNode;
};

function ItemInfo({ label, value }: ItemInfoProps) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      py={3}
      px={4}
      alignItems="center"
    >
      <Text color="#9A9EA7">{label}</Text>
      <Text fontWeight="bold">{value}</Text>
    </Box>
  );
}

function ItemDetails({ item, type }: MedicationTypeContentProps) {
  const { register, setValue, watch } = useFormContext();

  const selectedRoute = watch(`${type}[${item.index}].route`);

  const onChangeRoute = (route?: OrderFormElementRoute) => {
    setValue(`${type}[${item.index}].route`, route);

    if (route !== "IV") {
      setValue(`${type}[${item.index}].stopTime`, null);
      setValue(`${type}[${item.index}].amountInfused`, null);
      setValue(`${type}[${item.index}].amountWasted`, null);
      setValue(`${type}[${item.index}].stoppedBy`, null);
    }
  };

  return (
    <Box>
      <Box
        height="310px"
        w="500px"
        border="1px solid #FFFFFF"
        borderRadius="10px"
        bgColor="white"
        overflow="hidden"
      >
        <ItemInfo
          label="Order Status"
          value={
            <Badge
              bgColor={
                ORDER_STATUS_COLOR[(item.status as OrderStatus) || "InProcess"]
              }
              fontWeight="700"
              fontSize="15px"
              color="white"
              borderRadius="20px"
              padding="0.5rem 1rem"
              textTransform="capitalize"
              width="100%"
              textAlign="center"
            >
              {enumMapper.toDisplay(
                "orderStatus",
                (item.status as OrderStatus) || "InProcess"
              )}
            </Badge>
          }
        />
        <Divider ml="16px" />
        <Divider ml="16px" />
        <ItemInfo label="Medication" value={item.medication as string} />
        <Divider ml="16px" />
        <ItemInfo label="Dose" value={item.solution as string} />
        <Divider ml="16px" />
        <ItemInfo
          label="Time Ordered"
          value={getOrderDetailsDateShortFormat(item.order?.timeOrdered)}
        />
        <Divider ml="16px" />
        <Flex p={4} alignItems="center">
          <Text color="#9A9EA7">Notes</Text>
          <Text>{item.order?.notes as string}</Text>
        </Flex>
      </Box>
      <Flex columnGap="20px" mt="10px">
        <Box flex={1}>
          <Text color="#9A9EA7" pl={4} pb={1}>
            Route
          </Text>
          <Select<{ value: OrderFormElementRoute; label: string }>
            items={getOptions("orderFormElementRoute")}
            labelAccessor="label"
            valueAccessor="value"
            showBlank
            selectedItem={selectedRoute}
            onSelectedItemChange={(changes) =>
              onChangeRoute(changes.selectedItem?.value)
            }
          />
        </Box>
        <Box>
          <Text color="#9A9EA7" pl={4} pb={1}>
            Site
          </Text>
          <ChartRowElement
            type="input"
            name={`${type}[${item.index}].site`}
            w="140px"
          />
        </Box>
      </Flex>
      <Box py={4}>
        <Text color="#9A9EA7" pl={4} pb={1}>
          Comments
        </Text>
        <Textarea
          backgroundColor="white"
          borderRadius="10px"
          resize="none"
          height="70px"
          minH="unset"
          border="0"
          {...register(`${type}[${item.index}].comments`)}
        />
      </Box>
    </Box>
  );
}

type StepProps = {
  label: string;
  isActive: boolean;
  color: string;
};

function Step({ label, isActive }: StepProps) {
  return (
    <Text
      bgColor={isActive ? "#9A9EA7" : "#D1D1D6"}
      color="white"
      py={1}
      px={2}
      borderRadius="5px"
      fontSize="17px"
      textAlign="center"
      w="100%"
    >
      {label}
    </Text>
  );
}

function MedicationTypeContent({ item, type }: MedicationTypeContentProps) {
  const { control, watch } = useFormContext();
  const status = watch(`medications[${item.index}].status`);

  return (
    <Box display="flex" justifyContent="space-between">
      <ItemDetails item={item} type={type} />
      <Box w="460px">
        <Step
          label="Step 1 - Prepare Medication"
          isActive={status === "Ordered" || status === "Administered"}
          color="cyan"
        />
        <Box py={4} display="flex">
          <Box>
            <Text color="#808080" ml={4}>
              Preparation Time*
            </Text>
            <ChartRowElement
              type="date"
              name={`medications[${item.index}].preparationTime`}
              w="240px"
              isDisabled={status !== "Ordered" && status !== "Administered"}
            />
          </Box>
          <Box ml={4}>
            <Text color="#808080">Prepared by</Text>
            <UserInfo userId={watch(`medications[${item.index}].preparedBy`)} />
          </Box>
        </Box>
        <Step
          label="Step 2 - Start Medication"
          isActive={status === "Prepared" || status === "Administered"}
          color="green"
        />
        <Box py={4} display="flex">
          <Box>
            <Text color="#808080" ml={4}>
              Start Time*
            </Text>
            <ChartRowElement
              type="date"
              name={`medications[${item.index}].startTime`}
              w="240px"
              isDisabled={status !== "Prepared" && status !== "Administered"}
            />
          </Box>
          <Box ml={4}>
            <Text color="#808080">Started by</Text>
            <UserInfo userId={watch(`medications[${item.index}].startedBy`)} />
          </Box>
        </Box>
        <Step
          label="Step 3 - Assess/Administer Medication"
          isActive={status === "Completed" || status === "Administered"}
          color="purple"
        />
        <Box py={4} display="flex" flexDir="column">
          <Box display="flex">
            <Box>
              <Text color="#808080" ml={4}>
                Assessment Time*
              </Text>
              <ChartRowElement
                type="date"
                name={`medications[${item.index}].assessmentTime`}
                w="240px"
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
            </Box>
            <Box ml={4}>
              <Text color="#808080">Assessed by</Text>
              <UserInfo
                userId={watch(`medications[${item.index}].assessedBy`)}
              />
            </Box>
          </Box>
          <Box pt={3}>
            <Text color="#808080" ml={4} pb={3}>
              Change*
            </Text>
            <HStack justifyContent="center">
              <FormChartOption
                label="no change"
                control={control}
                name={`medications[${item.index}].noChange`}
                bgColor="white"
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
              <FormChartOption
                label="improved"
                control={control}
                name={`medications[${item.index}].improved`}
                bgColor="white"
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
              <FormChartOption
                label="NAR"
                control={control}
                name={`medications[${item.index}].nar`}
                bgColor="white"
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
              <FormChartOption
                label="continued"
                control={control}
                name={`medications[${item.index}].continued`}
                bgColor="white"
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
            </HStack>
          </Box>
          <Box pt={3}>
            <Text color="#808080" ml={4} pb={3}>
              Pain (1/10)*
            </Text>
            <HStack justifyContent="center">
              <FormRadioChartOptionGroup
                options={[...Array(10).keys()].map((val) => ({
                  label: `${val + 1}`,
                  value: `${val + 1}`,
                }))}
                control={control}
                name={`medications[${item.index}].pain`}
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
            </HStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function IvStartsTypeContent({ item, type }: MedicationTypeContentProps) {
  const { control, watch } = useFormContext();
  const status = watch(`ivStarts[${item.index}].status`);

  return (
    <Box display="flex" justifyContent="space-between">
      <ItemDetails item={item} type={type} />
      <Box w="460px">
        <Step
          label="Step 1 - Start Medication"
          isActive={status === "Ordered" || status === "Administered"}
          color="green"
        />
        <Box py={4} display="flex">
          <Box>
            <Text color="#808080" ml={4}>
              Start Time*
            </Text>
            <ChartRowElement
              type="date"
              name={`ivStarts[${item.index}].startTime`}
              w="240px"
              isDisabled={status !== "Ordered" && status !== "Administered"}
            />
          </Box>
          <Box ml={4}>
            <Text color="#808080">Started by</Text>
            <UserInfo userId={watch(`ivStarts[${item.index}].startedBy`)} />
          </Box>
        </Box>
        <Step
          label="Step 2 - Assess/Administer Medication"
          isActive={status === "Completed" || status === "Administered"}
          color="purple"
        />
        <Box py={4} display="flex" flexDir="column">
          <Box display="flex">
            <Box>
              <Text color="#808080" ml={4}>
                Assessment Time*
              </Text>
              <ChartRowElement
                type="date"
                name={`ivStarts[${item.index}].assessmentTime`}
                w="240px"
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
            </Box>
            <Box ml={4}>
              <Text color="#808080">Assessed by</Text>
              <UserInfo userId={watch(`ivStarts[${item.index}].assessedBy`)} />
            </Box>
          </Box>
          <Box pt={3}>
            <Text color="#808080" ml={4} pb={2}>
              Change*
            </Text>
            <HStack justifyContent="center">
              <FormChartOption
                label="no change"
                control={control}
                name={`ivStarts[${item.index}].noChange`}
                bgColor="white"
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
              <FormChartOption
                label="improved"
                control={control}
                name={`ivStarts[${item.index}].improved`}
                bgColor="white"
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
              <FormChartOption
                label="NAR"
                control={control}
                name={`ivStarts[${item.index}].nar`}
                bgColor="white"
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
              <FormChartOption
                label="continued"
                control={control}
                name={`ivStarts[${item.index}].continued`}
                bgColor="white"
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
            </HStack>
          </Box>
          <Box pt={3}>
            <Text color="#808080" ml={4} pb={3}>
              Pain (1/10)*
            </Text>
            <HStack justifyContent="center">
              <FormRadioChartOptionGroup
                options={[...Array(10).keys()].map((val) => ({
                  label: `${val + 1}`,
                  value: `${val + 1}`,
                }))}
                control={control}
                name={`ivStarts[${item.index}].pain`}
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
            </HStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function IvMedicationsTypeContent({ item, type }: MedicationTypeContentProps) {
  const { control, watch, register } = useFormContext();
  const status = watch(`${type}[${item.index}].status`);

  const route = watch(`${type}[${item.index}].route`);
  const isIV = route === "IV";

  return (
    <Box display="flex" justifyContent="start" gap="40px">
      <ItemDetails item={item} type={type} />
      <Box w="460px">
        <Step
          label="Prepare Medication"
          isActive={status === "Administered" || status === "Ordered"}
          color="cyan"
        />
        <Box py={4} display="flex">
          <Box>
            <Text color="#808080" ml={4}>
              Preparation Time*
            </Text>
            <ChartRowElement
              type="date"
              name={`${type}[${item.index}].preparationTime`}
              isDisabled={status !== "Administered" && status !== "Ordered"}
              w="240px"
            />
          </Box>
          <Box ml={4}>
            <Text color="#808080">Prepared by</Text>
            <UserInfo userId={watch(`${type}[${item.index}].preparedBy`)} />
          </Box>
        </Box>
        <Step
          label="Start Medication"
          isActive={status === "Administered" || status === "Prepared"}
          color="blue"
        />
        <Box py={4} display="flex">
          <Box>
            <Text color="#808080" ml={4}>
              Start Time*
            </Text>
            <ChartRowElement
              type="date"
              name={`${type}[${item.index}].startTime`}
              isDisabled={status !== "Administered" && status !== "Prepared"}
              w="240px"
            />
          </Box>
          <Box ml={4}>
            <Text color="#808080">Started by</Text>
            <UserInfo userId={watch(`${type}[${item.index}].startedBy`)} />
          </Box>
        </Box>
        {isIV && (
          <>
            <Step
              label="Stop Medication"
              isActive={status === "InProcess" || status === "Administered"}
              color="green"
            />
            <Box py={4} display="flex">
              <Box>
                <Text color="#808080" ml={4}>
                  Stop Time*
                </Text>
                <ChartRowElement
                  type="date"
                  name={`${type}[${item.index}].stopTime`}
                  w="240px"
                  isDisabled={
                    status !== "InProcess" && status !== "Administered"
                  }
                />
              </Box>
              <Box ml={4}>
                <Text color="#808080">Stopped by</Text>
                <UserInfo userId={watch(`${type}[${item.index}].stoppedBy`)} />
              </Box>
            </Box>
            <Box py={4} display="flex" gap={4}>
              <Box>
                <Text color="#808080" ml={4}>
                  Amount Infused*
                </Text>
                <Input
                  w="240px"
                  {...register(`${type}[${item.index}].amountInfused`)}
                  isDisabled={
                    status !== "InProcess" && status !== "Administered"
                  }
                />
              </Box>
              <Box>
                <Text color="#808080" ml={4}>
                  Amount Wasted*
                </Text>
                <Input
                  w="240px"
                  {...register(`${type}[${item.index}].amountWasted`)}
                  isDisabled={
                    status !== "InProcess" && status !== "Administered"
                  }
                />
              </Box>
            </Box>
          </>
        )}
        <Step
          label="Assess/Administer Medication"
          isActive={status === "Completed" || status === "Administered"}
          color="purple"
        />
        <Box py={4} display="flex" flexDir="column">
          <Box display="flex">
            <Box>
              <Text color="#808080" ml={4}>
                Assessment Time*
              </Text>
              <ChartRowElement
                type="date"
                name={`${type}[${item.index}].assessmentTime`}
                w="240px"
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
            </Box>
            <Box ml={4}>
              <Text color="#808080">Assessed by</Text>
              <UserInfo userId={watch(`${type}[${item.index}].assessedBy`)} />
            </Box>
          </Box>
          <Box pt={3}>
            <Text color="#808080" ml={4}>
              Change*
            </Text>
            <HStack justifyContent="center">
              <FormChartOption
                label="no change"
                control={control}
                name={`${type}[${item.index}].noChange`}
                bgColor="white"
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
              <FormChartOption
                label="improved"
                control={control}
                name={`${type}[${item.index}].improved`}
                bgColor="white"
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
              <FormChartOption
                label="NAR"
                control={control}
                name={`${type}[${item.index}].nar`}
                bgColor="white"
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
              <FormChartOption
                label="continued"
                control={control}
                name={`${type}[${item.index}].continued`}
                bgColor="white"
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
            </HStack>
          </Box>
          <Box pt={3}>
            <Text color="#808080" ml={4}>
              Pain (1/10)*
            </Text>
            <HStack justifyContent="center">
              <FormRadioChartOptionGroup
                options={[...Array(10).keys()].map((val) => ({
                  label: `${val + 1}`,
                  value: `${val + 1}`,
                }))}
                control={control}
                name={`${type}[${item.index}].pain`}
                isDisabled={status !== "Completed" && status !== "Administered"}
              />
            </HStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function MedsAndIvModal({
  isOpen,
  onClose,
  type,
  item,
  onSave,
}: MedsAndIvModalProps) {
  const { getValues, setValue, watch } = useFormContext();

  const { user: currentUser } = useAuth0();
  const userId = currentUser ? currentUser[USERID_CLAIMS] : "";

  const status = watch(`${type}[${item.index}].status`);

  const [isLoading, setIsLoading] = useState(false);

  const route = watch(`${type}[${item.index}].route`);

  const handleSave = async () => {
    setIsLoading(true);

    if (
      getValues(`${type}[${item.index}].preparationTime`) &&
      status === "Ordered"
    ) {
      setValue(`${type}[${item.index}].status`, "Prepared");
      if (!getValues(`${type}[${item.index}].preparedBy`)) {
        setValue(`${type}[${item.index}].preparedBy`, userId);
      }
    }

    if (
      getValues(`${type}[${item.index}].startTime`) &&
      status === "Prepared"
    ) {
      setValue(
        `${type}[${item.index}].status`,
        route === "IV" ? "InProcess" : "Completed"
      );

      if (!getValues(`${type}[${item.index}].startedBy`)) {
        setValue(`${type}[${item.index}].startedBy`, userId);
      }
    }

    if (
      getValues(`${type}[${item.index}].stopTime`) &&
      getValues(`${type}[${item.index}].amountInfused`) &&
      getValues(`${type}[${item.index}].amountWasted`) &&
      status === "InProcess"
    ) {
      setValue(`${type}[${item.index}].status`, "Completed");
      if (!getValues(`${type}[${item.index}].stoppedBy`)) {
        setValue(`${type}[${item.index}].stoppedBy`, userId);
      }
    }

    if (
      getValues(`${type}[${item.index}].assessmentTime`) &&
      getValues(`${type}[${item.index}].pain`) &&
      (getValues(`${type}[${item.index}].noChange`) ||
        getValues(`${type}[${item.index}].improved`) ||
        getValues(`${type}[${item.index}].nar`) ||
        getValues(`${type}[${item.index}].continued`)) &&
      status === "Completed"
    ) {
      setValue(`${type}[${item.index}].status`, "Administered");
      if (!getValues(`${type}[${item.index}].assessedBy`)) {
        setValue(`${type}[${item.index}].assessedBy`, userId);
      }
    }
    await onSave();
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered>
      <ModalContent background="gray.200" minW="1080px">
        <ModalHeader
          display="flex"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <chakra.span
            fontSize="16px"
            cursor="pointer"
            onClick={isLoading ? undefined : onClose}
            {...(isLoading ? { color: "gray.400" } : null)}
            color="blue"
          >
            Cancel
          </chakra.span>
          <chakra.span fontSize="16px">Meds & IV</chakra.span>
          <chakra.span
            fontSize="16px"
            cursor="pointer"
            color="blue"
            onClick={isLoading ? undefined : handleSave}
          >
            {isLoading ? <Spinner /> : "Save"}
          </chakra.span>
        </ModalHeader>
        <ModalBody display="flex" flexDir="column" gap="1rem">
          <IvMedicationsTypeContent item={item} type={type} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export {
  MedsAndIvModal,
  IvMedicationsTypeContent,
  MedicationTypeContent,
  IvStartsTypeContent,
};
