import {
  chakra,
  Box,
  Flex,
  Icon,
  Input,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { EmrBed, EmrRemove } from "@medstonetech/slate-icons";
import { OrderElementResponse } from "modules/order-list/types";
import { useState } from "react";
import { Button, CalendarInput, Label } from "shared";

const fieldLabelStyle = {
  mt: "10px",
  fontSize: "11pt",
};

type SchedulerProps = {
  repetitions: number;
  repetitionHours: number;
  repetitionMinutes: number;
  scheduledTime: Date | null;
  isScheduled: boolean;
  isPRN: boolean;
  prnReason: string;
};

const initialSchedulerProps: SchedulerProps = {
  prnReason: "",
  repetitions: 0,
  repetitionHours: 0,
  repetitionMinutes: 0,
  scheduledTime: null,
  isScheduled: false,
  isPRN: false,
};

type AddOrderItemSchedulerModalBodyProps = {
  item: OrderElementResponse;
  scheduleProps: SchedulerProps;
  setShowScheduler: (value: boolean) => void;
  setSchedulerProps: (value: SchedulerProps) => void;
};

const repetitionHoursOptions = [2, 4, 6, 8, 10, 12, 24];
const repetitionsOptions = [...Array(7)].map((_, index) => index + 1);

function AddOrderItemSchedulerModalBody(
  props: AddOrderItemSchedulerModalBodyProps
) {
  const { item, scheduleProps, setShowScheduler, setSchedulerProps } = props;

  const [isPRN, setIsPRN] = useState(scheduleProps.isPRN);
  const [isScheduled, setIsScheduled] = useState(scheduleProps.isScheduled);

  const [prnReason, setPrnReason] = useState(scheduleProps.prnReason);
  const [scheduledTime, setScheduledTime] = useState<Date | null>(
    scheduleProps.scheduledTime
  );

  const [repetitions, setRepetitions] = useState(
    repetitionsOptions.includes(scheduleProps.repetitions)
      ? scheduleProps.repetitions
      : 0
  );
  const [repetitionHours, setRepetitionHours] = useState(
    repetitionHoursOptions.includes(scheduleProps.repetitionHours)
      ? scheduleProps.repetitionHours
      : 0
  );
  const [repetitionsCustom, setRepetitionsCustom] = useState<number | string>(
    repetitions !== 0 && !repetitionsOptions.includes(scheduleProps.repetitions)
      ? scheduleProps.repetitions
      : ""
  );
  const [repetitionHoursCustom, setRepetitionHoursCustom] = useState<
    number | string
  >(
    !repetitionHoursOptions.includes(scheduleProps.repetitionHours)
      ? scheduleProps.repetitionHours
      : ""
  );
  const [repetitionMinutesCustom, setRepetitionMinutesCustom] = useState<
    number | string
  >(
    !repetitionHoursOptions.includes(scheduleProps.repetitionHours)
      ? scheduleProps.repetitionMinutes
      : ""
  );

  const cleanFields = () => {
    setIsPRN(false);
    setPrnReason("");
    setRepetitions(0);
    setRepetitionHours(0);
    setRepetitionsCustom("");
    setRepetitionHoursCustom("");
    setRepetitionMinutesCustom("");

    setScheduledTime(null);
  };

  return (
    <>
      <ModalBody
        display="flex"
        flexDirection="column"
        px="20px"
        py="20px"
        maxW="inherit"
      >
        <Box flex={1}>
          <Box>
            <Label {...fieldLabelStyle}>Start Time *</Label>
            <Flex maxW="240px">
              <CalendarInput
                name="scheduledTime"
                dateFormat={"LL/dd/yyyy HH:mm"}
                showIcon={false}
                showTimeInput={true}
                shouldCloseOnSelect={false}
                onChange={(date) => setScheduledTime(date)}
                selected={scheduledTime}
                onInputClick={() => {
                  if (!scheduledTime) {
                    setScheduledTime(new Date());
                  }
                }}
              />
            </Flex>
          </Box>

          <Box marginTop="20px">
            <Label {...fieldLabelStyle}>Repeat Every (hours)</Label>
            <Flex
              sx={{
                gap: "8px",
                marginTop: "4px",
                paddingLeft: "8px",
                paddingRight: "8px",
                justifyContent: "space-around",
              }}
              wrap="wrap"
            >
              {repetitionHoursOptions.map((value) => {
                const isSelected = repetitionHours === value;
                return (
                  <Box
                    key={value}
                    as="button"
                    width="35px"
                    height="35px"
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontWeight="500"
                    color={isSelected ? "#0000ff" : "black"}
                    border={isSelected ? "2px solid blue" : "1px solid gray"}
                    onClick={() => {
                      setRepetitionHours(value);
                      setRepetitionHoursCustom("");
                      setRepetitionMinutesCustom("");
                    }}
                    _hover={{ bgColor: "blue.100", cursor: "pointer" }}
                  >
                    {value}
                  </Box>
                );
              })}

              <Label
                sx={{
                  position: "relative",
                  top: "-3px",
                }}
                {...fieldLabelStyle}
              >
                Other
              </Label>
              <Input
                type="number"
                sx={{
                  width: "70px",
                  height: "35px",
                  borderRadius: "8px",
                  border: "1px solid gray",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  fontSize: "14px",
                  marginTop: "1px",
                }}
                value={repetitionHoursCustom}
                onChange={(e) => {
                  setRepetitionHours(0);

                  if (Number(e.target.value) < 0) setRepetitionHoursCustom("");
                  if (repetitionMinutesCustom === "")
                    setRepetitionMinutesCustom(0);

                  setRepetitionHoursCustom(
                    e.target.value === "" ? "" : Number(e.target.value)
                  );
                }}
              />
              <Input
                type="number"
                sx={{
                  width: "70px",
                  height: "35px",
                  borderRadius: "8px",
                  border: "1px solid gray",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  fontSize: "14px",
                  marginTop: "1px",
                }}
                value={repetitionMinutesCustom}
                onChange={(e) => {
                  setRepetitionHours(0);

                  if (Number(e.target.value) < 0 || Number(e.target.value) > 59)
                    setRepetitionMinutesCustom("");
                  if (repetitionHoursCustom === "") setRepetitionHoursCustom(0);

                  setRepetitionMinutesCustom(
                    e.target.value === "" ? "" : Number(e.target.value)
                  );
                }}
              />
            </Flex>
          </Box>

          <Box marginTop="20px">
            <Label {...fieldLabelStyle}>Max # Of Times</Label>
            <Flex
              sx={{
                gap: "8px",
                marginTop: "4px",
                paddingLeft: "8px",
                paddingRight: "8px",
                justifyContent: "space-around",
              }}
              wrap="wrap"
            >
              {repetitionsOptions.map((_, index) => {
                const value = index + 1;
                const isSelected = repetitions === value;
                return (
                  <Box
                    key={value}
                    as="button"
                    width="35px"
                    height="35px"
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontWeight="500"
                    color={isSelected ? "#0000ff" : "black"}
                    border={isSelected ? "2px solid blue" : "1px solid gray"}
                    onClick={() => {
                      setRepetitions(value);
                      setRepetitionsCustom("");
                    }}
                    _hover={{ bgColor: "blue.100", cursor: "pointer" }}
                  >
                    {value}
                  </Box>
                );
              })}

              <Label
                sx={{
                  position: "relative",
                  top: "-3px",
                }}
                {...fieldLabelStyle}
              >
                Other
              </Label>
              <Input
                type="number"
                sx={{
                  width: "70px",
                  height: "35px",
                  borderRadius: "8px",
                  border: "1px solid gray",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  fontSize: "14px",
                  marginTop: "1px",
                }}
                value={repetitionsCustom}
                onChange={(e) => {
                  setRepetitions(0);
                  setRepetitionsCustom(
                    e.target.value === "" ? "" : Number(e.target.value)
                  );
                }}
              />
            </Flex>
          </Box>

          {item.orderFormElementType === "Medication" && (
            <Box
              sx={{
                paddingLeft: "10px",
                marginTop: "40px",
                marginBottom: "15px",
              }}
            >
              <Button
                sx={{
                  gap: "16px",
                  width: "50%",
                  borderRadius: "8px",
                }}
                variant="outlineSquared"
                colorScheme={isPRN ? "blue" : "gray"}
                onClick={() => setIsPRN(!isPRN)}
              >
                <Icon as={EmrBed} w="24px" h="24px" /> PRN
              </Button>

              {isPRN && (
                <Box marginTop="20px">
                  <Label {...fieldLabelStyle}>PRN Reason</Label>
                  <Input
                    type="text"
                    value={prnReason}
                    onChange={(e) => setPrnReason(e.target.value)}
                    sx={{
                      width: "100%",
                      height: "35px",
                      borderRadius: "8px",
                      border: "1px solid gray",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      fontSize: "14px",
                      marginTop: "1px",
                    }}
                  />
                </Box>
              )}
            </Box>
          )}

          <Box
            sx={{
              paddingLeft: "10px",
              marginTop: "40px",
              marginBottom: "15px",
            }}
          >
            <Button
              w="100%"
              top="18px"
              height="100%"
              fontSize="0.9375rem"
              color="red"
              display="flex"
              variant="label"
              alignItems="center"
              marginLeft="8px"
              position="static"
              gap="10px"
              justifyContent="flex-start"
              disabled={!isScheduled}
              onClick={() => {
                cleanFields();

                setIsScheduled(false);
                setShowScheduler(false);
                setSchedulerProps(initialSchedulerProps);
              }}
            >
              <Icon as={EmrRemove} fontSize="1.525rem" />
              <chakra.span fontSize="15px" fontWeight="600" color="red">
                Remove Schedule
              </chakra.span>
            </Button>
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter
        justifyContent="space-around"
        bgColor="white"
        borderBottomRadius="md"
        px={2}
      >
        <Button
          variant="outlineSquared"
          colorScheme="gray"
          onClick={() => {
            cleanFields();

            setShowScheduler(false);
          }}
          width="240px"
        >
          Cancel
        </Button>
        <Button
          variant="outlineSquared"
          bgColor="blue"
          color="white"
          disabled={
            !scheduledTime ||
            (!repetitions && !repetitionsCustom) ||
            (!repetitionHours &&
              !repetitionHoursCustom &&
              !repetitionMinutesCustom) ||
            (isPRN && !prnReason)
          }
          onClick={() => {
            setIsScheduled(true);
            setShowScheduler(false);
            setSchedulerProps({
              repetitions: repetitions || Number(repetitionsCustom),
              repetitionHours: repetitionHours || Number(repetitionHoursCustom),
              repetitionMinutes: Number(repetitionMinutesCustom),
              scheduledTime,
              isScheduled: true,
              isPRN,
              prnReason,
            });
          }}
          width="240px"
        >
          Add Schedule
        </Button>
      </ModalFooter>
    </>
  );
}

export type { SchedulerProps };
export { AddOrderItemSchedulerModalBody, initialSchedulerProps };
