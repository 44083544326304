import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSectionHeader,
  ChartSubsectionHeader,
} from "modules";
import { Icon } from "@chakra-ui/react";

import { EmrProcedures } from "@medstonetech/slate-icons";
import { ProcedureSectionProps } from "./procedures";

function ProceduralSedationNote(props: ProcedureSectionProps) {
  const {
    chartCode,
    encounterId,
    sectionId,
    sectionCode,
    sectionSubCode,
    isReadOnly,
    onRemoveElement,
  } = props;

  const sectionDelimiter = `${sectionId}_${sectionCode}_${sectionSubCode}`;
  const subSectionDelimiter = `${chartCode}${sectionCode}${sectionSubCode}`;

  return (
    <>
      <ChartSectionHeader
        showDeleteBtn
        isSticky={false}
        icon={<Icon as={EmrProcedures} />}
        encounterId={encounterId}
        sectionId={sectionCode}
        showNotesBtn={false}
        onDeleteSection={() =>
          onRemoveElement(`${sectionId}_${sectionCode}_${sectionSubCode}_Q001`)
        }
      >
        Procedural Sedation Note {isReadOnly ? "(Read Only)" : ""}
      </ChartSectionHeader>
      <ChartSubsection
        header={
          <ChartSubsectionHeader
            subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q001`}
            encounterId={encounterId}
            chartId={chartCode}
            sectionId={sectionCode}
            showInteractions={false}
            subsectionLabel="Sedation type"
          >
            Sedation type
          </ChartSubsectionHeader>
        }
        content={
          <ChartSubsectionContent>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="deep"
                name={`${sectionDelimiter}_Q001.${subSectionDelimiter}Q001A001`}
              />
              <ChartRowElement
                type="option"
                label="moderate"
                name={`${sectionDelimiter}_Q001.${subSectionDelimiter}Q001A002`}
              />
              <ChartRowElement
                type="option"
                label="other"
                name={`${sectionDelimiter}_Q001.${subSectionDelimiter}Q001A003`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q001.${subSectionDelimiter}Q001A004`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q001.${subSectionDelimiter}Q001A005`}
              />
            </ChartRow>
          </ChartSubsectionContent>
        }
      />
      <ChartSubsection
        header={
          <ChartSubsectionHeader
            subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q002`}
            encounterId={encounterId}
            chartId={chartCode}
            sectionId={sectionCode}
            showInteractions={false}
            subsectionLabel="Indications"
          >
            Indications
          </ChartSubsectionHeader>
        }
        content={
          <ChartSubsectionContent>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="last meal"
                textDecoration="underline"
                name={`${sectionDelimiter}_Q002.${subSectionDelimiter}Q002A001`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q002.${subSectionDelimiter}Q002A003`}
              />
              <ChartRowElement
                type="option"
                name={`${sectionDelimiter}_Q002.${subSectionDelimiter}Q002A004`}
                label="date & time:"
                textDecoration="underline"
              />
              <ChartRowElement
                type="date"
                name={`${sectionDelimiter}_Q002.${subSectionDelimiter}Q002A005`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="last meal"
                textDecoration="underline"
                name={`${sectionDelimiter}_Q002.${subSectionDelimiter}Q002A006`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q002.${subSectionDelimiter}Q002A007`}
              />
              <ChartRowElement
                type="option"
                name={`${sectionDelimiter}_Q002.${subSectionDelimiter}Q002A008`}
                label="date & time:"
                textDecoration="underline"
              />
              <ChartRowElement
                type="date"
                name={`${sectionDelimiter}_Q002.${subSectionDelimiter}Q002A009`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q002.${subSectionDelimiter}Q002A010`}
              />
            </ChartRow>
          </ChartSubsectionContent>
        }
      />
      <ChartSubsection
        header={
          <ChartSubsectionHeader
            subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q003`}
            encounterId={encounterId}
            chartId={chartCode}
            sectionId={sectionCode}
            showInteractions={false}
            subsectionLabel="HPI"
          >
            HPI
          </ChartSubsectionHeader>
        }
        content={
          <ChartSubsectionContent>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="prior complications to general anesthesia"
                textDecoration="underline"
                name={`${sectionDelimiter}_Q003.${subSectionDelimiter}Q004A001`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q003.${subSectionDelimiter}Q003A002`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="prior complications to procedural sedation"
                textDecoration="underline"
                name={`${sectionDelimiter}_Q003.${subSectionDelimiter}Q003A003`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q003.${subSectionDelimiter}Q003A004`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q003.${subSectionDelimiter}Q003A005`}
              />
            </ChartRow>
          </ChartSubsectionContent>
        }
      />
      <ChartSubsection
        header={
          <ChartSubsectionHeader
            subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q004`}
            encounterId={encounterId}
            chartId={chartCode}
            sectionId={sectionCode}
            showInteractions={false}
            subsectionLabel="Anesthesia"
          >
            Anesthesia
          </ChartSubsectionHeader>
        }
        content={
          <ChartSubsectionContent>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="local"
                name={`${sectionDelimiter}_Q004.${subSectionDelimiter}Q004A001`}
              />
              <ChartRowElement
                type="option"
                name={`${sectionDelimiter}_Q004.${subSectionDelimiter}Q004A002`}
                label="topical"
              />
              <ChartRowElement
                type="input"
                w="695px"
                name={`${sectionDelimiter}_Q004.${subSectionDelimiter}Q004A003`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="lidocaine"
                name={`${sectionDelimiter}_Q004.${subSectionDelimiter}Q004A004`}
              />
              <ChartRowElement
                type="option"
                name={`${sectionDelimiter}_Q004.${subSectionDelimiter}Q004A005`}
                label="bupivacaine"
              />
              <ChartRowElement
                type="option"
                name={`${sectionDelimiter}_Q004.${subSectionDelimiter}Q004A006`}
                label="epi"
              />
              <ChartRowElement
                type="option"
                name={`${sectionDelimiter}_Q004.${subSectionDelimiter}Q004A007`}
                label="bicarb"
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q004.${subSectionDelimiter}Q004A008`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q004.${subSectionDelimiter}Q004A009`}
              />
            </ChartRow>
          </ChartSubsectionContent>
        }
      />
      <ChartSubsection
        header={
          <ChartSubsectionHeader
            subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q005`}
            encounterId={encounterId}
            chartId={chartCode}
            sectionId={sectionCode}
            showInteractions={false}
            subsectionLabel="Allergies"
          >
            Allergies
          </ChartSubsectionHeader>
        }
        content={
          <ChartSubsectionContent>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="NKDA"
                name={`${sectionDelimiter}_Q005.${subSectionDelimiter}Q005A001`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q005.${subSectionDelimiter}Q005A002`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="brevital"
                name={`${sectionDelimiter}_Q005.${subSectionDelimiter}Q005A003`}
              />
              <ChartRowElement
                type="option"
                label="etomidate"
                name={`${sectionDelimiter}_Q005.${subSectionDelimiter}Q005A004`}
              />
              <ChartRowElement
                type="option"
                label="fentanyl"
                name={`${sectionDelimiter}_Q005.${subSectionDelimiter}Q005A005`}
              />
              <ChartRowElement
                type="option"
                label="ketamine"
                name={`${sectionDelimiter}_Q005.${subSectionDelimiter}Q005A006`}
              />
              <ChartRowElement
                type="option"
                label="lidocaine"
                name={`${sectionDelimiter}_Q005.${subSectionDelimiter}Q005A007`}
              />
              <ChartRowElement
                type="option"
                label="midazolam"
                name={`${sectionDelimiter}_Q005.${subSectionDelimiter}Q005A008`}
              />
              <ChartRowElement
                type="option"
                label="morphine"
                name={`${sectionDelimiter}_Q005.${subSectionDelimiter}Q005A009`}
              />
              <ChartRowElement
                type="option"
                label="nitrous oxide"
                name={`${sectionDelimiter}_Q005.${subSectionDelimiter}Q005A010`}
              />
              <ChartRowElement
                type="option"
                label="propofol"
                name={`${sectionDelimiter}_Q005.${subSectionDelimiter}Q005A011`}
              />
              <ChartRowElement
                type="option"
                label="other"
                name={`${sectionDelimiter}_Q005.${subSectionDelimiter}Q005A012`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q005.${subSectionDelimiter}Q005A013`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q005.${subSectionDelimiter}Q005A014`}
              />
            </ChartRow>
          </ChartSubsectionContent>
        }
      />
      <ChartSubsection
        header={
          <ChartSubsectionHeader
            subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q006`}
            encounterId={encounterId}
            chartId={chartCode}
            sectionId={sectionCode}
            showInteractions={false}
            subsectionLabel="ASA Classification"
          >
            ASA Classification
          </ChartSubsectionHeader>
        }
        content={
          <ChartSubsectionContent>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="E. Emergency condition applies"
                name={`${sectionDelimiter}_Q006.${subSectionDelimiter}Q006A001`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q006.${subSectionDelimiter}Q006A002`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="P1. Normal healthy patient"
                name={`${sectionDelimiter}_Q006.${subSectionDelimiter}Q006A003`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q006.${subSectionDelimiter}Q006A004`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="P2. Patient with a mild systemic disease"
                name={`${sectionDelimiter}_Q006.${subSectionDelimiter}Q006A005`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q006.${subSectionDelimiter}Q006A006`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="P3. Patient with a severe systemic disease"
                name={`${sectionDelimiter}_Q006.${subSectionDelimiter}Q006A007`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q006.${subSectionDelimiter}Q006A008`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="P4. Patient with a severe systemic disease that is a constant threat to life"
                name={`${sectionDelimiter}_Q006.${subSectionDelimiter}Q006A009`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q006.${subSectionDelimiter}Q006A010`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="P5. Moribund patient who is not expected to survive w/o the operation"
                name={`${sectionDelimiter}_Q006.${subSectionDelimiter}Q006A011`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q006.${subSectionDelimiter}Q006A012`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q006.${subSectionDelimiter}Q006A013`}
              />
            </ChartRow>
          </ChartSubsectionContent>
        }
      />
      <ChartSubsection
        header={
          <ChartSubsectionHeader
            subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q007`}
            encounterId={encounterId}
            chartId={chartCode}
            sectionId={sectionCode}
            showInteractions={false}
            subsectionLabel="Airway"
          >
            Airway
          </ChartSubsectionHeader>
        }
        content={
          <ChartSubsectionContent>
            <ChartRow>
              <ChartRowGroup width="250px">
                <ChartRowElement
                  type="option"
                  label="nml anatomy"
                  name={`${sectionDelimiter}_Q007.${subSectionDelimiter}Q007A001`}
                />
                <ChartRowElement type="empty" />
              </ChartRowGroup>
              <ChartRowGroup>
                <ChartRowElement
                  type="option"
                  label="obese"
                  name={`${sectionDelimiter}_Q007.${subSectionDelimiter}Q007A002`}
                />
                <ChartRowElement
                  type="input"
                  name={`${sectionDelimiter}_Q007.${subSectionDelimiter}Q007A003`}
                />
              </ChartRowGroup>
            </ChartRow>
            <ChartRow>
              <ChartRowGroup width="250px">
                <ChartRowElement type="empty" />
              </ChartRowGroup>
              <ChartRowGroup>
                <ChartRowElement
                  type="option"
                  label="large tonge"
                  name={`${sectionDelimiter}_Q007.${subSectionDelimiter}Q007A004`}
                />
                <ChartRowElement
                  type="option"
                  label="Large teeth"
                  name={`${sectionDelimiter}_Q007.${subSectionDelimiter}Q007A005`}
                />
                <ChartRowElement
                  type="input"
                  name={`${sectionDelimiter}_Q007.${subSectionDelimiter}Q007A006`}
                />
              </ChartRowGroup>
            </ChartRow>
            <ChartRow>
              <ChartRowGroup width="250px">
                <ChartRowElement type="empty" />
              </ChartRowGroup>
              <ChartRowGroup>
                <ChartRowElement
                  type="option"
                  label="angioedema"
                  name={`${sectionDelimiter}_Q007.${subSectionDelimiter}Q007A007`}
                />
                <ChartRowElement
                  type="input"
                  name={`${sectionDelimiter}_Q007.${subSectionDelimiter}Q007A008`}
                />
              </ChartRowGroup>
            </ChartRow>
            <ChartRow>
              <ChartRowGroup width="250px">
                <ChartRowElement type="empty" />
              </ChartRowGroup>
              <ChartRowGroup>
                <ChartRowElement
                  type="option"
                  label="abnormal 3-3-2 rule"
                  name={`${sectionDelimiter}_Q007.${subSectionDelimiter}Q007A009`}
                />
                <ChartRowElement
                  type="input"
                  name={`${sectionDelimiter}_Q007.${subSectionDelimiter}Q007A010`}
                />
              </ChartRowGroup>
            </ChartRow>
            <ChartRow>
              <ChartRowGroup width="250px">
                <ChartRowElement type="empty" />
              </ChartRowGroup>
              <ChartRowGroup>
                <ChartRowElement
                  type="option"
                  label="possible upper airway obstruction"
                  name={`${sectionDelimiter}_Q007.${subSectionDelimiter}Q007A011`}
                />
                <ChartRowElement
                  type="input"
                  name={`${sectionDelimiter}_Q007.${subSectionDelimiter}Q007A012`}
                />
              </ChartRowGroup>
            </ChartRow>
            <ChartRow>
              <ChartRowGroup width="250px">
                <ChartRowElement type="empty" />
              </ChartRowGroup>
              <ChartRowGroup>
                <ChartRowElement
                  type="option"
                  label="neck immobility"
                  name={`${sectionDelimiter}_Q007.${subSectionDelimiter}Q007A013`}
                />
                <ChartRowElement
                  type="input"
                  name={`${sectionDelimiter}_Q007.${subSectionDelimiter}Q007A014`}
                />
              </ChartRowGroup>
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q007.${subSectionDelimiter}Q007A015`}
              />
            </ChartRow>
          </ChartSubsectionContent>
        }
      />
      <ChartSubsection
        header={
          <ChartSubsectionHeader
            subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q008`}
            encounterId={encounterId}
            chartId={chartCode}
            sectionId={sectionCode}
            showInteractions={false}
            subsectionLabel="Mallampati Classification"
          >
            Mallampati Classification
          </ChartSubsectionHeader>
        }
        content={
          <ChartSubsectionContent>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="Class1. Soft palate, anterior / posterior, tonsillar pillars, and uvula visible"
                name={`${sectionDelimiter}_Q008.${subSectionDelimiter}Q008A001`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q008.${subSectionDelimiter}Q008A002`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="Class2. Tonsillar pillars and uvula hidden by base of tongue"
                name={`${sectionDelimiter}_Q008.${subSectionDelimiter}Q008A003`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q008.${subSectionDelimiter}Q008A004`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="Class3. Only soft palate visible"
                name={`${sectionDelimiter}_Q008.${subSectionDelimiter}Q008A005`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q008.${subSectionDelimiter}Q008A006`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="Class4. Soft palate not visible"
                name={`${sectionDelimiter}_Q008.${subSectionDelimiter}Q008A007`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q008.${subSectionDelimiter}Q008A008`}
              />
            </ChartRow>

            <ChartRow>
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q008.${subSectionDelimiter}Q008A009`}
              />
            </ChartRow>
          </ChartSubsectionContent>
        }
      />
      <ChartSubsection
        header={
          <ChartSubsectionHeader
            subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q009`}
            encounterId={encounterId}
            chartId={chartCode}
            sectionId={sectionCode}
            showInteractions={false}
            subsectionLabel="Preparation"
          >
            Preparation
          </ChartSubsectionHeader>
        }
        content={
          <ChartSubsectionContent>
            <ChartRow>
              <ChartRowElement
                type="option"
                name={`${sectionDelimiter}_Q009.${subSectionDelimiter}Q009A001`}
                label="plain explained to:"
                textDecoration="underline"
              />
              <ChartRowElement
                type="option"
                label="patient"
                name={`${sectionDelimiter}_Q009.${subSectionDelimiter}Q009A002`}
              />
              <ChartRowElement
                type="option"
                label="parent"
                name={`${sectionDelimiter}_Q009.${subSectionDelimiter}Q009A003`}
              />
              <ChartRowElement
                type="option"
                label="guardian"
                name={`${sectionDelimiter}_Q009.${subSectionDelimiter}Q009A004`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q009.${subSectionDelimiter}Q009A005`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="consent signed (see hospital consent)"
                name={`${sectionDelimiter}_Q009.${subSectionDelimiter}Q009A006`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q009.${subSectionDelimiter}Q009A007`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="oximetry during procedure"
                name={`${sectionDelimiter}_Q009.${subSectionDelimiter}Q009A008`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q009.${subSectionDelimiter}Q009A009`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="IV access obtained"
                name={`${sectionDelimiter}_Q009.${subSectionDelimiter}Q009A010`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q009.${subSectionDelimiter}Q009A011`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="suction immediately available"
                name={`${sectionDelimiter}_Q009.${subSectionDelimiter}Q009A012`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q009.${subSectionDelimiter}Q009A013`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="cardiac monitor use"
                name={`${sectionDelimiter}_Q009.${subSectionDelimiter}Q009A014`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q009.${subSectionDelimiter}Q009A015`}
              />
            </ChartRow>
          </ChartSubsectionContent>
        }
      />
      <ChartSubsection
        header={
          <ChartSubsectionHeader
            subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q010`}
            encounterId={encounterId}
            chartId={chartCode}
            sectionId={sectionCode}
            showInteractions={false}
            subsectionLabel="Sedation"
          >
            Sedation
          </ChartSubsectionHeader>
        }
        content={
          <ChartSubsectionContent>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="etomidate"
                name={`${sectionDelimiter}_Q010.${subSectionDelimiter}Q010A001`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q010.${subSectionDelimiter}Q010A002`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="fentanyl"
                name={`${sectionDelimiter}_Q010.${subSectionDelimiter}Q010A003`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q010.${subSectionDelimiter}Q010A004`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="ketamine"
                name={`${sectionDelimiter}_Q010.${subSectionDelimiter}Q010A005`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q010.${subSectionDelimiter}Q010A006`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="propofol"
                name={`${sectionDelimiter}_Q010.${subSectionDelimiter}Q010A007`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q010.${subSectionDelimiter}Q010A008`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="versed"
                name={`${sectionDelimiter}_Q010.${subSectionDelimiter}Q010A009`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q010.${subSectionDelimiter}Q010A010`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q010.${subSectionDelimiter}Q010A011`}
              />
            </ChartRow>
          </ChartSubsectionContent>
        }
      />
      <ChartSubsection
        header={
          <ChartSubsectionHeader
            subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q011`}
            encounterId={encounterId}
            chartId={chartCode}
            sectionId={sectionCode}
            showInteractions={false}
            subsectionLabel="Reversal"
          >
            Reversal
          </ChartSubsectionHeader>
        }
        content={
          <ChartSubsectionContent>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="none"
                name={`${sectionDelimiter}_Q011.${subSectionDelimiter}Q011A001`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q011.${subSectionDelimiter}Q011A002`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="narcan"
                name={`${sectionDelimiter}_Q011.${subSectionDelimiter}Q011A003`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q011.${subSectionDelimiter}Q011A004`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="romazicon"
                name={`${sectionDelimiter}_Q011.${subSectionDelimiter}Q011A005`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q011.${subSectionDelimiter}Q011A006`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q011.${subSectionDelimiter}Q011A007`}
              />
            </ChartRow>
          </ChartSubsectionContent>
        }
      />
      <ChartSubsection
        header={
          <ChartSubsectionHeader
            subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q012`}
            encounterId={encounterId}
            chartId={chartCode}
            sectionId={sectionCode}
            showInteractions={false}
            subsectionLabel="Complications During / After Procedure"
          >
            Complications During / After Procedure
          </ChartSubsectionHeader>
        }
        content={
          <ChartSubsectionContent>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="none"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A001`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A002`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="vomiting"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A003`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A004`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="apnea"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A005`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A006`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="O2 desaturation"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A007`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A008`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="required BVM-PPV"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A009`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A010`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="hypotension"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A011`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A012`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="apnea"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A013`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A014`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                label="apnea"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A015`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A016`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q012.${subSectionDelimiter}Q012A017`}
              />
            </ChartRow>
          </ChartSubsectionContent>
        }
      />
      <ChartSubsection
        header={
          <ChartSubsectionHeader
            subsectionId={`${sectionId}_${sectionCode}_${sectionSubCode}_Q013`}
            encounterId={encounterId}
            chartId={chartCode}
            sectionId={sectionCode}
            showInteractions={false}
            subsectionLabel="Post sedation recovery score"
          >
            Post sedation recovery score
          </ChartSubsectionHeader>
        }
        content={
          <ChartSubsectionContent>
            <ChartRow>
              <ChartRowElement
                type="option"
                name={`${sectionDelimiter}_Q013.${subSectionDelimiter}Q013A001`}
                label="I personally performed"
                textDecoration="underline"
              />
              <ChartRowElement
                type="option"
                label="sedation"
                name={`${sectionDelimiter}_Q013.${subSectionDelimiter}Q013A002`}
              />
              <ChartRowElement
                type="option"
                label="procedure"
                name={`${sectionDelimiter}_Q013.${subSectionDelimiter}Q013A003`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q013.${subSectionDelimiter}Q013A004`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="option"
                name={`${sectionDelimiter}_Q013.${subSectionDelimiter}Q013A005`}
                label="Intra-service time:"
                textDecoration="underline"
              />
              <ChartRowElement
                type="option"
                label="< 10 mins"
                name={`${sectionDelimiter}_Q013.${subSectionDelimiter}Q013A006`}
              />
              <ChartRowElement
                type="option"
                label="10-22 min"
                name={`${sectionDelimiter}_Q013.${subSectionDelimiter}Q013A007`}
              />
              <ChartRowElement
                type="option"
                label="23-37 min"
                name={`${sectionDelimiter}_Q013.${subSectionDelimiter}Q013A008`}
              />
              <ChartRowElement
                type="option"
                label="38-52 min"
                name={`${sectionDelimiter}_Q013.${subSectionDelimiter}Q013A009`}
              />
              <ChartRowElement
                type="option"
                label="53-67 min"
                name={`${sectionDelimiter}_Q013.${subSectionDelimiter}Q013A010`}
              />
              <ChartRowElement
                type="option"
                label="68-82 min"
                name={`${sectionDelimiter}_Q013.${subSectionDelimiter}Q013A011`}
              />
              <ChartRowElement
                type="option"
                label="83-97 min"
                name={`${sectionDelimiter}_Q013.${subSectionDelimiter}Q013A012`}
              />
              <ChartRowElement
                type="option"
                label="98-112 min"
                name={`${sectionDelimiter}_Q013.${subSectionDelimiter}Q013A013`}
              />
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q013.${subSectionDelimiter}Q013A014`}
              />
            </ChartRow>
            <ChartRow>
              <ChartRowElement
                type="input"
                name={`${sectionDelimiter}_Q013.${subSectionDelimiter}Q013A015`}
              />
            </ChartRow>
          </ChartSubsectionContent>
        }
      />
    </>
  );
}

export { ProceduralSedationNote };
