import {
  Badge,
  Box,
  BoxProps,
  ButtonProps,
  chakra,
  Flex,
  Grid,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  UseDisclosureReturn,
  useToast,
} from "@chakra-ui/react";
import { OrderFormElementType, OrderStatus } from "enums";
import { usePagination } from "hooks";
import {
  CloseCircle,
  Inbox,
  InfoRoundOutline,
  BubbleChatIndicator,
  DeleteOutline,
  PillsBottle,
  MedicalCross,
  PRNLabel,
} from "icons";
import { SharedChartBaseProps, UseUsersResponseItem } from "modules";
import {
  ChartSection,
  OrderHistoryItem,
  ScribeOrderActionsModal,
  SharedChartSectionHeader,
  useOrderHistoryPagination,
} from "modules/charts-shared";
import {
  CancellationDetailModal,
  CancelOrderModal,
  OrderNotesModal,
} from "modules/orders";
import {
  ORDER_STATUS_COLOR,
  ORDER_STATUS_ICON,
} from "modules/orders/constants";
import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Card,
  Loading,
  PaginationControl,
  BackButton,
  PdfViewer,
  PdfViewerActions,
  PdfViewerActivePage,
  PdfViewerContainer,
  PdfViewerHeader,
  PdfViewerPreview,
  Button,
  WarningDialog,
  Select,
} from "shared";
import { extractApiErrorMessage } from "utils";
import * as enumMapper from "utils/enum-mapper";
import { ChartRouteBaseParams } from "../types";
import {
  EmrBed,
  EmrHeartBolt,
  EmrLabs,
  EmrNotes,
  EmrRadiology,
} from "@medstonetech/slate-icons";
import {
  useChangeOrderStatus,
  useDeleteOrder,
  useEncounterOrderFile,
} from "modules/orders/api";
import { OrderProviderAuthorization } from "./order-provider-authorization";
import { format, isValid } from "date-fns";
import { Cancellation } from "modules/orders/types/cancellation";
import { useChartTrackingContext, usePermissions } from "contexts";
import { formMessages } from "messages";

const ALL_MEDICATION_STATUSES = [
  { value: "Ordered", label: "Ordered", color: "orange", displayed: true },
  { value: "InProcess", label: "In Process", color: "blue", displayed: true },
  { value: "Completed", label: "Completed", color: "green", displayed: true },
  {
    value: "Administered",
    label: "Administered",
    color: "purple",
    displayed: true,
  },
  { value: "Prepared", label: "Prepared", color: "orange", displayed: false },
  {
    value: "Cancelled",
    label: "Cancelled",
    color: "gray.450",
    displayed: false,
  },
  {
    value: "NeedsApproval",
    label: "Needs Approval",
    color: "yellow",
    displayed: false,
  },
  {
    value: "CancellationRequested",
    label: "Cancellation Requested",
    color: "red",
    displayed: false,
  },
];

type OrderCardProps = {
  encounterId: string;
  order: OrderHistoryItem;
  icon?: React.ReactNode;
  onItemClick?: () => void;
  onOpenHistoryReport: (
    orderId: string,
    orderType?: OrderFormElementType
  ) => void;
  isReadOnly?: boolean;
} & Omit<BoxProps, "order">;

type ButtonCancelOrderModalProps = {
  orderId: string;
  encounterId: string;
  cancellation?: Cancellation;
  cancelledBy?: UseUsersResponseItem;
  orderStatus: OrderStatus;
} & Omit<ButtonProps, "onClick">;

function ButtonCancelOrderModal(props: ButtonCancelOrderModalProps) {
  const {
    orderId,
    encounterId,
    isDisabled,
    cancellation,
    cancelledBy,
    orderStatus,
    ...rest
  } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  const getButtonIcon = () => {
    if (orderStatus === "Cancelled") {
      return <Icon as={InfoRoundOutline} color="blue" h="24px" w="24px" />;
    }

    if (orderStatus === "CancellationRequested") {
      return <Icon as={BubbleChatIndicator} color="red" h="24px" w="24px" />;
    }

    return (
      <Icon
        as={CloseCircle}
        color={isDisabled ? "gray" : "red"}
        h="24px"
        w="24px"
      />
    );
  };

  return (
    <>
      <Box position="relative" textAlign="center">
        <Box
          backgroundColor="white"
          borderRadius="50%"
          position="absolute"
          h="14px"
          w="14px"
          top="calc(50% - 7px)"
          left="calc(50% - 7px)"
        />
        <IconButton
          size="iconSm"
          aria-label="Cancel"
          variant="ghost"
          h="40px"
          w="40px"
          onClick={onOpen}
          isDisabled={isDisabled}
          icon={getButtonIcon()}
          {...rest}
        />
      </Box>
      {!cancellation && (
        <CancelOrderModal
          isModalOpen={isOpen}
          onModalClose={onClose}
          orderId={orderId}
          encounterId={encounterId}
        />
      )}

      {cancellation && (
        <CancellationDetailModal
          isModalOpen={isOpen}
          onModalClose={onClose}
          orderId={orderId}
          encounterId={encounterId}
        />
      )}
    </>
  );
}

type DeleteOrderButtonProps = {
  orderId: string;
  encounterId: string;
} & Omit<ButtonProps, "onClick">;

function DeleteOrderButton(props: DeleteOrderButtonProps) {
  const toast = useToast();
  const { orderId, encounterId } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutateAsync: deleteOrder, isLoading } = useDeleteOrder(
    orderId,
    encounterId
  );

  const handleDelete = async () => {
    try {
      await deleteOrder({});
      toast({ description: formMessages.deleteSuccess("Order") });
      onClose();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
      onClose();
    }
  };

  return (
    <Box textAlign="center">
      <IconButton
        size="iconSm"
        aria-label="Cancel"
        variant="ghost"
        h="40px"
        w="40px"
        color="red"
        onClick={onOpen}
        icon={<DeleteOutline fontSize="28px" />}
        isLoading={isLoading}
        isDisabled={isLoading}
      />
      <WarningDialog
        isOpen={isOpen}
        mainText="Are you sure you want to Delete this order?"
        title="Warning!"
        actionLabel="Delete"
        onCancel={onClose}
        onAction={handleDelete}
        onClose={onClose}
      />
    </Box>
  );
}

type IconButtonOrderNotesModalProps = {
  order: OrderHistoryItem;
};

function IconButtonOrderNotesModal(props: IconButtonOrderNotesModalProps) {
  const { order } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <IconButton
        onClick={onOpen}
        aria-label="notes"
        icon={
          <Icon
            as={EmrNotes}
            color={order.notes && order.notes !== "" ? "cyan" : "gray.450"}
            fontSize="1.375rem"
          />
        }
        variant="ghost"
        height="2rem"
        width="2rem"
        minWidth="unset"
      />
      {isOpen && (
        <OrderNotesModal
          title={"Order Notes"}
          isOpen={isOpen}
          notes={order.notes}
          onClose={onClose}
        />
      )}
    </>
  );
}

type IconButtonOrderPRNModalProps = {
  order: OrderHistoryItem;
};

function IconButtonOrderPRNModal(props: IconButtonOrderPRNModalProps) {
  const { order } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <IconButton
        onClick={onOpen}
        aria-label="notes"
        icon={
          <Icon
            as={PRNLabel}
            fill="none"
            fontSize="2.375rem"
            color={order.status === "PRN" ? "blue" : "gray"}
          />
        }
        variant="ghost"
        height="2rem"
        width="2rem"
        minWidth="unset"
      />
      {isOpen && (
        <OrderNotesModal
          title={"PRN Reason"}
          isOpen={isOpen}
          notes={order.prnReason}
          onClose={onClose}
        />
      )}
    </>
  );
}

type OrderStatusSelectProps = { order: OrderHistoryItem; encounterId: string };

function OrderStatusSelect({ order, encounterId }: OrderStatusSelectProps) {
  const toast = useToast();

  const color = ALL_MEDICATION_STATUSES.find(
    (x) => x.value === order.status
  )?.color;

  const valueToOption = (value?: string) => {
    if (!value) {
      return null;
    }

    return ALL_MEDICATION_STATUSES.find((x) => x.value === value);
  };

  const { mutateAsync: changeOrderStatus, isLoading } = useChangeOrderStatus(
    order.id,
    encounterId
  );

  const handleStatusChange = async (status: OrderStatus) => {
    try {
      await changeOrderStatus({ status });
      toast({ description: formMessages.updateSuccess("Order") });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  return (
    <Select
      btnProps={{
        borderRadius: "20px",
        bgColor: color,
        color: "white",
        _hover: { bgColor: color },
      }}
      iconProps={{ color: "white" }}
      items={ALL_MEDICATION_STATUSES.filter((x) => x.displayed).filter(
        (x) =>
          (order.elementType !== "Medication" && x.value !== "Administered") ||
          order.elementType === "Medication"
      )}
      defaultSelectedItem={valueToOption(order.status)}
      labelAccessor="label"
      valueAccessor="value"
      showBlank={false}
      isDisabled={isLoading}
      onSelectedItemChange={(x) =>
        x.selectedItem &&
        handleStatusChange(x.selectedItem.value as OrderStatus)
      }
    />
  );
}

const templateColumsGrid = `
  10rem 8rem 5rem 5rem 22rem 5rem 6rem 8rem 5rem 5rem 5rem`;

const headerTemplateColumsGrid = `${templateColumsGrid}`;

const headerItemsStyles = {
  color: "gray.650",
  fontSize: "0.9375rem",
  fontWeight: "500",
  lineHeight: "1.11875rem",
};

function OrderCard(props: OrderCardProps) {
  const {
    order,
    encounterId,
    icon,
    onItemClick,
    onOpenHistoryReport,
    isReadOnly,
    ...rest
  } = props;

  const { scope } = usePermissions();
  const isAdmin = scope("orderadmin").isAccessible;

  return (
    <Card
      variant="default"
      bgColor={order.status === "NeedsApproval" ? "yellow" : "white"}
      marginBottom="0.625rem"
      {...rest}
    >
      <HStack spacing="1.25rem">
        <Box
          flex="1"
          onClick={onItemClick}
          cursor={order.status === "NeedsApproval" ? "pointer" : "default"}
        >
          <Grid
            templateColumns={`${templateColumsGrid} ${isAdmin ? "5rem" : ""}`}
            width="100%"
            height="66px"
            overflow="hidden"
            alignItems="center"
            columnGap="0.75rem"
          >
            <Box justifySelf="center" width="95%">
              {isAdmin ? (
                <OrderStatusSelect order={order} encounterId={encounterId} />
              ) : (
                <Badge
                  bgColor={ORDER_STATUS_COLOR[order.status || "InProcess"]}
                  fontWeight="700"
                  fontSize="15px"
                  color="white"
                  borderRadius="20px"
                  padding="0.5rem 0.5rem"
                  textTransform="capitalize"
                  width="100%"
                  textAlign="center"
                >
                  {enumMapper.toDisplay(
                    "orderStatus",
                    (order.status || "InProcess") === "PRN"
                      ? "Ordered"
                      : order.status
                  )}
                </Badge>
              )}
            </Box>
            <OrderCardItem textOverflow="revert">
              {format(new Date(order.timeOrdered), "MM-dd-yyyy")}
              <br />
              {format(new Date(order.timeOrdered), "hh:MM aa")}
            </OrderCardItem>
            <OrderCardItem>{order.orderId}</OrderCardItem>
            <OrderCardItem>
              <Icon
                color="gray.400"
                as={
                  {
                    Lab: EmrLabs,
                    Radiology: EmrRadiology,
                    Medication: PillsBottle,
                    Treatment: MedicalCross,
                    EKG: EmrHeartBolt,
                  }[order.elementType]
                }
                width="1.5em"
                height="1.5em"
              />
            </OrderCardItem>
            <OrderCardItem>
              <Text>{order.description}</Text>
              <Text fontSize="0.875rem" color="gray">
                {order.location}
              </Text>
            </OrderCardItem>
            <OrderCardItem>
              {order.elementType === "Radiology" ? order.examID : ""}
            </OrderCardItem>
            <OrderCardItem>
              {order.isObservation ? (
                <Icon as={EmrBed} color="purple" width="1.5em" height="1.5em" />
              ) : (
                ""
              )}
            </OrderCardItem>
            <OrderCardItem fontSize="0.938rem">
              {isValid(new Date(order?.scheduledTime ?? "")) &&
                format(new Date(order?.scheduledTime ?? ""), "MM-dd-yyyy")}
              <br />
              {isValid(new Date(order?.scheduledTime ?? "")) &&
                format(new Date(order?.scheduledTime ?? ""), "hh:mm aa")}
            </OrderCardItem>
            <OrderCardItem display="flex" justifyContent="center">
              {order.elementType === "Medication" &&
                order.isPRN &&
                order.prnReason && <IconButtonOrderPRNModal order={order} />}
            </OrderCardItem>
            <OrderCardItem display="flex" justifyContent="center">
              <IconButtonOrderNotesModal order={order} />
            </OrderCardItem>
            <Box display="flex" alignItems="center" justifyContent="center">
              <ButtonCancelOrderModal
                encounterId={encounterId}
                orderStatus={order.status}
                orderId={order.id}
                cancellation={order.cancellation}
                cancelledBy={order.cancelledBy}
                isDisabled={
                  ["Administered", "Completed", "InProcess"].includes(
                    order?.status
                  ) || isReadOnly
                }
              />
            </Box>
            {isAdmin && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <DeleteOrderButton
                  orderId={order.id}
                  encounterId={encounterId}
                />
              </Box>
            )}
          </Grid>
        </Box>
      </HStack>
    </Card>
  );
}

function OrderCardItem(props: BoxProps) {
  const { children, ...rest } = props;
  return (
    <Box
      color="black"
      fontSize="1rem"
      fontWeight="500"
      lineHeight="1.36375rem"
      textOverflow="ellipsis"
      whiteSpace="pre"
      width="100%"
      overflow="hidden"
      {...rest}
    >
      {children}
    </Box>
  );
}

function OrderCardHeader(props: BoxProps) {
  const { scope } = usePermissions();
  const isAdmin = scope("orderadmin").isAccessible;
  return (
    <Card
      variant="default"
      width="100%"
      bgColor="gray.200"
      borderColor="gray.450"
      borderWidth="1px"
      {...props}
    >
      <Grid
        templateColumns={`${headerTemplateColumsGrid} ${isAdmin ? "5rem" : ""}`}
        width="100%"
        alignItems="center"
        height="50px"
        columnGap="0.75rem"
      >
        <chakra.span justifySelf="center" {...headerItemsStyles}>
          Status
        </chakra.span>
        <chakra.span {...headerItemsStyles}>Ordered</chakra.span>
        <chakra.span {...headerItemsStyles}>
          Ordered <br /> ID
        </chakra.span>
        <chakra.span {...headerItemsStyles}>Type</chakra.span>
        <chakra.span {...headerItemsStyles}>Description</chakra.span>
        <chakra.span {...headerItemsStyles}>
          Study <br /> ID
        </chakra.span>
        <chakra.span {...headerItemsStyles}>
          Observation <br /> Order
        </chakra.span>
        <chakra.span {...headerItemsStyles}>Scheduled</chakra.span>
        <chakra.span
          {...headerItemsStyles}
          textAlign="center"
          justifySelf="center"
        >
          PRN
          <br />
          Reason
        </chakra.span>
        <chakra.span
          {...headerItemsStyles}
          textAlign="center"
          justifySelf="center"
        >
          Order <br /> Notes
        </chakra.span>
        <chakra.span
          {...headerItemsStyles}
          textAlign="center"
          justifySelf="center"
        >
          Cancel
          <br />
          Order
        </chakra.span>
        {isAdmin && (
          <chakra.span
            {...headerItemsStyles}
            textAlign="center"
            justifySelf="center"
          >
            Delete
          </chakra.span>
        )}
      </Grid>
    </Card>
  );
}

type OrderHistoryListItemCellProps = {
  encounterId: string;
  order: OrderHistoryItem;
  isDisabled?: boolean;
  onOpenOrderReportModal: (
    orderId: string,
    orderType?: OrderFormElementType
  ) => void;
};

function OrderHistoryListItemCell(props: OrderHistoryListItemCellProps) {
  const { order, encounterId, onOpenOrderReportModal, isDisabled } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  const getOrderHistoryItemProps = (
    status?: OrderStatus
  ): { onItemClick?: () => void } => {
    if (status === "NeedsApproval") {
      return {
        onItemClick: () => onOpen(),
      };
    }
    return {};
  };

  const { isPastEncounter } = useChartTrackingContext();

  return (
    <>
      <OrderCard
        order={order}
        key={order.orderId}
        {...(!!order.status && {
          icon: ORDER_STATUS_ICON[order.status],
        })}
        encounterId={encounterId}
        onOpenHistoryReport={onOpenOrderReportModal}
        isReadOnly={isPastEncounter || isDisabled}
        {...getOrderHistoryItemProps(order.status)}
      />
      {order.status === "NeedsApproval" && (
        <ScribeOrderActionsModal
          isModalOpen={isOpen}
          onModalClose={onClose}
          order={order}
          encounterId={encounterId}
        />
      )}
    </>
  );
}

type OrderHistoryFiltersProps = {
  filterSelected?: OrderFormElementType;
  onSelect: (filter: OrderFormElementType | undefined) => void;
};

function OrderHistoryFilters({
  filterSelected,
  onSelect,
}: OrderHistoryFiltersProps) {
  const filters = enumMapper.getOptions("orderFormElementType");

  return (
    <Box w="100%" display="flex" justifyContent="space-between" gap={4}>
      {filters.map((filter) => (
        <Button
          key={filter.value}
          width="100%"
          borderRadius="10px"
          color="gray.650"
          bgColor="gray.250"
          _hover={{ color: "gray.650", bgColor: "gray.250" }}
          aria-selected={filter.value === filterSelected}
          _selected={{ color: "white", bgColor: "blue" }}
          onClick={() =>
            onSelect(filter.value === filterSelected ? undefined : filter.value)
          }
        >
          {filter.label}
        </Button>
      ))}
    </Box>
  );
}

function OrderHistory(props: SharedChartBaseProps) {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();

  const { nextPage, previousPage, setTotalPages, state, gotoPage } =
    usePagination({
      initialState: {
        page: 0,
        totalPages: 0,
        size: 8,
        globalSearch: "",
      },
    });
  const { page, size } = state;
  const toast = useToast();

  const {
    isOpen: isOpenOrderHistoryReport,
    onClose: onCloseOrderHistoryReport,
    onOpen: onOpenOrderHistoryReport,
  } = useDisclosure();

  const [orderReportId, setOrderReportId] = React.useState("");
  const [orderReportType, setOrderReportType] = React.useState<
    OrderFormElementType | undefined
  >();
  const [filterSelected, setFilterSelected] = React.useState<
    OrderFormElementType | undefined
  >();

  const handleFilterSelection = (filter: OrderFormElementType | undefined) => {
    setFilterSelected(filter);
    gotoPage(0);
  };

  const location = useLocation();

  const fromSummary = location.pathname.includes("medical-records");

  const {
    data,
    isLoading: isListLoading,
    isFetching,
    error,
  } = useOrderHistoryPagination(
    {
      page: page + 1,
      size: fromSummary ? 50 : size,
      encounterId,
      elementType: filterSelected,
    },
    { enabled: true }
  );

  const isLoading = isListLoading || isFetching;
  const noResults = !isLoading && data && data.data.content.length < 1;
  const { chartType, isReadOnly } = props;

  React.useEffect(() => {
    setTotalPages(data?.data.totalPages || 0);
  }, [data?.data.totalPages, setTotalPages]);

  React.useEffect(() => {
    if (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [error, toast]);

  return (
    <ChartSection
      justifyContent="space-between"
      display="flex"
      flexDir="column"
      height="100%"
    >
      <Box display="flex" gap="20px" flexDir="column">
        <SharedChartSectionHeader
          icon={<Inbox />}
          encounterId={encounterId}
          sectionId="SOHI"
          variant="default"
          showNotesBtn={chartType === "Triage" ? false : undefined}
          isDisabled={isReadOnly}
        >
          Order History {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>
        <OrderHistoryFilters
          filterSelected={filterSelected}
          onSelect={handleFilterSelection}
        />

        <Box overflowY="auto" maxH="90%">
          <Flex justifyContent="center" padding="12px">
            <PaginationControl
              currentPage={page + 1}
              onClickNext={nextPage}
              onClickPrevious={previousPage}
              pagesCount={data?.data.totalPages || 0}
              centered
            />
          </Flex>
          <Box>
            <OrderCardHeader mb="15px" />
            {isLoading && <Loading p="12px 0" />}
            {noResults && (
              <Box width="100%">
                <Box
                  textAlign="center"
                  fontSize="1.65rem"
                  fontWeight="bold"
                  color="gray.650"
                >
                  No results
                </Box>
              </Box>
            )}
            {(data?.data.content || []).map((order) => (
              <OrderHistoryListItemCell
                order={order}
                key={order.id}
                encounterId={encounterId}
                onOpenOrderReportModal={(
                  orderId: string,
                  orderType?: OrderFormElementType
                ) => {
                  setOrderReportId(orderId);
                  setOrderReportType(orderType);
                  onOpenOrderHistoryReport();
                }}
                isDisabled={isReadOnly}
              />
            ))}
          </Box>

          <OrderHistoryReportFileModal
            isOpen={isOpenOrderHistoryReport}
            onClose={() => {
              setOrderReportId("");
              setOrderReportType(undefined);
              onCloseOrderHistoryReport();
            }}
            orderId={orderReportId}
            orderType={orderReportType}
          />
        </Box>
      </Box>

      <OrderProviderAuthorization
        encounterId={encounterId}
        isDisabled={isReadOnly}
      />
    </ChartSection>
  );
}

type OrderReportProps = {
  orderId: string;
  orderType?: OrderFormElementType;
};

type OrderReportFileModalProps = Pick<
  UseDisclosureReturn,
  "isOpen" | "onClose"
> &
  OrderReportProps;

function OrderHistoryReportFileModal(props: OrderReportFileModalProps) {
  const { orderId, orderType, ...modalProps } = props;
  const { onClose } = modalProps;
  const [file, setFile] = React.useState<Nullable<string | File | Blob>>(null);
  const [numPages, setNumPages] = React.useState(0);
  const [activePageNumber, setActivePageNumber] = React.useState(1);
  const { data } = useEncounterOrderFile(orderId, { enabled: orderId !== "" });

  React.useEffect(() => {
    if (data) {
      setFile(data.data);
    }
  }, [data]);

  React.useEffect(() => {
    if (orderId === "") {
      setFile(null);
      setNumPages(0);
      setActivePageNumber(1);
    }
  }, [orderId]);

  return (
    <Modal {...modalProps}>
      <ModalOverlay bg="none" />
      <ModalContent
        width="calc(100% - 320px)"
        marginLeft="320px"
        height="100%"
        padding="0"
        maxWidth="unset"
        marginTop="0"
        marginBottom="0"
      >
        <PdfViewer
          value={file}
          numPages={numPages}
          setNumPages={setNumPages}
          activePageNumber={activePageNumber}
          setActivePageNumber={setActivePageNumber}
        >
          <PdfViewerContainer
            height="100%"
            border="none"
            activePage={<PdfViewerActivePage pageWidth={1200} />}
            header={
              <PdfViewerHeader
                title={`${orderType ?? "Order"} Report`}
                position="relative"
                leftElements={[
                  <BackButton key="back-button" onClick={onClose}>
                    Back
                  </BackButton>,
                ]}
                rightElements={[
                  <PdfViewerActions
                    key="viewer-actions"
                    showDelete={false}
                    showSubmit={false}
                  />,
                ]}
              />
            }
            preview={<PdfViewerPreview />}
          />
        </PdfViewer>
      </ModalContent>
    </Modal>
  );
}

export { OrderHistory };
