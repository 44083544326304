import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const PRNLabel = createIcon({
  path: (
    <>
      <rect
        x="1"
        y="1"
        width="47"
        height="26"
        rx="3"
        stroke="currentColor"
        stroke-width="2"
      />

      <path
        d="M10.5264 19V9.13574H14.4639C16.4668 9.13574 17.8203 10.4551 17.8203 12.4307V12.4443C17.8203 14.4131 16.4668 15.7393 14.4639 15.7393H12.29V19H10.5264ZM14.0332 10.5781H12.29V14.3174H14.0332C15.2979 14.3174 16.0361 13.6338 16.0361 12.4512V12.4375C16.0361 11.2549 15.2979 10.5781 14.0332 10.5781ZM19.7686 19V9.13574H23.7539C25.873 9.13574 27.1445 10.3184 27.1445 12.1982V12.2119C27.1445 13.5176 26.4541 14.5977 25.2852 15.0215L27.4385 19H25.4219L23.4873 15.2881H21.5322V19H19.7686ZM21.5322 13.9346H23.5693C24.6836 13.9346 25.333 13.3125 25.333 12.2529V12.2393C25.333 11.207 24.6562 10.5645 23.5352 10.5645H21.5322V13.9346ZM29.2842 19V9.13574H30.8154L35.6621 15.9307H35.7715V9.13574H37.4668V19H35.9424L31.0957 12.2051H30.9863V19H29.2842Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: "0 0 49 28",
});

export { PRNLabel };
