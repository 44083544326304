import { Icon } from "@chakra-ui/react";
import { EmrHpi } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import {
  ChartRow,
  ChartRowElement,
  ChartRowElementImportedProps,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";

const sectionId = "SHPI";
const chartCode = "C074x";

function T74HPI() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "HPI",
    chartCode,
  });

  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:hpi").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  const { getValues } = useFormContext;

  const chartFavoriteEditMode: Record<
    "imported",
    ChartRowElementImportedProps
  > | null = getValues("chartFavoriteEditMode")
    ? {
        imported: {
          type: "nursing",
        },
      }
    : null;

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrHpi} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          HPI {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="sore throat"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                />
                <ChartRowElement
                  label="toothache"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                />
                <ChartRowElement
                  label="jaw pain"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="swelling of jaw"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="swelling of face"
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="foreign body"
                  name={`Q001.${chartCode}${sectionId}Q001A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A007`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  name={`Q001.${chartCode}${sectionId}Q001A008`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A009`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Onset Duration"
            >
              Onset Duration
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="just PTA"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                />
                <ChartRowElement
                  label="today"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                />
                <ChartRowElement
                  label="yesterday"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  type="input"
                  width="12.4rem"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="minutes ago"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hours ago"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="days ago"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="gradual onset"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="sudden onset"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Context"
            >
              Context
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="foreign body"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                />
                <ChartRowElement
                  label="ingestion"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                />
                <ChartRowElement
                  label="fractured tooth"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Associated Symptoms"
            >
              Associated Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="fever"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                />
                <ChartRowElement
                  label="chills"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                />
                <ChartRowElement
                  label="runny nose"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="congestion"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="cough"
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="swollen glands"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="toothache"
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="facial pain"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="sore throat:"
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="mild"
                  fontStyle="italic"
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="moderate"
                  fontStyle="italic"
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="severe"
                  fontStyle="italic"
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="unable to swallow"
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="painful swallowing"
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A016`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="earache"
                  name={`Q004.${chartCode}${sectionId}Q004A017`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A018`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="R"
                  name={`Q004.${chartCode}${sectionId}Q004A019`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A020`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="jaw pain"
                  name={`Q004.${chartCode}${sectionId}Q004A021`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="L"
                  name={`Q004.${chartCode}${sectionId}Q004A022`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="R"
                  name={`Q004.${chartCode}${sectionId}Q004A023`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A024`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="swollen jaw"
                  name={`Q004.${chartCode}${sectionId}Q004A025`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="swollen face"
                  name={`Q004.${chartCode}${sectionId}Q004A026`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="mild"
                  fontStyle="italic"
                  name={`Q004.${chartCode}${sectionId}Q004A027`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="moderate"
                  fontStyle="italic"
                  name={`Q004.${chartCode}${sectionId}Q004A028`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="severe"
                  fontStyle="italic"
                  name={`Q004.${chartCode}${sectionId}Q004A029`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A030`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  name={`Q004.${chartCode}${sectionId}Q004A031`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A032`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Worsened by"
            >
              Worsened by
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="heat"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                />
                <ChartRowElement
                  label="cold"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                />
                <ChartRowElement
                  label="chewing"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                />
                <ChartRowElement
                  label="swallowing"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                />
                <ChartRowElement
                  label="nothing"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="Relieved by"
            >
              Relieved by
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="ibuprofen"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q006.${chartCode}${sectionId}Q006A001`}
                />
                <ChartRowElement
                  label="nothing"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Recently"
            >
              Recently
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="similar symptoms previously"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q007.${chartCode}${sectionId}Q007A001`}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A002`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="recently:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q007.${chartCode}${sectionId}Q007A003`}
                />
                <ChartRowElement
                  label="seen by doctor"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q007.${chartCode}${sectionId}Q007A004`}
                />
                <ChartRowElement
                  label="treated by doctor"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q007.${chartCode}${sectionId}Q007A005`}
                />
                <ChartRowElement
                  label="seen by dentist"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q007.${chartCode}${sectionId}Q007A006`}
                />
                <ChartRowElement
                  label="treated by dentist"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q007.${chartCode}${sectionId}Q007A007`}
                />
                <ChartRowElement
                  label="PCP sent to ER"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q007.${chartCode}${sectionId}Q007A008`}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A009`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T74HPI };
