import {
  Button,
  chakra,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import * as React from "react";
import { Card, ToolbarHeader } from "shared";

type OrderNotesModalProps = {
  title: string;
  notes?: string;
  isOpen: boolean;
  onClose: () => void;
};

type OrderNotestModalContentProps = {
  title: string;
  notes: string;
  onModalClose: () => void;
};

function EditOrderNotesModalContent(props: OrderNotestModalContentProps) {
  const { title, notes, onModalClose } = props;

  return (
    <>
      <ModalHeader>
        <ToolbarHeader
          titleText={
            <chakra.span fontSize="1.0625rem" fontWeight="600">
              {title}
            </chakra.span>
          }
          rightButtons={[
            <Button key="cancelBtn" onClick={onModalClose}>
              Close
            </Button>,
          ]}
        />
      </ModalHeader>
      <ModalBody padding="15px 25px 25px" fontSize="1.0625rem">
        <Card variant="default" boxShadow="none">
          <Textarea
            disabled
            value={notes}
            rows={3}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "10px",
              border: "none",
            }}
          />
        </Card>
      </ModalBody>
    </>
  );
}

function OrderNotesModal(props: OrderNotesModalProps) {
  const { isOpen, onClose, title, notes = "" } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="gray.200" maxWidth="542px">
        {isOpen && (
          <EditOrderNotesModalContent
            onModalClose={onClose}
            title={title}
            notes={notes}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { OrderNotesModal };
