import { Icon } from "@chakra-ui/react";
import { EmrRos } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import {
  ChartRow,
  ChartRowElement,
  ChartRowElementImportedProps,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";

const sectionId = "SROS";
const chartCode = "C074x";

function T74ROS() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "ROS",
    chartCode,
  });

  const { watch } = useFormContext;

  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ros").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  const q002AllNormal = Boolean(watch(`Q002.${chartCode}${sectionId}Q002A001`));
  const q003AllNormal = Boolean(watch(`Q003.${chartCode}${sectionId}Q003A001`));
  const q004AllNormal = Boolean(watch(`Q004.${chartCode}${sectionId}Q004A001`));
  const q005AllNormal = Boolean(watch(`Q005.${chartCode}${sectionId}Q005A001`));
  const q006AllNormal = Boolean(watch(`Q006.${chartCode}${sectionId}Q006A001`));
  const q007AllNormal = Boolean(watch(`Q007.${chartCode}${sectionId}Q007A001`));
  const q008AllNormal = Boolean(watch(`Q008.${chartCode}${sectionId}Q008A001`));
  const q009AllNormal = Boolean(watch(`Q009.${chartCode}${sectionId}Q009A001`));
  const q010AllNormal = Boolean(watch(`Q010.${chartCode}${sectionId}Q010A001`));
  const q011AllNormal = Boolean(watch(`Q011.${chartCode}${sectionId}Q011A001`));
  const q012AllNormal = Boolean(watch(`Q012.${chartCode}${sectionId}Q012A001`));

  const { getValues } = useFormContext;

  const chartFavoriteEditMode: Record<
    "imported",
    ChartRowElementImportedProps
  > | null = getValues("chartFavoriteEditMode")
    ? {
        imported: {
          type: "nursing",
        },
      }
    : null;

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrRos} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          ROS {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="checkbox"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  ml="10px"
                />
                <ChartRowElement
                  label="Except as marked positive, all systems listed were reviewed and found negative."
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  color="black"
                  fontWeight="600"
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="CONST"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q002.${chartCode}${sectionId}Q002A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              CONST
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="recent illness"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q002AllNormal}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q002AllNormal}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  isDisabled={q002AllNormal}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q002AllNormal}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="EYES"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q003.${chartCode}${sectionId}Q003A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              EYES
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="eye problems"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q003AllNormal}
                />
                <ChartRowElement
                  label="redness"
                  fontStyle="italic"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q003AllNormal}
                />
                <ChartRowElement
                  label="itching"
                  fontStyle="italic"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q003AllNormal}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q003AllNormal}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  isDisabled={q003AllNormal}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q003AllNormal}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="NEURO"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q004.${chartCode}${sectionId}Q004A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              NEURO
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="headache"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q004AllNormal}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q004AllNormal}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  isDisabled={q004AllNormal}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q004AllNormal}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="GI"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q005.${chartCode}${sectionId}Q005A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              GI
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="nausea"
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q005AllNormal}
                />
                <ChartRowElement
                  label="vomiting"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q005AllNormal}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q005AllNormal}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q005AllNormal}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q005AllNormal}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="GU"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q006.${chartCode}${sectionId}Q006A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="problems urinating"
                  name={`Q006.${chartCode}${sectionId}Q006A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q006AllNormal}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A003`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q006AllNormal}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  name={`Q006.${chartCode}${sectionId}Q006A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q006AllNormal}
                />
                <ChartRowElement
                  name={`Q006.${chartCode}${sectionId}Q006A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q006AllNormal}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="RESP"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q007.${chartCode}${sectionId}Q007A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              RESP
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="shortness of breath"
                  name={`Q007.${chartCode}${sectionId}Q007A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q007AllNormal}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A003`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q007AllNormal}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  name={`Q007.${chartCode}${sectionId}Q007A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q007AllNormal}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q007AllNormal}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="CVS"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q008.${chartCode}${sectionId}Q008A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="chest pain"
                  name={`Q008.${chartCode}${sectionId}Q008A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q008AllNormal}
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A003`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q008AllNormal}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  name={`Q008.${chartCode}${sectionId}Q008A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q008AllNormal}
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q008AllNormal}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="MS"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q009.${chartCode}${sectionId}Q009A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              MS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="muscle aches"
                  name={`Q009.${chartCode}${sectionId}Q009A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q009AllNormal}
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A003`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q009AllNormal}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  name={`Q009.${chartCode}${sectionId}Q009A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q009AllNormal}
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q009AllNormal}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              subsectionLabel="SKIN"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q010.${chartCode}${sectionId}Q010A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              SKIN
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="rash"
                  name={`Q010.${chartCode}${sectionId}Q010A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q010AllNormal}
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A003`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q010AllNormal}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  name={`Q010.${chartCode}${sectionId}Q010A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q010AllNormal}
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q010AllNormal}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              subsectionLabel="LYMPH"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q011.${chartCode}${sectionId}Q011A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              LYMPH
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="ankle swelling"
                  name={`Q011.${chartCode}${sectionId}Q011A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q011AllNormal}
                />
                <ChartRowElement
                  label="L"
                  name={`Q011.${chartCode}${sectionId}Q011A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q011AllNormal}
                />
                <ChartRowElement
                  label="R"
                  name={`Q011.${chartCode}${sectionId}Q011A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q011AllNormal}
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q011AllNormal}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  name={`Q011.${chartCode}${sectionId}Q011A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q011AllNormal}
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A007`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q011AllNormal}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q012"
              subsectionLabel="PSYCH"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q012.${chartCode}${sectionId}Q012A001`}
                    w="93px"
                  >
                    all normal
                  </ChartRowElement>
                </>
              }
            >
              PSYCH
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="anxiety"
                  name={`Q012.${chartCode}${sectionId}Q012A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q012AllNormal}
                />
                <ChartRowElement
                  label="depression"
                  name={`Q012.${chartCode}${sectionId}Q012A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q012AllNormal}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A004`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q012AllNormal}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  name={`Q012.${chartCode}${sectionId}Q012A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  isDisabled={q012AllNormal}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A006`}
                  type="input"
                  {...chartFavoriteEditMode}
                  isDisabled={q012AllNormal}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q013"
              subsectionLabel="Additional Findings"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q013.${chartCode}${sectionId}Q013A001`}
                    w="60px"
                  >
                    none
                  </ChartRowElement>
                </>
              }
              isRequired={false}
            >
              Additional Findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q013.${chartCode}${sectionId}Q013A002`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T74ROS };
