import * as React from "react";
import {
  useController,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { useMergeRefs } from "@chakra-ui/react";
import { ChartOption, ChartOptionProps } from "shared";
import { useDivideControlProps } from "./hooks";

type FormChartOptionProps<TFieldValues extends FieldValues> =
  UseControllerProps<TFieldValues> & ChartOptionProps;

function FormChartOptionInner<TFieldValues extends FieldValues>(
  props: FormChartOptionProps<TFieldValues>,
  ref?: React.ForwardedRef<HTMLButtonElement>
) {
  const { onChange: onChangeProp, ...restProps } = props;
  const [chartOptionProps, controllerProps] = useDivideControlProps<
    TFieldValues,
    FormChartOptionProps<TFieldValues>
  >(restProps);

  const {
    field: { ref: fieldRef, onChange, ...restField },
  } = useController(controllerProps);

  const chartOptionRef = useMergeRefs(ref, fieldRef);

  const handleChange: ChartOptionProps["onChange"] = (nextValue) => {
    onChangeProp?.(nextValue);
    onChange(nextValue);
  };

  return (
    <ChartOption
      ref={chartOptionRef}
      onChange={handleChange}
      whiteSpace="nowrap"
      {...chartOptionProps}
      {...restField}
    />
  );
}

const FormChartOption = React.forwardRef(FormChartOptionInner) as <
  TFieldValues extends FieldValues
>(
  props: FormChartOptionProps<TFieldValues> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  }
) => ReturnType<typeof FormChartOptionInner>;

export { FormChartOption };
export type { FormChartOptionProps };
