import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const PersonSearch = createIcon({
  path: (
    <path
      d="M11.1254 17.7491C10.6529 17.7491 10.189 17.7062 9.74227 17.6203C9.73368 17.1306 9.5189 16.6065 9.14089 16.2285L7.90378 14.9742C8.09278 14.4759 8.20447 13.9261 8.20447 13.3677C8.20447 13.0155 8.1701 12.6804 8.09278 12.354C8.95189 12.0361 9.98282 11.8385 11.1254 11.8385C13.5739 11.8385 15.4639 12.732 16.3488 13.7113C17.5344 12.4485 18.256 10.7474 18.256 8.87457C18.256 4.93127 15.0687 1.74399 11.1254 1.74399C7.23368 1.74399 4.10653 4.85395 4.01203 8.70275C3.43643 8.61684 2.85223 8.65979 2.25945 8.82302C2.26804 4.01203 6.19416 0 11.1254 0C16.0309 0 20 3.97766 20 8.87457C20 13.7715 16.0309 17.7491 11.1254 17.7491ZM11.1254 10.4296C9.46735 10.4124 8.17869 9.02062 8.1701 7.17354C8.16151 5.43814 9.47595 3.99485 11.1254 3.99485C12.7835 3.99485 14.0893 5.43814 14.0893 7.17354C14.0893 9.02062 12.7921 10.4381 11.1254 10.4296ZM3.49656 16.8643C1.58076 16.8643 0 15.2921 0 13.3677C0 11.4519 1.58076 9.87113 3.49656 9.87113C5.41237 9.87113 6.99313 11.4519 6.99313 13.3677C6.99313 14.055 6.78694 14.6993 6.4433 15.2406L8.45361 17.2595C8.60825 17.4141 8.70275 17.6203 8.70275 17.8265C8.70275 18.299 8.37629 18.6426 7.92096 18.6426C7.67182 18.6426 7.46564 18.5481 7.28522 18.3591L5.28351 16.3746C4.75945 16.6838 4.14948 16.8643 3.49656 16.8643ZM3.49656 15.61C4.72509 15.61 5.73024 14.5962 5.73024 13.3591C5.73024 12.1478 4.72509 11.134 3.49656 11.134C2.27663 11.134 1.26289 12.1478 1.26289 13.3591C1.26289 14.5962 2.27663 15.61 3.49656 15.61Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 -1 20 20",
});

export { PersonSearch };
