import React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrHpi } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import {
  ChartRow,
  ChartRowElement,
  ChartRowElementImportedProps,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";

const sectionId = "SHPI";
const chartCode = "C073x";

function T73HPI() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onRouteChangeSave,
    onSaveAndNext,
  } = useChartForm({
    sectionId,
    sectionLabel: "HPI",
    chartCode,
  });

  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:hpi").isEditable;
  const { getValues, watch, setValue } = useFormContext;

  React.useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (!name) return;
      const fields = [
        `Q009.${chartCode}${sectionId}Q009A004`,
        `Q009.${chartCode}${sectionId}Q009A005`,
        `Q009.${chartCode}${sectionId}Q009A006`,
        `Q009.${chartCode}${sectionId}Q009A007`,
        `Q009.${chartCode}${sectionId}Q009A008`,
        `Q009.${chartCode}${sectionId}Q009A009`,
        `Q009.${chartCode}${sectionId}Q009A010`,
        `Q009.${chartCode}${sectionId}Q009A011`,
        `Q009.${chartCode}${sectionId}Q009A012`,
        `Q009.${chartCode}${sectionId}Q009A013`,
      ];

      if (fields.includes(name)) {
        const value = getValues(name) as unknown as string | undefined;
        if (value === "Positive" || value === "Negative") {
          fields.forEach((code) => {
            if (code !== name) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setValue(code, "");
            }
          });
        }
      }
    });

    return () => subscription?.unsubscribe();
  }, [watch, setValue, getValues]);

  if (getIsLoading) {
    return <Loading />;
  }

  const chartFavoriteEditMode: Record<
    "imported",
    ChartRowElementImportedProps
  > | null = getValues("chartFavoriteEditMode")
    ? {
        imported: {
          type: "nursing",
        },
      }
    : null;

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrHpi} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          HPI {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="injury to:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q001.${chartCode}${sectionId}Q001A045`}
                />
                <ChartRowElement
                  label="pain to:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q001.${chartCode}${sectionId}Q001A046`}
                />
                <ChartRowElement
                  label="head"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="neck"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="chest"
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="back"
                  name={`Q001.${chartCode}${sectionId}Q001A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="abdomen"
                  name={`Q001.${chartCode}${sectionId}Q001A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="face"
                  name={`Q001.${chartCode}${sectionId}Q001A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="mouth"
                  name={`Q001.${chartCode}${sectionId}Q001A007`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="lip"
                  name={`Q001.${chartCode}${sectionId}Q001A008`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="chin"
                  name={`Q001.${chartCode}${sectionId}Q001A009`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="nose"
                  name={`Q001.${chartCode}${sectionId}Q001A010`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="forehead"
                  name={`Q001.${chartCode}${sectionId}Q001A011`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A012`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="L"
                  name={`Q001.${chartCode}${sectionId}Q001A013`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  ml="100px"
                />
                <ChartRowElement
                  label="R"
                  name={`Q001.${chartCode}${sectionId}Q001A014`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="collar-bone area"
                  name={`Q001.${chartCode}${sectionId}Q001A015`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="shoulder"
                  name={`Q001.${chartCode}${sectionId}Q001A016`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="arm"
                  name={`Q001.${chartCode}${sectionId}Q001A017`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="elbow"
                  name={`Q001.${chartCode}${sectionId}Q001A018`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="forearm"
                  name={`Q001.${chartCode}${sectionId}Q001A019`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="wrist"
                  name={`Q001.${chartCode}${sectionId}Q001A020`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hand"
                  name={`Q001.${chartCode}${sectionId}Q001A021`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="palm"
                  name={`Q001.${chartCode}${sectionId}Q001A022`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="thumb"
                  name={`Q001.${chartCode}${sectionId}Q001A023`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="index"
                  name={`Q001.${chartCode}${sectionId}Q001A024`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="middle"
                  name={`Q001.${chartCode}${sectionId}Q001A025`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="ring"
                  name={`Q001.${chartCode}${sectionId}Q001A026`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="small"
                  name={`Q001.${chartCode}${sectionId}Q001A027`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A028`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="L"
                  name={`Q001.${chartCode}${sectionId}Q001A029`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  ml="100px"
                />
                <ChartRowElement
                  label="R"
                  name={`Q001.${chartCode}${sectionId}Q001A030`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hip"
                  name={`Q001.${chartCode}${sectionId}Q001A031`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="pelvis"
                  name={`Q001.${chartCode}${sectionId}Q001A032`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q001.${chartCode}${sectionId}Q001A033`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="knee"
                  name={`Q001.${chartCode}${sectionId}Q001A034`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="leg"
                  name={`Q001.${chartCode}${sectionId}Q001A035`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="ankle"
                  name={`Q001.${chartCode}${sectionId}Q001A036`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="foot"
                  name={`Q001.${chartCode}${sectionId}Q001A037`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A038`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="history of:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q001.${chartCode}${sectionId}Q001A047`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q001.${chartCode}${sectionId}Q001A039`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="chronic"
                  name={`Q001.${chartCode}${sectionId}Q001A040`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="recurrent"
                  name={`Q001.${chartCode}${sectionId}Q001A041`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="neck pain"
                  name={`Q001.${chartCode}${sectionId}Q001A042`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="back pain"
                  name={`Q001.${chartCode}${sectionId}Q001A043`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q001.${chartCode}${sectionId}Q001A044`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Onset Duration"
            >
              Onset Duration
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="just PTA"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="today"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="yesterday"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  w="50px"
                  type="input"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="minutes ago"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hours ago"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="days ago"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="gradual onset"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="sudden onset"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Timing"
            >
              Timing
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="continues in ED"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="better"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="gone now"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="worse"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="lasted:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A013`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="pain:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A014`}
                />
                <ChartRowElement
                  label="intermittent"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="lasting"
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  w="300px"
                  type="input"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="worse"
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="persistent"
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="since:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A015`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                  w="300px"
                  type="input"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A012`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Recent Injury?"
            >
              Recent Injury?
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="no"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="yes"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="possibly"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Context"
            >
              Context
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="lifting"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="turning"
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="bending"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="fall"
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="near-fall"
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="trauma"
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="direct blow"
                  name={`Q005.${chartCode}${sectionId}Q005A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A008`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="laceration"
                  name={`Q005.${chartCode}${sectionId}Q005A009`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="incision"
                  name={`Q005.${chartCode}${sectionId}Q005A010`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="stab"
                  name={`Q005.${chartCode}${sectionId}Q005A011`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="crush"
                  name={`Q005.${chartCode}${sectionId}Q005A012`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="burn"
                  name={`Q005.${chartCode}${sectionId}Q005A013`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="human bite"
                  name={`Q005.${chartCode}${sectionId}Q005A014`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="animal bite"
                  name={`Q005.${chartCode}${sectionId}Q005A015`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="GSW"
                  name={`Q005.${chartCode}${sectionId}Q005A016`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A017`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="tripped"
                  name={`Q005.${chartCode}${sectionId}Q005A018`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="slipped"
                  name={`Q005.${chartCode}${sectionId}Q005A019`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="lost balance"
                  name={`Q005.${chartCode}${sectionId}Q005A020`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="fainted"
                  name={`Q005.${chartCode}${sectionId}Q005A021`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A022`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="dislocated while raising arm"
                  name={`Q005.${chartCode}${sectionId}Q005A023`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="L"
                  name={`Q005.${chartCode}${sectionId}Q005A024`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="R"
                  name={`Q005.${chartCode}${sectionId}Q005A025`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A026`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="PCP to sent to ER"
                  name={`Q005.${chartCode}${sectionId}Q005A027`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A028`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q005.${chartCode}${sectionId}Q005A029`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A030`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Where"
            >
              Where
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="home"
                  name={`Q007.${chartCode}${sectionId}Q007A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="school"
                  name={`Q007.${chartCode}${sectionId}Q007A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="neighbor's"
                  name={`Q007.${chartCode}${sectionId}Q007A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="park"
                  name={`Q007.${chartCode}${sectionId}Q007A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="work"
                  name={`Q007.${chartCode}${sectionId}Q007A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="street"
                  name={`Q007.${chartCode}${sectionId}Q007A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="nursing home"
                  name={`Q007.${chartCode}${sectionId}Q007A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A008`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="exact location:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q007.${chartCode}${sectionId}Q007A011`}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A009`}
                  w="627.5px"
                  type="input"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="exact activity:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q007.${chartCode}${sectionId}Q007A012`}
                />
                <ChartRowElement
                  name={`Q007.${chartCode}${sectionId}Q007A010`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              subsectionLabel="Other Injuries?"
            >
              Other Injuries?
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="none"
                  name={`Q008.${chartCode}${sectionId}Q008A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="head"
                  name={`Q008.${chartCode}${sectionId}Q008A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="face"
                  name={`Q008.${chartCode}${sectionId}Q008A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="neck"
                  name={`Q008.${chartCode}${sectionId}Q008A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="chest"
                  name={`Q008.${chartCode}${sectionId}Q008A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="abdomen"
                  name={`Q008.${chartCode}${sectionId}Q008A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="extremities"
                  name={`Q008.${chartCode}${sectionId}Q008A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="other"
                  name={`Q008.${chartCode}${sectionId}Q008A008`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q008.${chartCode}${sectionId}Q008A009`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Severity"
            >
              Severity
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="mild"
                  name={`Q009.${chartCode}${sectionId}Q009A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="moderate"
                  name={`Q009.${chartCode}${sectionId}Q009A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="severe"
                  name={`Q009.${chartCode}${sectionId}Q009A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="(1/10):"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q009.${chartCode}${sectionId}Q009A015`}
                />
                <ChartRowElement
                  label="1"
                  name={`Q009.${chartCode}${sectionId}Q009A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="2"
                  name={`Q009.${chartCode}${sectionId}Q009A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="3"
                  name={`Q009.${chartCode}${sectionId}Q009A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="4"
                  name={`Q009.${chartCode}${sectionId}Q009A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="5"
                  name={`Q009.${chartCode}${sectionId}Q009A008`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="6"
                  name={`Q009.${chartCode}${sectionId}Q009A009`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="7"
                  name={`Q009.${chartCode}${sectionId}Q009A010`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="8"
                  name={`Q009.${chartCode}${sectionId}Q009A011`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="9"
                  name={`Q009.${chartCode}${sectionId}Q009A012`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="10"
                  name={`Q009.${chartCode}${sectionId}Q009A013`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q009.${chartCode}${sectionId}Q009A014`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              subsectionLabel="Quality"
            >
              Quality
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="burning"
                  name={`Q010.${chartCode}${sectionId}Q010A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="sharp"
                  name={`Q010.${chartCode}${sectionId}Q010A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="dull"
                  name={`Q010.${chartCode}${sectionId}Q010A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="aching"
                  name={`Q010.${chartCode}${sectionId}Q010A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="similar to prior back pain(s)"
                  name={`Q010.${chartCode}${sectionId}Q010A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q010.${chartCode}${sectionId}Q010A006`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              subsectionLabel="Location"
            >
              Location
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="abdomen:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q011.${chartCode}${sectionId}Q011A058`}
                />
                <ChartRowElement
                  label="generalized"
                  name={`Q011.${chartCode}${sectionId}Q011A001`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="LUQ"
                  name={`Q011.${chartCode}${sectionId}Q011A002`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="RUQ"
                  name={`Q011.${chartCode}${sectionId}Q011A003`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="LLQ"
                  name={`Q011.${chartCode}${sectionId}Q011A004`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="RLQ"
                  name={`Q011.${chartCode}${sectionId}Q011A005`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A006`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="L"
                  name={`Q011.${chartCode}${sectionId}Q011A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="collar-bone area"
                  name={`Q011.${chartCode}${sectionId}Q011A008`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="shoulder"
                  name={`Q011.${chartCode}${sectionId}Q011A009`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="arm"
                  name={`Q011.${chartCode}${sectionId}Q011A010`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="elbow"
                  name={`Q011.${chartCode}${sectionId}Q011A011`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="forearm"
                  name={`Q011.${chartCode}${sectionId}Q011A012`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="wrist"
                  name={`Q011.${chartCode}${sectionId}Q011A013`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hand"
                  name={`Q011.${chartCode}${sectionId}Q011A014`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="fingers"
                  name={`Q011.${chartCode}${sectionId}Q011A015`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q011.${chartCode}${sectionId}Q011A016`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="knee"
                  name={`Q011.${chartCode}${sectionId}Q011A017`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="leg"
                  name={`Q011.${chartCode}${sectionId}Q011A018`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="ankle"
                  name={`Q011.${chartCode}${sectionId}Q011A019`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="foot"
                  name={`Q011.${chartCode}${sectionId}Q011A020`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="toe(s)"
                  name={`Q011.${chartCode}${sectionId}Q011A021`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A022`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="R"
                  name={`Q011.${chartCode}${sectionId}Q011A023`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="collar-bone area"
                  name={`Q011.${chartCode}${sectionId}Q011A024`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="shoulder"
                  name={`Q011.${chartCode}${sectionId}Q011A025`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="arm"
                  name={`Q011.${chartCode}${sectionId}Q011A026`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="elbow"
                  name={`Q011.${chartCode}${sectionId}Q011A027`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="forearm"
                  name={`Q011.${chartCode}${sectionId}Q011A028`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="wrist"
                  name={`Q011.${chartCode}${sectionId}Q011A029`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hand"
                  name={`Q011.${chartCode}${sectionId}Q011A030`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="fingers"
                  name={`Q011.${chartCode}${sectionId}Q011A031`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q011.${chartCode}${sectionId}Q011A032`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="knee"
                  name={`Q011.${chartCode}${sectionId}Q011A033`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="leg"
                  name={`Q011.${chartCode}${sectionId}Q011A034`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="ankle"
                  name={`Q011.${chartCode}${sectionId}Q011A035`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="foot"
                  name={`Q011.${chartCode}${sectionId}Q011A036`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="toe(s)"
                  name={`Q011.${chartCode}${sectionId}Q011A037`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A038`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="head"
                  name={`Q011.${chartCode}${sectionId}Q011A039`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="neck"
                  name={`Q011.${chartCode}${sectionId}Q011A040`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="chest"
                  name={`Q011.${chartCode}${sectionId}Q011A041`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="face"
                  name={`Q011.${chartCode}${sectionId}Q011A042`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="mouth"
                  name={`Q011.${chartCode}${sectionId}Q011A043`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="lip"
                  name={`Q011.${chartCode}${sectionId}Q011A044`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="chin"
                  name={`Q011.${chartCode}${sectionId}Q011A045`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="nose"
                  name={`Q011.${chartCode}${sectionId}Q011A046`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="forehead"
                  name={`Q011.${chartCode}${sectionId}Q011A047`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A048`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="back:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q011.${chartCode}${sectionId}Q011A059`}
                />
                <ChartRowElement
                  label="upper"
                  name={`Q011.${chartCode}${sectionId}Q011A049`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="mid"
                  name={`Q011.${chartCode}${sectionId}Q011A050`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="lower"
                  name={`Q011.${chartCode}${sectionId}Q011A051`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A052`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="radiating to:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q011.${chartCode}${sectionId}Q011A060`}
                />
                <ChartRowElement
                  label="R"
                  name={`Q011.${chartCode}${sectionId}Q011A053`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="L"
                  name={`Q011.${chartCode}${sectionId}Q011A054`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="thigh"
                  name={`Q011.${chartCode}${sectionId}Q011A055`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="leg"
                  name={`Q011.${chartCode}${sectionId}Q011A056`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q011.${chartCode}${sectionId}Q011A057`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q012"
              subsectionLabel="Associated Symptoms"
            >
              Associated Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="fever"
                  name={`Q012.${chartCode}${sectionId}Q012A014`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="chills"
                  name={`Q012.${chartCode}${sectionId}Q012A015`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="sweating"
                  name={`Q012.${chartCode}${sectionId}Q012A016`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="headache"
                  name={`Q012.${chartCode}${sectionId}Q012A017`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="light-headedness"
                  name={`Q012.${chartCode}${sectionId}Q012A003`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="chest pain"
                  name={`Q012.${chartCode}${sectionId}Q012A018`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="rapid heart rate"
                  name={`Q012.${chartCode}${sectionId}Q012A004`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="neck pain"
                  name={`Q012.${chartCode}${sectionId}Q012A019`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A020`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="nausea"
                  name={`Q012.${chartCode}${sectionId}Q012A083`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="vomiting"
                  name={`Q012.${chartCode}${sectionId}Q012A084`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="diarrhea"
                  name={`Q012.${chartCode}${sectionId}Q012A008`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="constipation"
                  name={`Q012.${chartCode}${sectionId}Q012A021`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="incontinence"
                  name={`Q012.${chartCode}${sectionId}Q012A022`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="bowel problem"
                  name={`Q012.${chartCode}${sectionId}Q012A023`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="bladder problem"
                  name={`Q012.${chartCode}${sectionId}Q012A024`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A025`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="weakness"
                  name={`Q012.${chartCode}${sectionId}Q012A026`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="bruising"
                  name={`Q012.${chartCode}${sectionId}Q012A030`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="loss feeling"
                  name={`Q012.${chartCode}${sectionId}Q012A034`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="loss power"
                  name={`Q012.${chartCode}${sectionId}Q012A035`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="L"
                  name={`Q012.${chartCode}${sectionId}Q012A027`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="R"
                  name={`Q012.${chartCode}${sectionId}Q012A028`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A029`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="unable to move shoulder"
                  name={`Q012.${chartCode}${sectionId}Q012A040`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="L"
                  name={`Q012.${chartCode}${sectionId}Q012A041`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="R"
                  name={`Q012.${chartCode}${sectionId}Q012A042`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A043`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="unable to bear weight"
                  name={`Q012.${chartCode}${sectionId}Q012A044`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="L"
                  name={`Q012.${chartCode}${sectionId}Q012A045`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="R"
                  name={`Q012.${chartCode}${sectionId}Q012A046`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A047`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="snapping sensation"
                  name={`Q012.${chartCode}${sectionId}Q012A048`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="popping sensation"
                  name={`Q012.${chartCode}${sectionId}Q012A049`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="L"
                  name={`Q012.${chartCode}${sectionId}Q012A050`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="R"
                  name={`Q012.${chartCode}${sectionId}Q012A051`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A052`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="tingling"
                  name={`Q012.${chartCode}${sectionId}Q012A053`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="numbness"
                  name={`Q012.${chartCode}${sectionId}Q012A054`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="sensory loss"
                  name={`Q012.${chartCode}${sectionId}Q012A055`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="motor loss"
                  name={`Q012.${chartCode}${sectionId}Q012A056`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="L"
                  name={`Q012.${chartCode}${sectionId}Q012A057`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="R"
                  name={`Q012.${chartCode}${sectionId}Q012A058`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A059`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="abdominal pain:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q012.${chartCode}${sectionId}Q012A088`}
                />
                <ChartRowElement
                  label="abdominal injury:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q012.${chartCode}${sectionId}Q012A089`}
                />
                <ChartRowElement
                  label="generalized"
                  name={`Q012.${chartCode}${sectionId}Q012A060`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="LUQ"
                  name={`Q012.${chartCode}${sectionId}Q012A061`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="RUQ"
                  name={`Q012.${chartCode}${sectionId}Q012A062`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="LLQ"
                  name={`Q012.${chartCode}${sectionId}Q012A063`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="RLQ"
                  name={`Q012.${chartCode}${sectionId}Q012A064`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A065`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="blow to head"
                  name={`Q012.${chartCode}${sectionId}Q012A066`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="dizziness"
                  name={`Q012.${chartCode}${sectionId}Q012A067`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="fainted"
                  name={`Q012.${chartCode}${sectionId}Q012A068`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="seizure"
                  name={`Q012.${chartCode}${sectionId}Q012A069`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="memory impairment"
                  name={`Q012.${chartCode}${sectionId}Q012A070`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A071`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="lost consciousness:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q012.${chartCode}${sectionId}Q012A090`}
                />
                <ChartRowElement
                  label="yes"
                  name={`Q012.${chartCode}${sectionId}Q012A072`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="no"
                  name={`Q012.${chartCode}${sectionId}Q012A073`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="unknown"
                  name={`Q012.${chartCode}${sectionId}Q012A074`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="duration:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q012.${chartCode}${sectionId}Q012A091`}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A075`}
                  w="50px"
                  type="input"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="seconds"
                  name={`Q012.${chartCode}${sectionId}Q012A076`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="minutes"
                  name={`Q012.${chartCode}${sectionId}Q012A077`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hours"
                  name={`Q012.${chartCode}${sectionId}Q012A078`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A079`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="remembers:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q012.${chartCode}${sectionId}Q012A092`}
                />
                <ChartRowElement
                  label="event"
                  name={`Q012.${chartCode}${sectionId}Q012A080`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="coming to hospital"
                  name={`Q012.${chartCode}${sectionId}Q012A081`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A082`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q012.${chartCode}${sectionId}Q012A093`}
                />
                <ChartRowElement
                  name={`Q012.${chartCode}${sectionId}Q012A086`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q013"
              subsectionLabel="Modifying Factors"
            >
              Modifying Factors
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="none"
                  name={`Q013.${chartCode}${sectionId}Q013A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="pain on movement"
                  name={`Q013.${chartCode}${sectionId}Q013A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q013.${chartCode}${sectionId}Q013A003`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q014"
              subsectionLabel="Worsened by"
            >
              Worsened by
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="nothing"
                  name={`Q014.${chartCode}${sectionId}Q014A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="supine"
                  name={`Q014.${chartCode}${sectionId}Q014A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="upright position"
                  name={`Q014.${chartCode}${sectionId}Q014A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="sitting position"
                  name={`Q014.${chartCode}${sectionId}Q014A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="movement:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q014.${chartCode}${sectionId}Q014A013`}
                />
                <ChartRowElement
                  label="of trunk"
                  name={`Q014.${chartCode}${sectionId}Q014A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="of neck"
                  name={`Q014.${chartCode}${sectionId}Q014A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="to right"
                  name={`Q014.${chartCode}${sectionId}Q014A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="to left"
                  name={`Q014.${chartCode}${sectionId}Q014A008`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="flexion"
                  name={`Q014.${chartCode}${sectionId}Q014A009`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="cough"
                  name={`Q014.${chartCode}${sectionId}Q014A010`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="deep breaths"
                  name={`Q014.${chartCode}${sectionId}Q014A011`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q014.${chartCode}${sectionId}Q014A012`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q015"
              subsectionLabel="Relieved by"
            >
              Relieved by
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="nothing"
                  name={`Q015.${chartCode}${sectionId}Q015A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="supine"
                  name={`Q015.${chartCode}${sectionId}Q015A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="upright position"
                  name={`Q015.${chartCode}${sectionId}Q015A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="activity"
                  name={`Q015.${chartCode}${sectionId}Q015A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="remaining still"
                  name={`Q015.${chartCode}${sectionId}Q015A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="lying on side"
                  name={`Q015.${chartCode}${sectionId}Q015A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="L"
                  name={`Q015.${chartCode}${sectionId}Q015A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="R"
                  name={`Q015.${chartCode}${sectionId}Q015A008`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q015.${chartCode}${sectionId}Q015A009`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q016"
              subsectionLabel="Recently"
            >
              Recently
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="similar symptoms previously"
                  name={`Q016.${chartCode}${sectionId}Q016A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q016.${chartCode}${sectionId}Q016A002`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="recently:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q016.${chartCode}${sectionId}Q016A008`}
                />
                <ChartRowElement
                  label="seen by doctor"
                  name={`Q016.${chartCode}${sectionId}Q016A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="treated by doctor"
                  name={`Q016.${chartCode}${sectionId}Q016A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hospitalized"
                  name={`Q016.${chartCode}${sectionId}Q016A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="PCP sent to ER"
                  name={`Q016.${chartCode}${sectionId}Q016A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q016.${chartCode}${sectionId}Q016A007`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T73HPI };
