import { axiosOrderInstance } from "config";
import {
  OrderFormElementCategory,
  OrderFormElementRoute,
  OrderFormElementType,
} from "enums";
import { useAxiosQuery } from "hooks";

type UseOrderQueueProps = {
  encounterId: string;
};

type OrderQueueItem = {
  id: string;
  orderBy: string;
  description: string;
  notes?: string;
  elementType: OrderFormElementType;
  elementCategory?: OrderFormElementCategory;
  location?: string;
  route?: OrderFormElementRoute;
  administrationTime?: string;
  encounterId: string;
  chartApplicationId: string;
  scheduledTime?: string;
  isObservation?: boolean;
  isScheduled?: boolean;
  isPRN?: boolean;
  prnReason?: string;
  createdAt: Date;
};

function useOrderQueue({ encounterId }: UseOrderQueueProps) {
  return useAxiosQuery(["orderQueue", { encounterId }], () =>
    axiosOrderInstance.get<OrderQueueItem[]>(`v1/queue/${encounterId}`)
  );
}

export type { OrderQueueItem };
export { useOrderQueue };
