import * as React from "react";
import {
  Box,
  Button,
  chakra,
  CSSObject,
  Flex,
  HStack,
  Icon,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { CardProps, Card } from "shared";
import { EmrNotes } from "@medstonetech/slate-icons";
import { ChartNotesModal } from "modules";
import { SharedChartNotesModal } from "../chart-notes/SharedChartNotesModal";
import { useChartSectionNotesCount } from "modules/charts-shared/api/queries/useChartSectionNotesCount";
import { EncounterIntegrationCards } from "modules/reception/components/encounter-integration";
import { useChartTrackingContext } from "contexts";
import { MinusRoundFilled } from "icons";
import { ChartFavorites } from "../chart-favorites";
import { useLocation } from "react-router-dom";

type WithNotes = {
  icon: JSX.Element;
  chartId: string;
  encounterId: string;
  sectionId: string;
  showNotesBtn?: true;
};

type NoNotes = {
  icon: JSX.Element;
  showNotesBtn: false;
  encounterId?: string;
  sectionId?: string;
  chartId?: string;
  showInformationSentButtons?: boolean;
  showFavoritesBtn?: boolean;
};

type ActionBtnsProps = {
  showImportBtn?: boolean;
  showDeleteBtn?: boolean;
  showActionsBtns?: boolean;
  onCancel?: () => void;
  onImport?: () => void;
  onSave?: () => void;
  onSaveAndNext?: () => void;
  onDeleteSection?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  isSticky?: boolean;
};

type ChartSectionHeaderProps = (WithNotes | NoNotes) &
  ActionBtnsProps &
  CardProps;

type ChartSectionHeaderCardProps = {
  icon: JSX.Element;
  showNotesBtn: boolean;
  onOpen?: () => void;
  sectionNotesCount?: number; // number of notes - turn blue icon to indicate if has notes
  sectionBgColor?: string;
  sectionMargin?: string;
  sectionPadding?: string;
  encounterId?: string;
  showInformationSentButtons?: boolean;
  wrapperStyles?: CSSObject;
  showFavoritesBtn?: boolean;
  sectionId?: string;
  chartId?: string;
} & ActionBtnsProps &
  CardProps;

function isPropsNoNotes(props: ChartSectionHeaderProps): props is NoNotes {
  return (props as NoNotes).showNotesBtn === false;
}

function ChartSectionHeaderCard(props: ChartSectionHeaderCardProps) {
  const {
    onOpen,
    showNotesBtn,
    showDeleteBtn,
    showImportBtn = false,
    showActionsBtns,
    onCancel,
    onImport,
    onSave,
    onSaveAndNext,
    isLoading,
    isDisabled,
    icon,
    children,
    sectionNotesCount,
    sectionBgColor = "gray.50",
    sectionMargin = "0rem",
    sectionPadding = "1rem 0 0 0",
    showInformationSentButtons = false,
    encounterId,
    onDeleteSection = () => {},
    wrapperStyles = {},
    isSticky = true,
    showFavoritesBtn,
    chartId,
    sectionId,
    ...cardProps
  } = props;

  const { isPastEncounter } = useChartTrackingContext();

  const location = useLocation();

  const fromSummary = location.pathname.includes("medical-records");

  return (
    <Box
      margin={sectionMargin}
      padding={sectionPadding}
      paddingBottom="0"
      height={showInformationSentButtons ? "6rem" : "4.5rem"}
      marginBottom={
        showInformationSentButtons ? "3.5rem !important" : "2rem !important"
      }
      position={isSticky && !fromSummary ? "sticky" : "relative"}
      top="-1.5rem"
      zIndex={isSticky ? 10 : 9}
      background={sectionBgColor}
      sx={wrapperStyles}
    >
      {encounterId && showInformationSentButtons && (
        <Flex justify="center">
          <EncounterIntegrationCards
            encounterId={encounterId}
            showAYVAPharmacyIntegration
            isDisabled={isDisabled}
          />
        </Flex>
      )}
      <Card
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="1rem 1.25rem"
        variant="default"
        border="none"
        {...cardProps}
      >
        <HStack spacing="0.625rem">
          <Box
            borderRadius="5px"
            bg="blue"
            width="1.875rem"
            height="1.875rem"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {React.cloneElement(icon, {
              color: "white",
              fontSize: "1.25rem",
            })}
          </Box>
          <chakra.h2 fontSize="1.125rem" fontWeight="700">
            {children}

            {showActionsBtns &&
              !isPastEncounter &&
              !fromSummary &&
              showImportBtn && (
                <Button
                  ml={4}
                  variant="action"
                  onClick={onImport}
                  type="submit"
                  colorScheme="blue"
                  height="2rem"
                  isLoading={isLoading}
                  isDisabled={isLoading || isDisabled}
                >
                  Import
                </Button>
              )}
          </chakra.h2>
          {showFavoritesBtn && chartId && sectionId && !fromSummary && (
            <ChartFavorites chartCode={chartId} sectionCode={sectionId} />
          )}
        </HStack>
        <HStack spacing="0.625rem">
          {showNotesBtn && !fromSummary && (
            <IconButton
              aria-label="notes"
              icon={
                <Icon
                  as={EmrNotes}
                  color={
                    sectionNotesCount && sectionNotesCount > 0
                      ? "blue"
                      : "gray.450"
                  }
                  fontSize="1.375rem"
                />
              }
              onClick={onOpen}
              variant="ghost"
              height="2rem"
              width="2rem"
              minWidth="unset"
            />
          )}
          {showDeleteBtn && !fromSummary && (
            <Button
              variant="ghost"
              color="red"
              fontSize="1.0625rem"
              disabled={isPastEncounter}
              rightIcon={
                <Icon
                  borderRadius="1rem"
                  color="red"
                  backgroundColor="white"
                  as={MinusRoundFilled}
                />
              }
              onClick={onDeleteSection}
            >
              Remove
            </Button>
          )}
          {showActionsBtns && !isPastEncounter && !fromSummary && (
            <>
              <Button
                variant="action"
                onClick={onSave}
                type="submit"
                colorScheme="blue"
                height="2rem"
                isLoading={isLoading}
                isDisabled={isLoading || isDisabled}
              >
                Save
              </Button>
              {onSaveAndNext && (
                <Button
                  variant="solid"
                  onClick={onSaveAndNext}
                  colorScheme="blue"
                  height="2rem"
                  isLoading={isLoading}
                  isDisabled={isLoading || isDisabled}
                  sx={{ borderRadius: "5px" }}
                >
                  Save & Next
                </Button>
              )}
            </>
          )}
        </HStack>
      </Card>
    </Box>
  );
}

function ChartSectionHeaderWithNotes(
  props: WithNotes & ActionBtnsProps & CardProps
) {
  const {
    chartId,
    encounterId,
    sectionId,
    icon,
    showNotesBtn = true,
    children,
    isDisabled,
    ...cardProps
  } = props;
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { data } = useChartSectionNotesCount({
    encounterId,
    sectionCode: sectionId,
    chartCode: chartId,
  });

  const sectionNotesCount = data?.data || 0;

  return (
    <>
      <ChartSectionHeaderCard
        onOpen={onOpen}
        showNotesBtn={showNotesBtn}
        icon={icon}
        sectionNotesCount={sectionNotesCount}
        chartId={chartId}
        sectionId={sectionId}
        {...cardProps}
      >
        {children}
      </ChartSectionHeaderCard>
      {isOpen && (
        <ChartNotesModal
          chartId={chartId}
          isOpen={isOpen}
          encounterId={encounterId}
          onClose={onClose}
          sectionId={sectionId}
          title={children}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
}

function ChartSectionHeader(props: ChartSectionHeaderProps) {
  if (isPropsNoNotes(props)) {
    const { encounterId, sectionId, chartId, ...rest } = props;
    return (
      <ChartSectionHeaderCard
        sectionId={sectionId}
        chartId={chartId}
        {...rest}
      />
    );
  }
  return <ChartSectionHeaderWithNotes {...props} />;
}

type SharedWithNotes = {
  icon: JSX.Element;
  encounterId: string;
  sectionId: string;
  chartCode?: string;
  showNotesBtn?: true;
  areNotesShared?: boolean;
  sectionBgColor?: string;
  sectionPadding?: string;
  showFavoritesBtn?: boolean;
};

type SharedChartSectionHeaderProps = (SharedWithNotes | NoNotes) &
  ActionBtnsProps &
  CardProps & { wrapperStyles?: CSSObject };

function SharedChartSectionHeaderWithNotes(
  props: SharedWithNotes & ActionBtnsProps & CardProps
) {
  const {
    encounterId,
    sectionId,
    chartCode,
    icon,
    showNotesBtn = true,
    children,
    sectionBgColor,
    sectionPadding,
    isDisabled,
    showFavoritesBtn,
    ...cardProps
  } = props;
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { data } = useChartSectionNotesCount({
    encounterId,
    sectionCode: sectionId,
  });

  const sectionNotesCount = data?.data || 0;

  return (
    <>
      <ChartSectionHeaderCard
        onOpen={onOpen}
        showNotesBtn={showNotesBtn}
        icon={icon}
        sectionNotesCount={sectionNotesCount}
        sectionBgColor={sectionBgColor}
        sectionPadding={sectionPadding}
        encounterId={encounterId}
        isDisabled={isDisabled}
        sectionId={sectionId}
        chartId={chartCode}
        showFavoritesBtn={showFavoritesBtn}
        {...cardProps}
      >
        {children}
      </ChartSectionHeaderCard>
      {isOpen && (
        <SharedChartNotesModal
          encounterId={encounterId}
          isOpen={isOpen}
          onClose={onClose}
          sectionCode={sectionId}
          title={children}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
}

function isPropsSharedNoNotes(
  props: SharedChartSectionHeaderProps
): props is NoNotes {
  return (props as NoNotes).showNotesBtn === false;
}

function SharedChartSectionHeader(props: SharedChartSectionHeaderProps) {
  if (isPropsSharedNoNotes(props)) {
    const { encounterId, sectionId, ...rest } = props;
    return <ChartSectionHeaderCard encounterId={encounterId} {...rest} />;
  }
  return <SharedChartSectionHeaderWithNotes {...props} />;
}

export type { ChartSectionHeaderProps, NoNotes, ActionBtnsProps };
export { ChartSectionHeader, SharedChartSectionHeader, ChartSectionHeaderCard };
