import { Box, chakra, Icon, useDisclosure, useToast } from "@chakra-ui/react";
import { AddRounded, Edit, LeftArrow } from "icons";
import {
  useChartFavorites,
  useChartSectionInfo,
  useUpdateChartFavorite,
} from "modules/charts-shared/api";
import { ChartFavoriteRequest } from "modules/charts-shared/types";
import { mapFormToPayload } from "modules/charts-shared/utils";
import { useFormContext } from "react-hook-form";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { useParams } from "react-router-dom";
import { Button, WarningDialog } from "shared";
import { extractApiErrorMessage } from "utils";
import { ChartFavoriteCreateModal } from "./ChartFavoriteNameModal";
import { ChartFavoritesModal } from "./ChartFavoritesModal";

type ChartFavoritesProps = {
  chartCode: string;
  sectionCode: string;
};

function ChartFavorites({ chartCode, sectionCode }: ChartFavoritesProps) {
  const toast = useToast();
  const { encounterId = "" } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isAddOpen,
    onClose: onAddClose,
    onOpen: onAddOpen,
  } = useDisclosure();
  const updatedMessageDisclosure = useDisclosure();

  const { getValues, reset, setValue, watch } = useFormContext();

  const { data: sectionInfoData, isLoading: isSectionInfoLoading } =
    useChartSectionInfo({
      encounterId,
      chartCode,
      sectionCode,
    });

  const { data: chartFavoritesData, isLoading } = useChartFavorites({
    sectionCode,
    chartCode,
  });

  const chartFavorites = chartFavoritesData?.data ?? [];

  const sectionInfo = sectionInfoData?.data;
  const chartFavoriteIdForm = getValues("chartFavoriteId");
  const selectedChartFavorite = chartFavorites.find(
    (x) =>
      (chartFavoriteIdForm && x.id === chartFavoriteIdForm) ||
      (!chartFavoriteIdForm && x.id === sectionInfo?.chartFavoriteId)
  );

  const {
    mutateAsync: updateChartFavorite,
    isLoading: isUpdateFavoriteLoading,
  } = useUpdateChartFavorite({
    id: selectedChartFavorite?.id ?? "",
    sectionCode,
  });

  const handleUpdateChartFavorite = async () => {
    try {
      if (selectedChartFavorite) {
        const form = getValues();
        delete form.chartFavoriteEditMode;
        const subsections = mapFormToPayload(form);
        const payload: ChartFavoriteRequest = {
          title: selectedChartFavorite.title,
          sectionCode: selectedChartFavorite.sectionCode,
          chartCode: selectedChartFavorite.chartCode,
          isFavorite: selectedChartFavorite.isFavorite,
          subSections: subsections
            .map((x) => ({
              code: x.code,
              answers: x.answers.map((answer) => ({
                qCode: answer.qCode.slice(5),
                value: answer.value,
              })),
            }))
            .filter(
              (x) =>
                x.code !== "chartFavoriteId" &&
                x.code !== "chartFavoriteEditMode"
            ),
        };
        await updateChartFavorite(payload);
        reset(form);
        updatedMessageDisclosure.onOpen();
      }
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  return (
    <>
      <Button
        variant="outlineSquared"
        height="36px"
        sx={{
          w: "360px",
          display: "flex",
          justifyContent:
            isLoading || isSectionInfoLoading ? "center" : "space-between",
          alignItems: "center",
          color: "black",
          borderColor: "gray.450",
          p: 0,
        }}
        onClick={onOpen}
        isLoading={isLoading || isSectionInfoLoading}
      >
        <Box display="flex" alignItems="center" gap={1} ml={2}>
          <Icon
            as={
              selectedChartFavorite?.isFavorite ? MdFavorite : MdFavoriteBorder
            }
            color={selectedChartFavorite?.isFavorite ? "blue" : "gray"}
            fontSize="24px"
          />
          {selectedChartFavorite?.title
            ? selectedChartFavorite?.title
            : "Favorites"}
        </Box>
        <LeftArrow mr={3} transform="rotate(270deg)" />
      </Button>
      {!watch("chartFavoriteEditMode") && (
        <Button
          variant="outlineSquared"
          onClick={() => setValue("chartFavoriteEditMode", "edit")}
          isLoading={isUpdateFavoriteLoading}
          isDisabled={isUpdateFavoriteLoading || !selectedChartFavorite}
          sx={{
            height: "36px",
            w: "150px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon as={Edit} fontSize="20px" mr={1} />
          Edit Favorite
        </Button>
      )}
      {watch("chartFavoriteEditMode") && (
        <Button
          variant="outlineSquared"
          onClick={handleUpdateChartFavorite}
          isLoading={isUpdateFavoriteLoading}
          isDisabled={isUpdateFavoriteLoading || !selectedChartFavorite}
          sx={{
            height: "36px",
            w: "150px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "blue",
            color: "white",
            _disabled: { backgroundColor: "blue" },
            _hover: { backgroundColor: "blue" },
          }}
        >
          Save Favorite
        </Button>
      )}
      <Button
        variant="outlineSquared"
        onClick={onAddOpen}
        disabled={watch("chartFavoriteEditMode")}
        sx={{
          height: "36px",
          w: "150px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon as={AddRounded} fontSize="20px" mr={1} />
        New Favorite
      </Button>

      {isAddOpen && (
        <ChartFavoriteCreateModal
          isOpen={isAddOpen}
          onClose={onAddClose}
          sectionCode={sectionCode}
          chartCode={chartCode}
        />
      )}

      {isOpen && (
        <ChartFavoritesModal
          isOpen={isOpen}
          onClose={onClose}
          chartFavorites={chartFavorites}
          sectionCode={sectionCode}
          chartFavoriteIdSelected={selectedChartFavorite?.id}
          chartCode={chartCode}
        />
      )}

      <WarningDialog
        {...updatedMessageDisclosure}
        onCancel={updatedMessageDisclosure.onClose}
        onAction={updatedMessageDisclosure.onClose}
        title="Favorite Updated!"
        mainText={
          <chakra.span>
            This Favorite has been successfully updated.
          </chakra.span>
        }
        actionLabel="OK"
        actionButtonProps={{ color: "blue" }}
        onlyActionButton
      />
    </>
  );
}

export { ChartFavorites };
