import { Button, chakra, SimpleGrid, Box, BoxProps } from "@chakra-ui/react";
import { useYupValidationResolver } from "hooks";
import { LabOrderFilters } from "modules/orders/api";
import * as React from "react";
import { NestedValue, useForm } from "react-hook-form";
import {
  Card,
  FormCalendarInput,
  FormControl,
  FormNumberInputMask,
  FormSelect,
  Input,
} from "shared";
import { debounce } from "throttle-debounce";
import { SelectOption } from "types";
import { enumMapper } from "utils";
import { OrderGroup, OrderType } from "enums";
import * as Yup from "yup";
import { getValueByOrderGroup } from "modules/orders/helpers";

type OrdersSearchCardProps = {
  onSearch: (values: LabOrderFilters) => void;
  orderGroup: OrderGroup;
} & BoxProps;

type FormData = {
  firstName: string;
  lastName: string;
  ocurrence: string;
  mrn: string;
  ssn: string;
  dob: Date;
  orderType: NestedValue<SelectOption<OrderType>> | null;
  orderDescription: string;
  orderDate: Date;
  orderId: string;
  orderedBy: string;
};

const initialValues: Partial<FormData> = {
  firstName: "",
  lastName: "",
  ocurrence: "",
  mrn: "",
  ssn: "",
  dob: undefined,
  orderType: null,
  orderDescription: "",
  orderDate: undefined,
  orderId: "",
  orderedBy: "",
};

const schema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  ocurrence: Yup.string(),
  mrn: Yup.string(),
  ssn: Yup.string(),
  dob: Yup.date(),
  orderType: Yup.string(),
  orderDescription: Yup.string(),
  orderDate: Yup.date(),
  orderId: Yup.string(),
  orderedBy: Yup.string(),
});
function OrdersSearchCard(props: OrdersSearchCardProps) {
  const { onSearch, orderGroup, ...boxProps } = props;
  const title = getValueByOrderGroup<string>("Lab Search", "Radiology Search")[
    orderGroup
  ];

  const orderTypeEnum =
    orderGroup === "Radiology" ? "radiologyOrderType" : "labOrderType";

  const selectOrderTypes = enumMapper.getOptions(orderTypeEnum);

  const resolver = useYupValidationResolver(schema);
  const { register, reset, control, watch } = useForm<FormData>({
    resolver,
    defaultValues: initialValues,
  });

  const debounceSearch = React.useMemo(
    () =>
      debounce(1000, (values: LabOrderFilters) => {
        onSearch(values);
      }),
    [onSearch]
  );

  React.useEffect(() => {
    const subscription = watch((values) => {
      debounceSearch({
        ...values,
        orderType: values.orderType?.value,
      });
    });

    return () => subscription.unsubscribe();
  }, [watch, debounceSearch]);

  const handleReset = () => {
    reset(initialValues);
  };

  return (
    <Box {...boxProps}>
      <chakra.h2
        fontWeight="500"
        fontSize="1.5rem"
        lineHeight="1.75rem"
        marginLeft="1rem"
      >
        {title}
      </chakra.h2>

      <Card
        marginTop="0.625rem"
        padding="30px"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1)"
        borderRadius="10px"
      >
        <SimpleGrid spacing="20px" columns={{ base: 1, md: 3, xl: 6 }}>
          <FormControl label="Last Name">
            <Input
              type="text"
              placeholder="Last Name"
              {...register("lastName")}
            />
          </FormControl>
          <FormControl label="First Name">
            <Input
              type="text"
              placeholder="First Name"
              {...register("firstName")}
            />
          </FormControl>
          <FormControl label="Ocurrence #">
            <Input
              type="text"
              placeholder="Ocurrence #"
              {...register("ocurrence")}
            />
          </FormControl>
          <FormControl label="MRN">
            <Input type="text" placeholder="MRN" {...register("mrn")} />
          </FormControl>
          <FormControl label="SSN">
            <FormNumberInputMask
              type="text"
              placeholder="SSN"
              mask="###-##-####"
              inputMode="numeric"
              name="ssn"
              control={control}
            />
          </FormControl>
          <FormControl label="DOB">
            <FormCalendarInput
              name="dob"
              placeholderText="Date of Birth"
              maxDate={new Date()}
              control={control}
            />
          </FormControl>
          <FormControl label="Order Type">
            <FormSelect
              control={control}
              name="orderType"
              items={selectOrderTypes}
              labelAccessor="label"
              valueAccessor="value"
            />
          </FormControl>
          <FormControl label="Order Description">
            <Input
              type="text"
              placeholder="Order Description"
              {...register("orderDescription")}
            />
          </FormControl>
          <FormControl label="Order Date">
            <FormCalendarInput
              name="orderDate"
              placeholderText="Order Date"
              maxDate={new Date()}
              control={control}
            />
          </FormControl>
          <FormControl label="Order ID">
            <Input
              type="text"
              placeholder="Order ID"
              {...register("orderId")}
            />
          </FormControl>
          <FormControl label="Ordered by">
            <Input
              type="text"
              placeholder="Ordered by"
              {...register("orderedBy")}
            />
          </FormControl>

          <Button
            justifySelf="center"
            alignSelf="end"
            variant="action"
            colorScheme="dark-gray"
            onClick={handleReset}
            width="100%"
            maxWidth="280px"
            borderRadius="10px"
          >
            Clear Search
          </Button>
        </SimpleGrid>
      </Card>
    </Box>
  );
}

export { OrdersSearchCard };
