import * as React from "react";
import { VStack, Box } from "@chakra-ui/react";
import { SUBSECTION_CARD_PADDING } from "modules";
import { CardProps, Card } from "shared";

type ChartSubsectionProps = {
  content: JSX.Element;
  header?: JSX.Element;
};

function ChartSubsectionContent(props: CardProps) {
  const { children, ...restProps } = props;

  return (
    <Card
      padding={SUBSECTION_CARD_PADDING}
      overflow="auto"
      border="none"
      boxShadow="none"
      {...restProps}
    >
      <VStack spacing="1rem" minW="100%">
        {children}
      </VStack>
    </Card>
  );
}

function ChartSubsection(props: ChartSubsectionProps) {
  const { content, header } = props;

  return (
    <Box
      sx={{
        "> :not(:last-child)": { marginBottom: "1rem" },
        marginBottom: ".5rem",
      }}
    >
      {header}
      {content}
    </Box>
  );
}

export { ChartSubsection, ChartSubsectionContent };
