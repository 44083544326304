import { axiosOrderInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { OrderQueueRequestDto } from "./useAddOrderToQueue";

type AddOrderToQueueProps = {
  encounterId: string;
  chartCode: string;
};

function useAddMultipleOrdersToQueue({
  encounterId,
  chartCode,
}: AddOrderToQueueProps) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["addMultipleOrdersToQueue", { encounterId, chartCode }],
    (payload: OrderQueueRequestDto[]) =>
      axiosOrderInstance.post(
        `v1/queue/${encounterId}/${chartCode}/multiple`,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["orderQueue", { encounterId }]);
      },
    }
  );
}

export { useAddMultipleOrdersToQueue };
