import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartRowElementImportedProps,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SCLI";
const chartCode = "C024x";

function T24ClinicalImpression() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({
          description: formMessages.updateSuccess("Clinical Impression"),
        });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      getValues,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "clinical-impression",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Clinical Impression") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  const chartFavoriteEditMode: Record<
    "imported",
    ChartRowElementImportedProps
  > | null = getValues("chartFavoriteEditMode")
    ? {
        imported: {
          type: "nursing",
        },
      }
    : null;

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Initial visit unless marked:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                />
                <ChartRowElement
                  label="subsequent"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="sequelae"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Neuro"
            >
              Neuro
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Migraine HA:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A051`}
                />
                <ChartRowElement
                  label="acute:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A052`}
                />
                <ChartRowElement
                  label="tx resistant"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="poor control"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A053`}
                />
                <ChartRowElement
                  label="aura"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="status migrainosus"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hemiplegia"
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="menses"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="cerebral infarct"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Headache:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A054`}
                />
                <ChartRowElement
                  label="acute:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A055`}
                />
                <ChartRowElement
                  label="tx resistant"
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="poor control"
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A056`}
                />
                <ChartRowElement
                  label="tension"
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="vascular"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="cluster"
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hypertensive"
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="exertional"
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="allergy related"
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="cough"
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="post traumatic"
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="post LP"
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="drug induced:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A057`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="CVA:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A058`}
                />
                <ChartRowElement
                  label="Infarct:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A059`}
                />
                <ChartRowElement
                  label="thrombotic"
                  name={`Q002.${chartCode}${sectionId}Q002A022`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="embolic"
                  name={`Q002.${chartCode}${sectionId}Q002A023`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="occlusion"
                  name={`Q002.${chartCode}${sectionId}Q002A024`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="unknown type"
                  name={`Q002.${chartCode}${sectionId}Q002A025`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="artery involved:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A060`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A026`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A027`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A028`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hemorrhage:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A061`}
                />
                <ChartRowElement
                  label="Intracerebral:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A062`}
                />
                <ChartRowElement
                  label="subcortical"
                  name={`Q002.${chartCode}${sectionId}Q002A029`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="cortical"
                  name={`Q002.${chartCode}${sectionId}Q002A030`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="intraventricular"
                  name={`Q002.${chartCode}${sectionId}Q002A031`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="SAH"
                  name={`Q002.${chartCode}${sectionId}Q002A032`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="brainstem"
                  name={`Q002.${chartCode}${sectionId}Q002A033`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="cerebellar"
                  name={`Q002.${chartCode}${sectionId}Q002A034`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="artery involved:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A063`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A035`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A036`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A037`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Meningitis:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A064`}
                />
                <ChartRowElement
                  label="viral"
                  name={`Q002.${chartCode}${sectionId}Q002A038`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="bacterial"
                  name={`Q002.${chartCode}${sectionId}Q002A039`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="H influ"
                  name={`Q002.${chartCode}${sectionId}Q002A040`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Pneumo"
                  name={`Q002.${chartCode}${sectionId}Q002A041`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Strep"
                  name={`Q002.${chartCode}${sectionId}Q002A042`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Staph"
                  name={`Q002.${chartCode}${sectionId}Q002A043`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A044`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Subdural (nontraumatic):"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A065`}
                />
                <ChartRowElement
                  label="L"
                  name={`Q002.${chartCode}${sectionId}Q002A045`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="R"
                  name={`Q002.${chartCode}${sectionId}Q002A046`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q002.${chartCode}${sectionId}Q002A047`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="subacute"
                  name={`Q002.${chartCode}${sectionId}Q002A048`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A049`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A050`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Other"
            >
              Other
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Anemia:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A100`}
                />
                <ChartRowElement
                  label="assoc. w:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A101`}
                />
                <ChartRowElement
                  label="disease"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="cancer"
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="ESRD"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="chemotherapy"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Iron deficiency:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A102`}
                />
                <ChartRowElement
                  label="due to:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A103`}
                />
                <ChartRowElement
                  label="inadequate diet"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="blood loss"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Anxiety disorder, generalized"
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="CO Poisoning:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A104`}
                />
                <ChartRowElement
                  label="accident"
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="self-harm"
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="source:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A105`}
                />
                <ChartRowElement
                  label="vehicle exhaust"
                  name={`Q003.${chartCode}${sectionId}Q003A012`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="gas appliance"
                  name={`Q003.${chartCode}${sectionId}Q003A013`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="wood burning"
                  name={`Q003.${chartCode}${sectionId}Q003A014`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="kerosene stove"
                  name={`Q003.${chartCode}${sectionId}Q003A015`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A016`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Dementia:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A106`}
                />
                <ChartRowElement
                  label="senile"
                  name={`Q003.${chartCode}${sectionId}Q003A017`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="multi-infarct"
                  name={`Q003.${chartCode}${sectionId}Q003A018`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Alzheimer's:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A107`}
                />
                <ChartRowElement
                  label="early onset"
                  name={`Q003.${chartCode}${sectionId}Q003A019`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="late"
                  name={`Q003.${chartCode}${sectionId}Q003A020`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Parkinson's"
                  name={`Q003.${chartCode}${sectionId}Q003A021`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="frontotemporal"
                  name={`Q003.${chartCode}${sectionId}Q003A022`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="w/ behavioral problem"
                  name={`Q003.${chartCode}${sectionId}Q003A023`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A024`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Depression:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A108`}
                />
                <ChartRowElement
                  label="major depressive disorder"
                  name={`Q003.${chartCode}${sectionId}Q003A025`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="single episode"
                  name={`Q003.${chartCode}${sectionId}Q003A026`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="recurrent"
                  name={`Q003.${chartCode}${sectionId}Q003A027`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="mild"
                  name={`Q003.${chartCode}${sectionId}Q003A028`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="moderate"
                  name={`Q003.${chartCode}${sectionId}Q003A029`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="severe"
                  name={`Q003.${chartCode}${sectionId}Q003A030`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A031`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Diabetes:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A109`}
                />
                <ChartRowElement
                  label="Type 1"
                  name={`Q003.${chartCode}${sectionId}Q003A032`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Type 2"
                  name={`Q003.${chartCode}${sectionId}Q003A033`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="complicated"
                  name={`Q003.${chartCode}${sectionId}Q003A034`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="uncomplicated"
                  name={`Q003.${chartCode}${sectionId}Q003A035`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="w:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A110`}
                />
                <ChartRowElement
                  label="hyperglycemia"
                  name={`Q003.${chartCode}${sectionId}Q003A036`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="diabetic:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A111`}
                />
                <ChartRowElement
                  label="peripheral neuropathy"
                  name={`Q003.${chartCode}${sectionId}Q003A037`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="foot ulcer"
                  name={`Q003.${chartCode}${sectionId}Q003A038`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="skin ulcer"
                  name={`Q003.${chartCode}${sectionId}Q003A039`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="PVD:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A112`}
                />
                <ChartRowElement
                  label="w:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A113`}
                />
                <ChartRowElement
                  label="gangrene"
                  name={`Q003.${chartCode}${sectionId}Q003A040`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="neuropathy"
                  name={`Q003.${chartCode}${sectionId}Q003A041`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hay fever"
                  name={`Q003.${chartCode}${sectionId}Q003A042`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A043`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypertension:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A114`}
                />
                <ChartRowElement
                  label="uncontrolled"
                  name={`Q003.${chartCode}${sectionId}Q003A044`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="malignant"
                  name={`Q003.${chartCode}${sectionId}Q003A045`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A046`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Leukocytosis"
                  name={`Q003.${chartCode}${sectionId}Q003A047`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A048`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="MI, acute:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A115`}
                />
                <ChartRowElement
                  label="STEMI"
                  name={`Q003.${chartCode}${sectionId}Q003A049`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="NSTEMI"
                  name={`Q003.${chartCode}${sectionId}Q003A050`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="anterior"
                  name={`Q003.${chartCode}${sectionId}Q003A051`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="inferior"
                  name={`Q003.${chartCode}${sectionId}Q003A052`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="lateral"
                  name={`Q003.${chartCode}${sectionId}Q003A053`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="post"
                  name={`Q003.${chartCode}${sectionId}Q003A054`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A055`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pneumonia:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A116`}
                />
                <ChartRowElement
                  label="interstitial"
                  name={`Q003.${chartCode}${sectionId}Q003A056`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="atypical"
                  name={`Q003.${chartCode}${sectionId}Q003A057`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="bronchopneumonia"
                  name={`Q003.${chartCode}${sectionId}Q003A058`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="lobar"
                  name={`Q003.${chartCode}${sectionId}Q003A059`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="aspiration"
                  name={`Q003.${chartCode}${sectionId}Q003A060`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="viral:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A117`}
                />
                <ChartRowElement
                  label="RSV"
                  name={`Q003.${chartCode}${sectionId}Q003A061`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="inflluenza:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A118`}
                />
                <ChartRowElement
                  label="A"
                  name={`Q003.${chartCode}${sectionId}Q003A062`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="B"
                  name={`Q003.${chartCode}${sectionId}Q003A063`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="bacterial:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A119`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A064`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Prescription refill"
                  name={`Q003.${chartCode}${sectionId}Q003A065`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A066`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pseudotumor cerebri"
                  name={`Q003.${chartCode}${sectionId}Q003A067`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A068`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Sinusitis:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A120`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q003.${chartCode}${sectionId}Q003A069`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="recurrent"
                  name={`Q003.${chartCode}${sectionId}Q003A070`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="maxillary"
                  name={`Q003.${chartCode}${sectionId}Q003A071`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="ethmoid"
                  name={`Q003.${chartCode}${sectionId}Q003A072`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="frontal"
                  name={`Q003.${chartCode}${sectionId}Q003A073`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="sphenoid"
                  name={`Q003.${chartCode}${sectionId}Q003A074`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A075`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Substance abuse:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A121`}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A076`}
                  w="300px"
                  type="input"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="w:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A122`}
                />
                <ChartRowElement
                  label="intoxication"
                  name={`Q003.${chartCode}${sectionId}Q003A077`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="dependence"
                  name={`Q003.${chartCode}${sectionId}Q003A078`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="withdrawal"
                  name={`Q003.${chartCode}${sectionId}Q003A079`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="delirium"
                  name={`Q003.${chartCode}${sectionId}Q003A080`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="perceptual disturbance"
                  name={`Q003.${chartCode}${sectionId}Q003A081`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A082`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Syncope:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A123`}
                />
                <ChartRowElement
                  label="vasovagal"
                  name={`Q003.${chartCode}${sectionId}Q003A083`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="orthostatic"
                  name={`Q003.${chartCode}${sectionId}Q003A084`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A085`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Temporal arterits"
                  name={`Q003.${chartCode}${sectionId}Q003A086`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A087`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="TMJ syndrome"
                  name={`Q003.${chartCode}${sectionId}Q003A088`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A089`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Trigeminal neuralgia"
                  name={`Q003.${chartCode}${sectionId}Q003A090`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A091`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="UTI:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A124`}
                />
                <ChartRowElement
                  label="cystitis"
                  name={`Q003.${chartCode}${sectionId}Q003A092`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q003.${chartCode}${sectionId}Q003A093`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="w/ hematuria"
                  name={`Q003.${chartCode}${sectionId}Q003A094`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A095`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Viral syndrome"
                  name={`Q003.${chartCode}${sectionId}Q003A096`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A097`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q003.${chartCode}${sectionId}Q003A098`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A099`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Signs / Symptoms"
            >
              Signs / Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Chest pain:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A019`}
                />
                <ChartRowElement
                  label="precordial"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="atypical"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Chest wall pain"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Nausea"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Vomiting"
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Dehydration"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypokalemia"
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Leukocytosis"
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Malaise"
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Fatigue"
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A016`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="other"
                  name={`Q004.${chartCode}${sectionId}Q004A017`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A018`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T24ClinicalImpression };
