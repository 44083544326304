import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const Recording = createIcon({
  path: (
    <path
      d="M12 24C18.5647 24 24 18.5529 24 12C24 5.43529 18.5529 0 11.9882 0C5.43529 0 0 5.43529 0 12C0 18.5529 5.44706 24 12 24ZM8.8 16.4118C8.04706 16.4118 7.58824 15.9647 7.58824 15.2235V8.77647C7.58824 8.02353 8.04706 7.58824 8.8 7.58824H15.2C15.9647 7.58824 16.4118 8.02353 16.4118 8.77647V15.2235C16.4118 15.9647 15.9647 16.4118 15.2 16.4118H8.8Z"
      fill="currentColor"
    />
  ),
  viewBox: "-2 0 30 24",
});

export { Recording };
