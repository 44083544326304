import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const OverrideHardStops = createIcon({
  path: (
    <path
      d="M7.09708 19.2083C5.86239 19.2083 5.14609 18.7842 4.46748 18.0302L0.848256 13.968C0.169651 13.2045 0 12.705 0 11.6682V7.54006C0 6.5033 0.169651 6.0132 0.848256 5.24034L4.46748 1.18756C5.14609 0.433553 5.86239 0 7.09708 0H12.9029C14.1376 0 14.8633 0.424128 15.5325 1.18756L19.0763 5.24034C19.7738 6.0509 20 6.5033 20 7.54006V11.6682C20 12.705 19.8209 13.2045 19.1423 13.968L15.5325 18.0302C14.8539 18.7842 14.1376 19.2083 12.9029 19.2083H7.09708ZM10.0094 11.1404C10.5938 11.1404 10.9331 10.8106 10.9614 10.1979L11.1027 5.74929C11.131 5.11781 10.6598 4.66541 10 4.66541C9.33082 4.66541 8.86899 5.10839 8.89727 5.73987L9.03864 10.1979C9.06692 10.8106 9.40622 11.1404 10.0094 11.1404ZM10.0094 14.4581C10.688 14.4581 11.2347 13.9962 11.2347 13.3271C11.2347 12.6673 10.6975 12.196 10.0094 12.196C9.32139 12.196 8.77474 12.6673 8.77474 13.3271C8.77474 13.9868 9.33082 14.4581 10.0094 14.4581Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { OverrideHardStops };
