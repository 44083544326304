import { axiosOrderInstance } from "config";
import {
  OrderFormElementCategory,
  OrderFormElementRoute,
  OrderFormElementType,
} from "enums";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type AddOrderToQueueProps = {
  encounterId: string;
  chartCode: string;
};

type OrderQueueRequestDto = {
  orderBy: string;
  description: string;
  notes?: string;
  elementType: OrderFormElementType;
  elementCategory?: OrderFormElementCategory;
  location?: string;
  route?: OrderFormElementRoute;
  administrationTime?: string;
  cptCode?: string;
  studyTypeId?: string;
  instructions?: string;
  repetitions?: number;
  scheduledTime?: string;
  isObservation?: boolean;
  isPRN?: boolean;
  prnReason?: string;
  isScheduled?: boolean;
};

function useAddOrderToQueue({ encounterId, chartCode }: AddOrderToQueueProps) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["addOrderToQueue", { encounterId, chartCode }],
    (payload: OrderQueueRequestDto) =>
      axiosOrderInstance.post(`v1/queue/${encounterId}/${chartCode}`, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["orderQueue", { encounterId }]);
      },
    }
  );
}

export { useAddOrderToQueue };
export type { OrderQueueRequestDto };
