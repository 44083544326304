import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useToast,
  chakra,
  IconButton,
} from "@chakra-ui/react";
import { EmrLock } from "@medstonetech/slate-icons";
import {
  CONCENTRATION_DOSE_UNITS,
  UNITS_ARRAY,
  VOLUME_UNITS,
} from "constants/orders";
import { OrderFormElementRoute } from "enums";
import { useAddCommonDoseFavorite } from "modules/charts-shared/api";
import { calculateMedicationAmount } from "modules/charts-shared/utils";
import {
  CommonDose,
  Concentration,
  OrderElementResponse,
} from "modules/order-list/types";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { IoArrowDownCircleOutline, IoHeart } from "react-icons/io5";
import { Button, Card, Checkbox, Label, Select } from "shared";
import { getOptions } from "utils/enum-mapper";
import { MedicationOrderForm } from "./AddMedicationOrderModal";
import {
  VALID_CONCENTRATIONS_UNITS,
  VALID_UNITS,
  VALID_VOLUME_UNITS,
} from "./ValidCalculations";

type CommonDosesProps = {
  item: OrderElementResponse;
  showCalculation: () => void;
};

const fieldLabelStyle = {
  mt: "10px",
  fontSize: "11pt",
};

function CommonDoses({ item, showCalculation }: CommonDosesProps) {
  const { setValue, register, watch, getValues } =
    useFormContext<MedicationOrderForm>();

  const { orderFormElementCommonDoses: commonDoses } = item;

  const { mutateAsync: addFavorite, isLoading: isAddingFavorite } =
    useAddCommonDoseFavorite();

  const toast = useToast();

  const getConcentration = (concentrationId?: string) => {
    const concentration: Concentration | undefined =
      item.orderFormElementConcentrations?.find(
        (c) => c.id === concentrationId
      );

    if (
      concentration &&
      concentration.doseAmount &&
      concentration.doseUnit &&
      concentration.volumeAmount &&
      concentration.volumeUnit
    ) {
      return ` • (${concentration.doseAmount} ${concentration.doseUnit} / ${concentration.volumeAmount} ${concentration.volumeUnit})`;
    }

    return "";
  };

  const handleCalculate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = e;
    const { concentration, doseAmount, doseUnit, weight } = getValues();
    if (checked && concentration && doseAmount && doseUnit && weight) {
      const amount = calculateMedicationAmount(
        concentration,
        doseAmount,
        doseUnit,
        weight
      );
      setValue("amount", amount);
    } else {
      setValue("amount", null);
    }
  };

  const doseUnit = watch("doseUnit");
  const doseId = watch("doseId");
  const route = watch("route");
  const concentrationDoseUnit = watch("concentration.doseUnit");
  const volumeUnit = watch("concentration.volumeUnit");

  const onAddFavorite = useCallback(async () => {
    if (doseId) {
      await addFavorite({
        orderFormElementId: item.id,
        route: route as OrderFormElementRoute,
        orderFormElementCommonDoseId: doseId,
      });
      toast({ description: "Common dose added to favorites" });
    }
  }, [addFavorite, doseId, item.id, route, toast]);

  const onImportDose = (dose: CommonDose, doseRoute: string | null) => {
    const concentration: Concentration | undefined =
      item.orderFormElementConcentrations?.find(
        (c) => c.id === dose.orderFormEelementConcentrationId
      );

    setValue("doseAmount", dose.doseAmount);
    setValue("doseUnit", dose.doseUnit);
    setValue("route", doseRoute || "");
    setValue("doseId", dose.id);

    if (concentration) {
      setValue("concentration.doseAmount", concentration.doseAmount);
      setValue("concentration.doseUnit", concentration.doseUnit);
      setValue("concentration.volumeAmount", concentration.volumeAmount);
      setValue("concentration.volumeUnit", concentration.volumeUnit);
    } else {
      setValue("concentration.doseAmount", undefined);
      setValue("concentration.doseUnit", undefined);
      setValue("concentration.volumeAmount", undefined);
      setValue("concentration.volumeUnit", undefined);
    }
  };

  const validCalculation =
    VALID_UNITS.includes(doseUnit) &&
    !!concentrationDoseUnit &&
    VALID_CONCENTRATIONS_UNITS.includes(concentrationDoseUnit) &&
    !!volumeUnit &&
    VALID_VOLUME_UNITS.includes(volumeUnit);

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Label {...fieldLabelStyle}>Medication Name *</Label>
      <InputGroup>
        <Input readOnly value={item?.description} />
        <InputRightElement>
          <Icon as={EmrLock} w="32px" h="32px" size="lg" color="gray.450" />
        </InputRightElement>
      </InputGroup>

      <Label {...fieldLabelStyle}>Common Doses • Concentration</Label>
      <Card variant="basic" p="15px 0" minH="40px" background="transparent">
        <Stack maxH="48" overflow="auto">
          {commonDoses?.map((cd) => (
            <Box alignItems="center" key={cd.id}>
              <IconButton
                color="blue"
                fontSize="1.5rem"
                icon={<Icon as={IoArrowDownCircleOutline} />}
                onClick={() => onImportDose(cd, route)}
                aria-label="remove favorite"
                variant="label"
                mr="0.5rem"
              />
              <chakra.span fontWeight="500">
                {cd.doseAmount || 0} {cd.doseUnit}
                {getConcentration(cd.orderFormEelementConcentrationId)}
              </chakra.span>
            </Box>
          ))}
        </Stack>
      </Card>

      <Label {...fieldLabelStyle}>Custom Dosage</Label>
      <Flex columnGap="10px">
        <Input {...register("doseAmount")} type="number" step=".001" />
        <Select
          items={UNITS_ARRAY}
          labelAccessor="label"
          valueAccessor="value"
          selectedItem={UNITS_ARRAY.find((u) => u.value === doseUnit)}
          showBlank={false}
          onSelectedItemChange={(changes) =>
            setValue("doseUnit", changes.selectedItem?.value || "")
          }
        />
      </Flex>

      <Label {...fieldLabelStyle}>Custom Concentration</Label>
      <Flex columnGap="10px">
        <Input
          {...register("concentration.doseAmount")}
          type="number"
          step=".001"
        />
        <Select
          items={CONCENTRATION_DOSE_UNITS}
          labelAccessor="label"
          valueAccessor="value"
          selectedItem={CONCENTRATION_DOSE_UNITS.find(
            (x) => x.value === concentrationDoseUnit
          )}
          onSelectedItemChange={(changes) =>
            setValue(
              "concentration.doseUnit",
              changes.selectedItem?.value ?? ""
            )
          }
        />
        <Input
          {...register("concentration.volumeAmount")}
          type="number"
          step=".001"
        />
        <Select
          items={VOLUME_UNITS}
          labelAccessor="label"
          valueAccessor="value"
          showBlank={false}
          selectedItem={VOLUME_UNITS.find((x) => x.value === volumeUnit)}
          onSelectedItemChange={(changes) =>
            setValue(
              "concentration.volumeUnit",
              changes.selectedItem?.value ?? ""
            )
          }
        />
      </Flex>

      {watch("concentration") && validCalculation && (
        <>
          <Label {...fieldLabelStyle}>Weight(kg)</Label>
          <Flex columnGap="10px">
            <Input type="number" step=".001" flex={1} {...register("weight")} />
            <Checkbox
              isDisabled={!Boolean(watch("weight"))}
              onChange={handleCalculate}
              flex={1}
            >
              Calculate from weight
            </Checkbox>
          </Flex>
        </>
      )}
      {watch("amount") && (
        <>
          <Label {...fieldLabelStyle}>Amount</Label>
          <Flex>
            <InputGroup flex={1}>
              <Input readOnly value={watch("amount") || 0} />
              <InputRightElement>mL</InputRightElement>
            </InputGroup>
            <Button
              flex={1}
              variant="label"
              color="blue"
              onClick={showCalculation}
            >
              Show Calculation
            </Button>
          </Flex>
        </>
      )}
      <Label {...fieldLabelStyle}>Route *</Label>
      <Select
        items={getOptions("orderFormElementRoute")}
        labelAccessor="label"
        valueAccessor="value"
        onSelectedItemChange={(changes) =>
          setValue("route", changes.selectedItem?.value || "")
        }
      />
      <Button
        color="blue"
        leftIcon={<Icon as={IoHeart} />}
        onClick={onAddFavorite}
        aria-label="add favorite"
        variant="label"
        disabled={!(doseId && route)}
        isLoading={isAddingFavorite}
        mt="1rem"
      >
        Add to favorites
      </Button>

      <Label {...fieldLabelStyle}>Notes</Label>
      <Input {...register("notes")} type="text" />
    </Box>
  );
}

export { CommonDoses };
