import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartRowElementImportedProps,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrClinicalImpression } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SCLI";
const chartCode = "C044x";

function T44ClinicalImpression() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:ci").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({
          description: formMessages.updateSuccess("Clinical Impression"),
        });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      getValues,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "clinical-impression",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Clinical Impression") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  const chartFavoriteEditMode: Record<
    "imported",
    ChartRowElementImportedProps
  > | null = getValues("chartFavoriteEditMode")
    ? {
        imported: {
          type: "nursing",
        },
      }
    : null;

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrClinicalImpression} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          Clinical Impression {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Initial visit unless marked:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q001.${chartCode}${sectionId}Q001A003`}
                />
                <ChartRowElement
                  label="subsequent"
                  name={`Q001.${chartCode}${sectionId}Q001A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="sequelae"
                  name={`Q001.${chartCode}${sectionId}Q001A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement type="empty" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Allergy"
            >
              Allergy
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Allergic reaction"
                  name={`Q002.${chartCode}${sectionId}Q002A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Anaphylaxis"
                  name={`Q002.${chartCode}${sectionId}Q002A002`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="localized"
                  name={`Q002.${chartCode}${sectionId}Q002A003`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="generalized"
                  name={`Q002.${chartCode}${sectionId}Q002A004`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="to:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A022`}
                />
                <ChartRowElement
                  label="bee sting"
                  name={`Q002.${chartCode}${sectionId}Q002A006`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="wasp"
                  name={`Q002.${chartCode}${sectionId}Q002A007`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="fire ants"
                  name={`Q002.${chartCode}${sectionId}Q002A008`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="food:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A023`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A009`}
                  w="318.6666564941406px"
                  type="input"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="drug:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A024`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A010`}
                  w="318.6666564941406px"
                  type="input"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="substance:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A025`}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A011`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="w/:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A026`}
                />
                <ChartRowElement
                  label="pruritus"
                  name={`Q002.${chartCode}${sectionId}Q002A012`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="rash"
                  name={`Q002.${chartCode}${sectionId}Q002A013`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hives"
                  name={`Q002.${chartCode}${sectionId}Q002A014`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="bronchospasm"
                  name={`Q002.${chartCode}${sectionId}Q002A015`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="angioedema"
                  name={`Q002.${chartCode}${sectionId}Q002A016`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="shock"
                  name={`Q002.${chartCode}${sectionId}Q002A017`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A018`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Angioendema:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q002.${chartCode}${sectionId}Q002A027`}
                />
                <ChartRowElement
                  label="hereditary"
                  name={`Q002.${chartCode}${sectionId}Q002A019`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A020`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q002.${chartCode}${sectionId}Q002A021`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="ENT / Resp"
            >
              ENT / Resp
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Airway Obstruction"
                  name={`Q003.${chartCode}${sectionId}Q003A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A002`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Allergic rhinitis:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A023`}
                />
                <ChartRowElement
                  label="acute"
                  name={`Q003.${chartCode}${sectionId}Q003A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="seasonal"
                  name={`Q003.${chartCode}${sectionId}Q003A004`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="perennial"
                  name={`Q003.${chartCode}${sectionId}Q003A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="due to:"
                  textDecorationLine="underline"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A024`}
                />
                <ChartRowElement
                  label="pollen"
                  name={`Q003.${chartCode}${sectionId}Q003A006`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="food"
                  name={`Q003.${chartCode}${sectionId}Q003A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="dander"
                  name={`Q003.${chartCode}${sectionId}Q003A008`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A009`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Asthma / Reactive airway dz:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A025`}
                />
                <ChartRowElement
                  label="acute exacerbation"
                  name={`Q003.${chartCode}${sectionId}Q003A010`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="status asthmaticus"
                  name={`Q003.${chartCode}${sectionId}Q003A011`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hx of:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q003.${chartCode}${sectionId}Q003A026`}
                />
                <ChartRowElement
                  label="mild"
                  name={`Q003.${chartCode}${sectionId}Q003A012`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="mod"
                  name={`Q003.${chartCode}${sectionId}Q003A013`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="severe dz"
                  name={`Q003.${chartCode}${sectionId}Q003A014`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="intermittent"
                  name={`Q003.${chartCode}${sectionId}Q003A015`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="persistent"
                  name={`Q003.${chartCode}${sectionId}Q003A016`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A017`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Bronchospasm, acute"
                  name={`Q003.${chartCode}${sectionId}Q003A018`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A019`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hay fever"
                  name={`Q003.${chartCode}${sectionId}Q003A020`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A021`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q003.${chartCode}${sectionId}Q003A022`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Skin / Systemic / Other"
            >
              Skin / Systemic / Other
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Contact dermatitis, allergic:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A031`}
                />
                <ChartRowElement
                  label="due to:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A032`}
                />
                <ChartRowElement
                  label="latex"
                  name={`Q004.${chartCode}${sectionId}Q004A001`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="plants"
                  name={`Q004.${chartCode}${sectionId}Q004A002`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="cosmetics"
                  name={`Q004.${chartCode}${sectionId}Q004A003`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="metal"
                  name={`Q004.${chartCode}${sectionId}Q004A004`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A005`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Drug rash:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A033`}
                />
                <ChartRowElement
                  label="due to:"
                  fontStyle="italic"
                  textDecorationLine="underline"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A034`}
                />
                <ChartRowElement
                  label="enteral"
                  name={`Q004.${chartCode}${sectionId}Q004A006`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="parenteral"
                  name={`Q004.${chartCode}${sectionId}Q004A007`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="topical"
                  name={`Q004.${chartCode}${sectionId}Q004A008`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A009`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Erythema multiforme:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A035`}
                />
                <ChartRowElement
                  label="due to:"
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A036`}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A010`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Insect bite:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A037`}
                />
                <ChartRowElement
                  label="Insect sting:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A038`}
                />
                <ChartRowElement
                  label="ant"
                  name={`Q004.${chartCode}${sectionId}Q004A011`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="fire ant"
                  name={`Q004.${chartCode}${sectionId}Q004A012`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="bee"
                  name={`Q004.${chartCode}${sectionId}Q004A013`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="wasp"
                  name={`Q004.${chartCode}${sectionId}Q004A014`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="hornet"
                  name={`Q004.${chartCode}${sectionId}Q004A015`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="spider"
                  name={`Q004.${chartCode}${sectionId}Q004A016`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="tick"
                  name={`Q004.${chartCode}${sectionId}Q004A017`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="mosquito"
                  name={`Q004.${chartCode}${sectionId}Q004A018`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="caterpillar"
                  name={`Q004.${chartCode}${sectionId}Q004A019`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="centipede"
                  name={`Q004.${chartCode}${sectionId}Q004A020`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A021`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Poison Ivy"
                  name={`Q004.${chartCode}${sectionId}Q004A022`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="Poison Oak"
                  name={`Q004.${chartCode}${sectionId}Q004A023`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A024`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Pruritus:"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                  name={`Q004.${chartCode}${sectionId}Q004A039`}
                />
                <ChartRowElement
                  label="local"
                  name={`Q004.${chartCode}${sectionId}Q004A025`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  label="generalized"
                  name={`Q004.${chartCode}${sectionId}Q004A026`}
                  fontStyle="italic"
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A027`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Urticaria, allergic"
                  name={`Q004.${chartCode}${sectionId}Q004A028`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A029`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q004.${chartCode}${sectionId}Q004A030`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Signs / Symptoms"
            >
              Signs / Symptoms
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  label="Nasal congestion"
                  name={`Q005.${chartCode}${sectionId}Q005A001`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A002`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Hypokalemia"
                  name={`Q005.${chartCode}${sectionId}Q005A003`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A004`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Leukocytosis"
                  name={`Q005.${chartCode}${sectionId}Q005A005`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A006`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Rash"
                  name={`Q005.${chartCode}${sectionId}Q005A007`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A008`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Shortness of breath"
                  name={`Q005.${chartCode}${sectionId}Q005A009`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A010`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Stridor"
                  name={`Q005.${chartCode}${sectionId}Q005A011`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A012`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  label="Syncope"
                  name={`Q005.${chartCode}${sectionId}Q005A013`}
                  type="cross-option"
                  {...chartFavoriteEditMode}
                />
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A014`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  name={`Q005.${chartCode}${sectionId}Q005A015`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T44ClinicalImpression };
