import { useAuth0 } from "@auth0/auth0-react";
import { Box, Icon, IconButton, Text } from "@chakra-ui/react";
import { EmrRemove, EmrSignature } from "@medstonetech/slate-icons";
import { useGetUserDetails } from "api";
import { useUserRoles } from "contexts/UserRoles";
import { ProviderChartCode, TriageChartCode } from "enums";
import {
  DispositionForm,
  DispositionSignatureForm,
} from "modules/charts-shared/types";
import { Control, useFieldArray } from "react-hook-form";
import { Button } from "shared";
import { USERID_CLAIMS } from "system-constants";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  SharedChartSubsectionHeader,
} from "..";
import { DispositionSignatureItem } from "./DispositionSignatureSubsection";

type AddNurseSignatureProps = {
  onAdd: () => void;
};

function AddNurseSignature({ onAdd }: AddNurseSignatureProps) {
  const { roles } = useUserRoles();

  return (
    <ChartSubsectionContent>
      <ChartRow templateColumns="repeat(3, 1fr)">
        <ChartRowElement
          type="container"
          content={
            <>
              <Text>E-signed by:</Text>
            </>
          }
        />
        <ChartRowGroup>
          <ChartRowElement
            type="container"
            minW={300}
            maxW={400}
            minH={50}
            content={
              <Box>
                <Button
                  variant="label"
                  color="blue"
                  onClick={onAdd}
                  disabled={
                    !roles.some((role) =>
                      ["Nurse", "ChargeNurse"].includes(role)
                    )
                  }
                >
                  <Icon
                    as={EmrSignature}
                    fontSize="1.5rem"
                    color="blue"
                    m={2}
                  />
                  Add Signature
                </Button>
              </Box>
            }
          />
        </ChartRowGroup>
        <ChartRowGroup justifyContent="end" pr={4}>
          <IconButton
            aria-label="sign"
            size="iconSm"
            icon={<Icon as={EmrRemove} fontSize="1.25rem" color={"red"} />}
            maxWidth="24px"
            variant="ghost"
            disabled
          />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

type DispositionNurseSignatureProps = {
  encounterId: string;
  chartCode: TriageChartCode | ProviderChartCode;
  sectionCode: string;
  chartType: string;
  signatures: DispositionSignatureForm[];
  control: Control<DispositionForm>;
};

function DispositionNurseSignature({
  chartCode,
  encounterId,
  sectionCode,
  chartType,
  signatures,
  control,
}: DispositionNurseSignatureProps) {
  const { user: currentUser } = useAuth0();
  const currentUserId = currentUser ? currentUser[USERID_CLAIMS] : "";

  const { data } = useGetUserDetails(currentUserId);

  const nurseSignature = signatures?.find((el) => el.type === "Nurse");

  const { insert: add, remove } = useFieldArray({
    control,
    name: "signatures",
  });

  const { insert } = useFieldArray({
    control,
    name: "signaturesToDelete",
  });

  const handleDelete = (userId: string) => {
    const index = signatures?.findIndex(
      (signature) => signature.userId === userId
    );

    if (index !== undefined) {
      remove(index);
    }

    const signature = signatures?.find((item) => item.userId === userId);

    if (signature && signature.id) {
      insert(0, signature);
    }
  };

  const handleAdd = () => {
    add(0, {
      type: "Nurse",
      userId: currentUserId,
      user: data?.data,
      createdBy: currentUserId,
      signed: true,
    });
  };

  return (
    <Box>
      <ChartSubsection
        header={
          <SharedChartSubsectionHeader
            chartCode={chartCode}
            encounterId={encounterId}
            sectionCode={sectionCode}
            subsectionCode="Q006"
            subsectionLabel={`Nurse Signature`}
            showNotesBtn={chartType === "Triage" ? false : undefined}
            showAuditsBtn={chartType === "Chart" || chartType === "Triage"}
            showInteractionsBtn={false}
          >
            Nurse Signature
          </SharedChartSubsectionHeader>
        }
        content={
          <>
            {nurseSignature && (
              <DispositionSignatureItem
                signature={nurseSignature}
                onDelete={handleDelete}
                control={control}
                signatures={signatures}
                useDelete
              />
            )}

            {!nurseSignature && <AddNurseSignature onAdd={handleAdd} />}
          </>
        }
      />
    </Box>
  );
}

export { DispositionNurseSignature };
