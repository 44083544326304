import { axiosPatientInstance } from "config";
import { SummaryReasons } from "enums";
import { useAxiosMutation } from "hooks";

type UseGenerateSummaryDocumentPayload = {
  addPatientDemographics?: boolean;
  addRegistrationDocuments?: boolean;
  addInsuranceInformation?: boolean;
  addNursingChartSummary?: boolean;
  addProviderChartSummary?: boolean;
  addLabReports?: boolean;
  addRadiologyReports?: boolean;
  addDischargeFile?: boolean;
  addObservationFile?: boolean;
  addMedicationsReport?: boolean;
  miscFilesToAdd?: string[];
};

function useGenerateSummaryDocument(
  encounterId: string,
  reason?: SummaryReasons,
  otherReason?: string
) {
  return useAxiosMutation(
    ["generateSummaryDocument", { encounterId }],
    (payload: UseGenerateSummaryDocumentPayload) =>
      axiosPatientInstance.post<Blob>(
        `v1/summary/${encounterId}/call-and-generate-custom-download`,
        payload,
        { responseType: "blob", params: { reason, description: otherReason } }
      )
  );
}

export type { UseGenerateSummaryDocumentPayload };
export { useGenerateSummaryDocument };
