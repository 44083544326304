import * as React from "react";
import {
  Box,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabPanel,
  TabPanels,
  useDisclosure,
} from "@chakra-ui/react";
import { CSSObject } from "@emotion/react";
import faker from "faker";
import { OrderItem } from "modules/charts-shared/types";
import { Concentration, OrderElementResponse } from "modules/order-list/types";
import { useVitals } from "modules/vitals/api";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, TabList, Tabs } from "shared";
import { CalculationModal } from "./CalculationModal";
import { CommonDoses } from "./CommonDoses";
import { MyFavoriteDoses } from "./MyFavoriteDoses";
import {
  AddOrderItemSchedulerModalBody,
  initialSchedulerProps,
  SchedulerProps,
} from "../AddOrderItemSchedulerModal";
import { EmrBed, EmrCalendar } from "@medstonetech/slate-icons";

type AddMedicationOrderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  item: OrderElementResponse;
  onAddItem: (item: OrderItem) => void;
  encounterId: string;
  isAddingLoading?: boolean;
};

type AddMedicationOrderItemModalBodyProps = Omit<
  AddMedicationOrderModalProps,
  "isOpen" | "item"
> & {
  item: OrderElementResponse;
};

type MedicationOrderForm = {
  medicationName: string;
  doseUnit: string;
  doseAmount: number;
  route: string | null;
  notes: string;
  weight: number;
  amount: number | null;
  concentration: Partial<Concentration>;
  doseId?: string;
  freeTextCommonDose?: string;
};

const TAB_SELECTED_STYLE: CSSObject = {
  bgColor: "white",
  color: "blue",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1);",
};

function AddMedicationOrderItemModalBody({
  item,
  onAddItem,
  encounterId,
  isAddingLoading,
  onClose,
}: AddMedicationOrderItemModalBodyProps) {
  const [showScheduler, setShowScheduler] = useState(false);
  const [schedulerProps, setSchedulerProps] = useState<SchedulerProps>(
    initialSchedulerProps
  );
  const [isObservation, setIsObservation] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const {
    isOpen: isCalculationOpen,
    onOpen: onCalculationOpen,
    onClose: onCalculationClose,
  } = useDisclosure();

  const { data } = useVitals(encounterId);

  const form = useForm<MedicationOrderForm>({
    defaultValues: {
      medicationName: item?.description,
      amount: null,
      route: null,
    },
  });
  const { handleSubmit, formState, reset, watch, setValue } = form;

  const { concentration, doseAmount, doseUnit, weight, route, notes, amount } =
    watch();

  const concentrationText = React.useMemo(() => {
    if (
      !concentration?.doseAmount ||
      !concentration?.doseUnit ||
      !concentration?.volumeAmount ||
      !concentration?.volumeUnit
    )
      return "";
    return ` (${concentration.doseAmount} ${concentration.doseUnit} / ${concentration.volumeAmount} ${concentration.volumeUnit}) `;
  }, [concentration]);

  const location = React.useMemo(() => {
    if (!doseAmount && !doseUnit && !route && !concentrationText) return "";
    return `${doseAmount ?? ""} ${doseUnit ?? ""}${concentrationText} ${
      route ?? ""
    }`;
  }, [doseAmount, doseUnit, route, concentrationText]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = () => {
    if (item) {
      onAddItem({
        id: faker.datatype.uuid(),
        description: `${item.description} ${
          amount ? `${amount ? `${amount} mL` : ""}` : ""
        }`,
        orderElementType: item.orderFormElementType,
        orderElementCategory: item.orderFormElementCategory || null,
        date: new Date(),
        location,
        notes,
        route: route || "",
        cptCode: item.cptCode,
        studyTypeId: item.examID,
        instructions: item.instructions,
        isObservation,
        ...(schedulerProps.isScheduled
          ? {
              prnReason: schedulerProps.prnReason,
              repetitions: schedulerProps.repetitions,
              repetitionHours: schedulerProps.repetitionHours,
              repetitionMinutes: schedulerProps.repetitionMinutes,
              scheduledTime: schedulerProps.scheduledTime?.toString() || "",
            }
          : null),
        isPRN: schedulerProps.isPRN,
        isScheduled: schedulerProps.isScheduled,
      });
    }
    handleClose();
  };

  useEffect(() => {
    if (data?.data.vitalsBMI?.weight) {
      setValue("weight", data.data.vitalsBMI?.weight);
    }
  }, [data, setValue]);

  return (
    <>
      <FormProvider {...form}>
        <Box flex={1} display="flex" flexDirection="column">
          {showScheduler ? (
            <AddOrderItemSchedulerModalBody
              item={item}
              scheduleProps={schedulerProps}
              setShowScheduler={setShowScheduler}
              setSchedulerProps={setSchedulerProps}
            />
          ) : (
            <Box
              as="form"
              onSubmit={handleSubmit(onSubmit)}
              flex={1}
              display="flex"
              flexDirection="column"
            >
              <ModalBody display="flex" flexDir="column">
                <Tabs
                  isFitted
                  size="sm"
                  index={activeTab}
                  variant="solid-rounded"
                  onChange={(index) => {
                    reset();
                    setActiveTab(index);
                  }}
                >
                  <TabList
                    index={activeTab}
                    maxH="32px"
                    sx={{ "> hr": { display: "none" } }}
                    mb="1rem"
                  >
                    <Tab maxH="32px" _selected={TAB_SELECTED_STYLE}>
                      Common
                    </Tab>
                    <Tab maxH="32px" _selected={TAB_SELECTED_STYLE}>
                      My Favorites
                    </Tab>
                  </TabList>
                  <TabPanels
                    overflowY="auto"
                    maxHeight="740px"
                    css={{
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    <TabPanel>
                      {item && activeTab === 0 && (
                        <CommonDoses
                          item={item}
                          showCalculation={onCalculationOpen}
                        />
                      )}
                    </TabPanel>
                    <TabPanel>
                      {item && activeTab === 1 && (
                        <MyFavoriteDoses
                          item={item}
                          showCalculation={onCalculationOpen}
                        />
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>

                {(item.orderFormElementType === "Radiology" ||
                  item.orderFormElementType === "Lab" ||
                  item.orderFormElementType === "Medication") && (
                  <HStack
                    sx={{
                      gap: "20px",
                      mt: "20px",
                      mb: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      sx={{
                        gap: "16px",
                        width: "50%",
                        borderRadius: "8px",
                      }}
                      variant="outlineSquared"
                      colorScheme={schedulerProps.isScheduled ? "blue" : "gray"}
                      onClick={() => setShowScheduler(true)}
                    >
                      <Icon as={EmrCalendar} w="24px" h="24px" /> Scheduled
                    </Button>
                    <Button
                      sx={{
                        gap: "16px",
                        width: "50%",
                        borderRadius: "8px",
                      }}
                      variant="outlineSquared"
                      colorScheme={isObservation ? "blue" : "gray"}
                      onClick={() => setIsObservation(!isObservation)}
                    >
                      <Icon as={EmrBed} w="24px" h="24px" /> Observation Order
                    </Button>
                  </HStack>
                )}
              </ModalBody>
              <ModalFooter
                justifyContent="space-around"
                bgColor="white"
                borderBottomRadius="md"
                px={2}
              >
                <Button
                  variant="outlineSquared"
                  colorScheme="gray"
                  onClick={handleClose}
                  width="240px"
                >
                  Cancel
                </Button>
                <Button
                  variant="outlineSquared"
                  type="submit"
                  bgColor="blue"
                  color="white"
                  disabled={!formState.isValid || !route}
                  width="240px"
                  isLoading={isAddingLoading}
                >
                  Add to Queue
                </Button>
              </ModalFooter>
            </Box>
          )}
        </Box>
      </FormProvider>

      {isCalculationOpen &&
        concentration?.doseAmount &&
        concentration?.doseUnit &&
        concentration?.volumeAmount &&
        concentration?.volumeUnit &&
        doseAmount &&
        doseUnit &&
        weight && (
          <CalculationModal
            isOpen={isCalculationOpen}
            onClose={onCalculationClose}
            concentration={concentration}
            doseAmount={doseAmount}
            doseUnit={doseUnit}
            weight={weight}
          />
        )}
    </>
  );
}

function AddMedicationOrderModal({
  isOpen,
  onClose,
  item,
  onAddItem,
  ...props
}: AddMedicationOrderModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay maxH="100%" />
      <ModalContent
        w="660px"
        maxWidth="660px"
        bg="gray.200"
        display="flex"
        flexDirection="column"
      >
        <ModalHeader textAlign="center" fontSize="17pt">
          {item?.orderFormElementType} Order
        </ModalHeader>
        {isOpen && item && (
          <AddMedicationOrderItemModalBody
            onAddItem={onAddItem}
            onClose={onClose}
            item={item}
            {...props}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { AddMedicationOrderModal };
export type { MedicationOrderForm };
