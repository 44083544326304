import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const Infusion = createIcon({
  path: (
    <path
      d="M2.20408 16.7273C0.808905 16.7273 0 15.8961 0 14.4935V3.87384C0 2.46382 0.808905 1.64007 2.20408 1.64007H4.07421V1.59555C4.07421 0.64564 4.72727 0 5.67718 0C6.62709 0 7.28015 0.64564 7.28015 1.59555V1.64007H9.1577C10.5529 1.64007 11.3544 2.46382 11.3544 3.87384V14.4935C11.3544 15.8961 10.5529 16.7273 9.1577 16.7273H6.22635V17.5733C6.22635 18.3451 6.62709 18.679 7.40631 18.679H10.7013C11.0649 18.679 11.3544 18.9685 11.3544 19.3395C11.3544 19.7032 11.0649 20 10.7013 20H7.39147C5.86271 20 4.89796 19.1169 4.89796 17.5881V16.7273H2.20408ZM5.67718 2.15213C5.98145 2.15213 6.21892 1.90724 6.21892 1.60297C6.21892 1.30612 5.98145 1.06122 5.67718 1.06122C5.38033 1.06122 5.14286 1.30612 5.14286 1.60297C5.14286 1.90724 5.38033 2.15213 5.67718 2.15213ZM3.1243 14.4416H8.2449C8.71243 14.4416 9.0167 14.1521 9.0167 13.692V5.48423C9.0167 5.03154 8.71243 4.73469 8.2449 4.73469H3.1243C2.65677 4.73469 2.3525 5.03154 2.3525 5.48423V13.692C2.3525 14.1521 2.65677 14.4416 3.1243 14.4416ZM5.67718 9.48423C5.03896 9.48423 4.4898 8.97959 4.4898 8.32653C4.4898 7.95547 4.68275 7.59926 4.85343 7.27273L5.44712 6.18924C5.58813 5.92208 5.76623 5.91466 5.91466 6.18924L6.50835 7.27273C6.67904 7.59926 6.87198 7.95547 6.87198 8.32653C6.87198 8.97959 6.33024 9.48423 5.67718 9.48423ZM4.14842 11.3395C3.90353 11.3395 3.74026 11.154 3.74026 10.9239C3.74026 10.7013 3.90353 10.5306 4.14842 10.5306H7.23562C7.4731 10.5306 7.64378 10.7013 7.64378 10.9239C7.64378 11.154 7.4731 11.3395 7.23562 11.3395H4.14842ZM4.72727 12.987C4.4898 12.987 4.32653 12.8163 4.32653 12.5937C4.32653 12.3711 4.4898 12.1855 4.72727 12.1855H6.63451C6.87198 12.1855 7.04267 12.3711 7.04267 12.5937C7.04267 12.8163 6.87198 12.987 6.63451 12.987H4.72727Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 12 20",
});

export { Infusion };
