import { axiosOrderInstance } from "config/axios";
import { ContrastType, MedicationType, OralOptions, OrderType } from "enums";
import { useAxiosMutation } from "hooks";
import { OrderItem } from "modules/charts-shared";
import { useQueryClient } from "react-query";

type UseCreateOrderPayloadItem = {
  orderType?: OrderType;
  description: string;
  contrast?: {
    type: ContrastType;
    options: OralOptions;
    seconds: number;
  };
} & Partial<OrderItem>;

type UseCreateMedicationOrderPayloadItem = {
  orderType: MedicationType;
  description: string;
};

function useCreateOrder(encounterId: string, chartCode: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createOrder", { encounterId, chartCode }],
    (payload: UseCreateOrderPayloadItem[]) =>
      axiosOrderInstance.post(`v1/${encounterId}/${chartCode}`, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterOrders", { encounterId }]);
        queryClient.invalidateQueries(["orderHistory", { encounterId }]);
        queryClient.invalidateQueries([
          "orderHistoryInfinite",
          { encounterId },
        ]);
        queryClient.invalidateQueries([
          "ivAndMedicationCounters",
          { encounterId },
        ]);
        queryClient.invalidateQueries(["medsAndIvCounters", { encounterId }]);
        queryClient.invalidateQueries(["treatmentsCounters", { encounterId }]);
        queryClient.invalidateQueries([
          "encounterOrderCounters",
          { encounterId },
        ]);
        queryClient.invalidateQueries(["ivAndMedication", { encounterId }]);
      },
    }
  );
}

type UseCreateMedsLabsOrderPayloadItem = {
  labsRxOrders: UseCreateOrderPayloadItem[];
  medicationOrders: UseCreateMedicationOrderPayloadItem[];
};

function useCreateLabsMedsOrder(encounterId: string, chartCode: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["createLabsMedsOrders", { encounterId, chartCode }],
    (payload: UseCreateMedsLabsOrderPayloadItem) =>
      axiosOrderInstance.post(
        `v1/labsmeds/${encounterId}/${chartCode}`,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterOrders", { encounterId }]);
        queryClient.invalidateQueries(["orderHistory", { encounterId }]);
      },
    }
  );
}

export type {
  UseCreateOrderPayloadItem,
  UseCreateMedsLabsOrderPayloadItem,
  UseCreateMedicationOrderPayloadItem,
};
export { useCreateOrder, useCreateLabsMedsOrder };
